const database = [
	{
		id: "R0001",
		geflügel: true,
		rind: false,
		schwein: false,
		fisch: false,
		meeresfrüchte: false,
		milch: false,
		ei: false,
		vegetarisch: false,
		vegan: false,
		lowFat: true,
		LowCarb: true,
		italienisch: true,
		spanisch: true,
		indisch: true,
		japanisch: true,
		chinesisch: true,
		arabisch: true,
		senf: true,
		schwefeldioxid: true,
		sulphite: true,
		nüsse: true,
		gluten: false,
		sellerie: false,
		sesamsamen: false,
		sojabohnen: false,
		laktose: false,
		eierzeugnisse: false,
		sellerieerzeugnisse: false,
		hülsenfrüchte: false,
		titleImage: "./R0001/0.webp",
		name: "Gebackenes Pistazien-Hähnchenschnitzel auf Blumenkohl-Rucola-Rosinen-Salat",
		level: 1,
		punkte: 3,
		kochzeit: 20,
		supermarktpreis: 1.83,
		kalorien: 574,
		schärfegrad: 0,
		gerichtbeschreibung:
			"Lorem ipsum dolor sit amet, consectetur adipiscing elit. At gravida faucibus consequat nec, dictum quis enim lacus. Dignissim maecenas velit faucibus diam vitae elementum. Sed risus dui aliquam varius dignissim pharetra, hendrerit. Ullamcorper amet enim ipsum faucibus nibh.",
		fancies: [ "gemahlener kreuzkümmel", "Körniger Senf", "Pistazienkerne", "Rosinen" ],
		einkaufsliste: [ "Blumenkohl", "Zwiebel (weiß)", "Zitrone", "Rucola", "Hähnchenbrustfilet" ],
		zutatenZuhause: [ "Salz", "Pfeffer", "Olivenöl" ],
		kcl: 574,
		fett: 30.1,
		kohlenhydrate: 37.3,
		eiweiß: 42.1,
		utensilien: [ "Küchenreibe", "Zitronenpresse", "Sieb", "Backofen", "Backblech", "Backpapier" ],
		allergene: [ "Senf", "Schwefeldioxid", "Sulphite", "Nüsse", "may contain traces of other allergens" ],
		steps: [
			{
				stepName: "Ofen vorheizen",
				stepDescription: "Heize den Backofen auf 220°C Umluft oder auf 240°C Ober-/Unterhitze vor. ",
				stepImage: "./R0001/1.webp"
			},
			{
				stepName: "Zwiebeln und Rosinen einweichen",
				stepDescription:
					"Schneide die Zwiebeln in dünne Streifen und vermische sie mit den Sultaninen in einer kleinen Schale. Dort sollen sie in lauwarmen Wasser eingeweicht werden. ",
				stepImage: "./R0001/2.webp"
			},
			{
				stepName: "Blumenkohl backen",
				stepDescription:
					"Schneide nun den Blumenkohl in 2-3 cm große Stücke. Vermische jene  mit 1 EL Olivenöl, jeweils einer Prise Salz und Pfeffer, sowie dem Kreuzkümmel.  Lege auf ein Backblech Backpapier aus und verteile die gewürzten Blumenkohlstückchen geichmäßig. Diese sollen nun im Ofen in 15-20 Minuten grillieren. Bedenke dabei sie nach etwa 10 Minuten einmal zu drehen. ",
				stepImage: "./R0001/3.webp"
			},
			{
				stepName: "Hähnchenfleisch mit Pistazien panieren",
				stepDescription:
					"Halbiere jeweils die Hähnchenbrust der Länge nach in dünne Schnitzel. Würze das Fleisch auf jeder Seite mit einer Prise Salz und Pfeffer. Vermenge den Senf mit 2 EL Wasser und streiche die Paste auf die Oberseite des Hähnchenschnitzels. Zerkleinere die Pistazien mit einem scharfen Messer in feine Stückchen und paniere nun die mit Senf bestrichene Seite mit den Pistazien. Passe die letzten 8-9 MInuten der Garzeit des Blumenkohls ab und lege das vorbereitete Hähnchenfleisch für die restliche Garzeit mit auf das Blech. ",
				stepImage: "./R0001/4.webp"
			},
			{
				stepName: "Salat zubereiten",
				stepDescription:
					"Entleere die Schale mit den eingelegten Zwiebeln und Rosinen über einem Sieb. Während des Abtropfens kann die Zitronenschale abgerieben werden. Presse den Saft einer Zitronenhälfte aus. Jenem Zitronensaft darfst du nun 1 TL von dem Zitronenabrieb, sowie 2 EL Olivenöl und nach Belieben Pfeffer und Salz dazugeben. Mixe dieses Salatdressing mit dem grillierten Blumenkohl, dem Rucola, sowie den eingeweichten Zwiebeln und Rosinen in einer Salatschüssel zusammen. ",
				stepImage: "./R0001/5.webp"
			},
			{
				stepName: "Servieren",
				stepDescription: "Gib das panierte Hähnchenschnitzel ansehnlich mit dem Salat auf einen Teller. ",
				stepImage: "./R0001/6.webp"
			}
		]
	},
	{
		id: "R0002",
		geflügel: false,
		rind: false,
		schwein: false,
		fisch: false,
		meeresfrüchte: false,
		milch: false,
		ei: false,
		vegetarisch: false,
		vegan: true,
		lowFat: true,
		LowCarb: false,
		italienisch: true,
		spanisch: true,
		indisch: true,
		japanisch: true,
		chinesisch: true,
		arabisch: true,
		senf: false,
		schwefeldioxid: false,
		sulphite: false,
		nüsse: false,
		gluten: true,
		sellerie: true,
		sesamsamen: false,
		sojabohnen: false,
		laktose: false,
		eierzeugnisse: false,
		sellerieerzeugnisse: false,
		hülsenfrüchte: false,
		titleImage: "./R0002/0.webp",
		name: "Grillierte Artischocken auf Bulgur-Pilav mit Sonnenblumenkernen und Oliven",
		level: 1,
		punkte: 3,
		kochzeit: 25,
		supermarktpreis: 3,
		kalorien: 789,
		schärfegrad: 0,
		gerichtbeschreibung:
			"Lorem ipsum dolor sit amet, consectetur adipiscing elit. At gravida faucibus consequat nec, dictum quis enim lacus. Dignissim maecenas velit faucibus diam vitae elementum. Sed risus dui aliquam varius dignissim pharetra, hendrerit. Ullamcorper amet enim ipsum faucibus nibh.",
		fancies: [ "Sonnenblumenkerne", "Gemüsebrühgewürz", "Oliven (schwarz)" ],
		einkaufsliste: [ "Artischockenherzen", "Kirschtomaten", "Gemüsemix", "Petersilie", "Tomatenmark", "Bulgur" ],
		zutatenZuhause: [ "Salz", "Pfeffer", "Olivenöl" ],
		kcl: 789,
		fett: 26.4,
		kohlenhydrate: 105.6,
		eiweiß: 23,
		utensilien: [ "Pfanne(mittelgroß)", "Sieb", "Messbecher", "Backofen", "Backblech", "Backpapier" ],
		allergene: [ "Gluten", "Sellerie", "kann Spuren von anderen Allergenen enthalten" ],
		steps: [
			{
				stepName: "Ofen vorheizen",
				stepDescription: "Heize den Backofen auf 220°C Ober-/Unterhitze oder 200°C Umluft vor.",
				stepImage: "./R0002/1.webp"
			},
			{
				stepName: "Zutaten vorbereiten",
				stepDescription:
					"Löse die Gemüsebrühe in 350 ml heißem Wasser auf. Schneide die angebebenene Gemüsemenge in möglichst kleine Stücke. Hacke die Petersilie mitsamt der Stängel und halte sie noch von den anderen Zutaten getrennt. Lasse die Artischoken und Oliven in einem Sieb abtropfen. Wasche die Kirschtomaten kurz ab.",
				stepImage: "./R0002/2.webp"
			},
			{
				stepName: "Gemüse dünsten",
				stepDescription:
					"Gib zu dem geschnittenen Gemüse 1 EL Olivenöl in eine große Pfanne und decke sie mit einem passenden Deckel ab. Bei starker Hitze und gelegentlichem Umrühren darfst du das Gemüse dünsten. ",
				stepImage: "./R0002/3.webp"
			},
			{
				stepName: "Artischocken grillieren",
				stepDescription:
					"Breite auf einem Backblech ein Backpapier aus. Verteile hierauf gleichmaßig die bereits abgetropften Artischocken, Oliven und die Kirschtomaten. Gib 1 TL Olivenöl, sowie nch Belieben Salz und Pfeffer hinzu. Lasse das Blech nun 10-15 Miniuten im Ofen. Die Tomaten dürfen leicht aufplatzen. ",
				stepImage: "./R0002/4.webp"
			},
			{
				stepName: "Pilav zubereiten",
				stepDescription:
					"Gib der Gemüsebrühe das Tomatenmark und die Sonnenblumenkerne hinzu und schütte damit das gebratene Gemüse in der Pfanne auf. Verrühre jetzt den Bulgur in der Pfanne. Nimm die Hitze der Pfanne auf die kleinste Stufe zurück, sodass der Bulgur bei niedrigster Hitze 10-15 Minuten leicht köcheln kann. Der Bulgur ist fertig, wenn er die Flüssigkeit aufgesaugt hat. ",
				stepImage: "./R0002/5.webp"
			},
			{
				stepName: "Servieren",
				stepDescription:
					"Gib den Pilav in eine Servierschale oder tiefen Teller und richte das Ofengemüse als Topping darauf.  Decoriere zum Abschluss mit der Petersilie. ",
				stepImage: "./R0002/6.webp"
			}
		]
	},
	{
		id: "R0003",
		geflügel: false,
		rind: true,
		schwein: false,
		fisch: false,
		meeresfrüchte: false,
		milch: false,
		ei: false,
		vegetarisch: false,
		vegan: false,
		lowFat: true,
		LowCarb: false,
		italienisch: true,
		spanisch: true,
		indisch: true,
		japanisch: true,
		chinesisch: true,
		arabisch: true,
		senf: false,
		schwefeldioxid: false,
		sulphite: false,
		nüsse: false,
		gluten: true,
		sellerie: false,
		sesamsamen: true,
		sojabohnen: true,
		laktose: false,
		eierzeugnisse: false,
		sellerieerzeugnisse: false,
		hülsenfrüchte: false,
		titleImage: "./R0001/0.webp",
		name: "Rinderhack-Bolognese koreanischer Art auf Asia-Nudeln mit Pilzen und Spinat",
		level: 1,
		punkte: 3,
		kochzeit: 20,
		supermarktpreis: 2.63,
		kalorien: 919,
		schärfegrad: 2,
		gerichtbeschreibung:
			"Lorem ipsum dolor sit amet, consectetur adipiscing elit. At gravida faucibus consequat nec, dictum quis enim lacus. Dignissim maecenas velit faucibus diam vitae elementum. Sed risus dui aliquam varius dignissim pharetra, hendrerit. Ullamcorper amet enim ipsum faucibus nibh.",
		fancies: [ "Sesamöl", "Sojasauce", "Koreanische Chilipaste" ],
		einkaufsliste: [
			"Zwiebel(weiß)",
			"Lauchzwiebeln",
			"Babyspinat",
			"Champignons",
			"Knoblauchzehe",
			"Ingwer(frisch)",
			"Mie-Nudeln",
			"Rinderhack"
		],
		zutatenZuhause: [ "Salz", "Pfeffer", "Zucker(braun)", "Pflanzenöl", "Essig" ],
		kcl: 919,
		fett: 28.7,
		kohlenhydrate: 118.5,
		eiweiß: 48.3,
		utensilien: [ "Wok", "Kochtopf (mittelgroß)", "Sieb" ],
		allergene: [ "Gluten", "Sesamsamen", "Sojabohnen", "kann Spuren von anderen Allergenen enthalten" ],
		steps: [
			{
				stepName: "Kochutensilien vorbereiten",
				stepDescription: "Bringe in einem mittelgroßen Topf Wasser zum Kochen.",
				stepImage: "./R0003/1.webp"
			},
			{
				stepName: "Pilze schneiden",
				stepDescription: "Schneide die Pilze in dünne Scheiben.",
				stepImage: "./R0003/2.webp"
			},
			{
				stepName: "Zwiebel, Knoblauch und Ingwer würfeln",
				stepDescription:
					"Schäle den Knoblauch und würfel ihn fein. Die Zwiebel darf nach dem Schälen in etwas größere Würfel geschnitten werden. Der Ingwer sollte auch geschält werden und ebenso in sehr feine würfel geschnitten werden, geschmacklich empfiehlt sich hier auch das Reiben. ",
				stepImage: "./R0003/3.webp"
			},
			{
				stepName: "Koreanische Sauce zubereiten",
				stepDescription:
					"Erhitze in einer großen Pfanne oder auch einem Wok 1 EL Pflanzenöl. Brate für etwa 4 Minuten nun alle geschnittenen Zutaten gemeinsam an. Gib nun das Hackfleisch dazu. Zeitgleich darf 1 EL Chilipaste, 1-2 EL Essig, 1 EL Zucker (braun) und die Sojasoße hinzugefügt werden. Brate alles zusammen für weitere 8-10 Minuten unter ständigem Rühren weiter an. Die Sauce wird zunhemend dicker. Nach Bedarf kann diese auch mit etwas Wasser verdünnt werden.  Gib nun nach und nach den Spinat zur Sauce dazu, sodass er langsam zusammenfällt. ",
				stepImage: "./R0003/4.webp"
			},
			{
				stepName: "Mie-Nudeln kochen",
				stepDescription:
					"Füge die Mie-Nudeln in das vorbereitete kochende Wasser. Innerhalb von 3-5 Minuten sind sie bissfest. Gieß sie in ein Sieb ab. Nach dem Abgießen werden die Nudeln mit dem Sesamöl in dem verwendetetn Topf durchmischt.",
				stepImage: "./R0003/5.webp"
			},
			{
				stepName: "Anrichten und Servieren",
				stepDescription:
					"Geschmacklich kann die Sauce mit zusätzlicher Chilipaste, Salz und Pfeffer nachgewürzt werden. Richte die Nudeln mit der Sauce ansehnlich an. Schneide die Lauchzwiebel in dünne Ringe, um damit zu garnieren. ",
				stepImage: "./R0003/6.webp"
			}
		]
	},
	{
		id: "R0004",
		geflügel: false,
		rind: false,
		schwein: false,
		fisch: false,
		meeresfrüchte: false,
		milch: false,
		ei: false,
		vegetarisch: false,
		vegan: true,
		lowFat: true,
		LowCarb: false,
		italienisch: true,
		spanisch: true,
		indisch: true,
		japanisch: true,
		chinesisch: true,
		arabisch: true,
		senf: false,
		schwefeldioxid: true,
		sulphite: true,
		nüsse: true,
		gluten: false,
		sellerie: false,
		sesamsamen: false,
		sojabohnen: false,
		laktose: false,
		eierzeugnisse: false,
		sellerieerzeugnisse: false,
		hülsenfrüchte: false,
		titleImage: "./R0004/0.webp",
		name: "Süßkartoffel-Kokos-Curry mit Cashews und Aprikosen",
		level: 1,
		punkte: 3,
		kochzeit: 20,
		supermarktpreis: 1.93,
		kalorien: 974,
		schärfegrad: 4,
		gerichtbeschreibung:
			"Lorem ipsum dolor sit amet, consectetur adipiscing elit. At gravida faucibus consequat nec, dictum quis enim lacus. Dignissim maecenas velit faucibus diam vitae elementum. Sed risus dui aliquam varius dignissim pharetra, hendrerit. Ullamcorper amet enim ipsum faucibus nibh.",
		fancies: [ "Cashewkerne", "Gelbe Currypaste", "Getrocknete Aprikosen" ],
		einkaufsliste: [
			"Lauchzwiebeln",
			"Paprika (gelb)",
			"Süßkartoffel",
			"Limette",
			"Thaibasilikum",
			"Kokosmilch",
			"Jasmin-Reis"
		],
		zutatenZuhause: [ "Salz", "Pfeffer", "Pflanzenöl" ],
		kcl: 974,
		fett: 38,
		kohlenhydrate: 133.7,
		eiweiß: 19.7,
		utensilien: [
			"Pfanne (klein)",
			"Pfanne(mittelgroß)",
			"Kochtopf (mittelgroß)",
			"Sieb",
			"Messbecher",
			"Schäler"
		],
		allergene: [ "Schwefeldioxid", "Sulphite", "Nüsse", "kann Spuren von anderen Allergenen enthalten" ],
		steps: [
			{
				stepName: "Gemüse vorbereiten",
				stepDescription:
					"Schäle die Süßkartoffeln, um sie anschließend in etwa 1cm große Stücke zu würfeln. Halbiere und entkerne die Paprika. Diese kann nun in Streifen geschnitten werden.  Zerkleinere den weißen Teil der Lauchzwiebel sehr fein und schneide den grünen Teil in dünne Ringe. ",
				stepImage: "./R0004/1.webp"
			},
			{
				stepName: "Gemüse braten",
				stepDescription:
					"Erhitze in einer großen Pfanne 1 EL Pflanzenöl auf mittlerer Hitzestufe. Die Süßkartoffelstücke, Lauchzwiebeln und Paprika werden gemeinsam 5-6 Minuten angebraten. ",
				stepImage: "./R0004/2.webp"
			},
			{
				stepName: "Reis kochen",
				stepDescription:
					"Während des Gemüseschneidens kann bereits in einem kleinen Topf 400 ml Wasser leicht gesalzen und zum Kochen gebracht werden. Der Reis wird zunächst unter fließendem Wasser in einem Sieb abgewaschen, bis das Wasser klar bleibt. Nachdem der Reis vorbereitet wurde, kann er in das kochende Wasser gegeben werden. Bei niedrigster Hitze und geschlossenem Deckel quillt der Reis langsam auf . Nach ca. 10-12 Minuten ist er gar und kann für weitere 5 Minuten abgedeckt (ohne Hitzezufuhr) nachziehen. ",
				stepImage: "./R0004/3.webp"
			},
			{
				stepName: "Cashews rösten",
				stepDescription:
					"Da Cashews schnell anbraten oder dunkel werden, ist es wichtig die Hitzezufuhr gering zu halten. Röste die Cashews also in einer kleinen Pfanne, ohne Zugabe von Fett oder Ölen, bei niedrigster  Hitzestufe. Nach etwa 1-2 Minuten fangen sie an zu duften und können vom Herd genommen werden. ",
				stepImage: "./R0004/4.webp"
			},
			{
				stepName: "Curry zubereiten",
				stepDescription:
					"In die Gemüsepfanne darfst du nun die Aprikosen und Kokosmilch hinzugeben. Verrühre darin die Currypaste und lasse die gesamte Pfanne bei geringster Hitzezufuhr für weitere 10-20 Minuten sanft weiter köcheln. Würze noch mit etwas Salz und Pfeffer nach, wenn die Süßkartoffeln gar sind. Um die Konsistenz nach Belieben anzupassen, kann etwas Wasser beigefügt werden. Auch der Schärfegrad kann individuell angepasst werden, indem mehr oder weniger Currypaste verwendet wird.",
				stepImage: "./R0004/5.webp"
			},
			{
				stepName: "Limette schneiden und garnieren",
				stepDescription:
					"Das Süßkartoffel-Curry kann mit dem Reis angerichtet werden. Schneide die Limette in feine Spalten. Garniere das Gericht nun mit den Cashews, den verbliebenen Lauchzwiebeln, den Limettenspalten und dem Thai-Basilikum. ",
				stepImage: "./R0004/6.webp"
			}
		]
	},
	{
		id: "R0005",
		geflügel: true,
		rind: false,
		schwein: false,
		fisch: false,
		meeresfrüchte: false,
		milch: false,
		ei: false,
		vegetarisch: false,
		vegan: false,
		lowFat: true,
		LowCarb: false,
		italienisch: true,
		spanisch: true,
		indisch: true,
		japanisch: true,
		chinesisch: true,
		arabisch: true,
		senf: false,
		schwefeldioxid: false,
		sulphite: false,
		nüsse: false,
		gluten: true,
		sellerie: false,
		sesamsamen: false,
		sojabohnen: false,
		laktose: true,
		eierzeugnisse: false,
		sellerieerzeugnisse: false,
		hülsenfrüchte: false,
		titleImage: "./R0005/0.webp",
		name: "Fenchel-Pasta in Orangen-Tomaten-Soße mit Hähnchenbrustfilets",
		level: 1,
		punkte: 3,
		kochzeit: 20,
		supermarktpreis: 4.99,
		kalorien: 730,
		schärfegrad: 0,
		gerichtbeschreibung:
			"Lorem ipsum dolor sit amet, consectetur adipiscing elit. At gravida faucibus consequat nec, dictum quis enim lacus. Dignissim maecenas velit faucibus diam vitae elementum. Sed risus dui aliquam varius dignissim pharetra, hendrerit. Ullamcorper amet enim ipsum faucibus nibh.",
		fancies: [ "Rauchsalz", "Fenchelsamen", "Geflügelbrühgewürz" ],
		einkaufsliste: [
			"Kirschtomaten",
			"Knoblauchzehe",
			"Fenchel",
			"Orange",
			"Basilikum",
			"Hartkäse",
			"Nudeln",
			"Hähnchenbrustfilet"
		],
		zutatenZuhause: [ "Salz", "Pfeffer", "Olivenöl" ],
		kcl: 730,
		fett: 15,
		kohlenhydrate: 108.4,
		eiweiß: 36.5,
		utensilien: [ "Pfanne(groß)", "Kochtopf(mittelgroß)", "Küchenreibe", "Zitronenpresse", "Sieb", "Messbecher" ],
		allergene: [ "Gluten", "Milch/-erzeugnisse, Laktose", "kann Spuren von anderen Allergenen enthalten" ],
		steps: [
			{
				stepName: "Nudelwasser aufsetzen",
				stepDescription:
					"Bringe in einem Topf genügend leicht gesalzenes Wasser für die Nudeln zum kochen. Löse die Hühnerbrühe seperat in 100ml Wasser auf. ",
				stepImage: "./R0005/1.webp"
			},
			{
				stepName: "Zutaten schneiden",
				stepDescription:
					"Schäle die Knoblauchzehe(n) und schneide jene in feine würfel. Halbiere die Orange, presse sie aus und bewahre den Saft noch seperat auf.  Schneide den Fenchel in schmale Streifen. Halbiere ihn hierfür zuvor und entferne den Strunk im Inneren. Schneide die Hähnchenbrustfilets  in möglichst feine Streifen, oder in kleine Würfel. ",
				stepImage: "./R0005/2.webp"
			},
			{
				stepName: "Gemüse braten",
				stepDescription:
					"Gib 1 EL Olivenöl in eine Pfanne und brate für etwa 4 Minuten den Fenchel mit einer Prise Salz an. Es empfiehlt sich bei mittlerer Hitze häufig zu Rühren. Gib nun den Knoblauch und die Fenchelsamen hinzu und lass die Pfanne für weitere 2 Minuten braten. Lösche mit dem Orangensaft und der bereits aufgelösten Hühnerbrühe ab und füge die Kirschtomaten und die Hähnchenstreifen hinzu. Die gesamte Pfanne darf abgedeckt bei mittlerer bis starker Hitzezufuhr für weitere 10 Minuten köcheln, sodass die Kirschtomaten weich werden und das Hähnchenfleisch gar wird. Füge das Rauchsalz für das besondere Aroma hinzu.",
				stepImage: "./R0005/3.webp"
			},
			{
				stepName: "Nudeln kochen",
				stepDescription:
					"Koche die Nudeln bissfest. 5 bis 6 Minuten Kochzeit sollten ausreichen. Wenn die Nudeln über einem Sieb abgeschüttet werden, darfst du 100 ml von dem Pastawasser auffangen. ",
				stepImage: "./R0005/4.webp"
			},
			{
				stepName: "Topping vorbereiten",
				stepDescription: "Raspel den Hartkäse und bereite die Basilikumblätter vor. ",
				stepImage: "./R0005/5.webp"
			},
			{
				stepName: "Servieren",
				stepDescription:
					"Vermenge im letzten Schritt die Nudeln mit dem abgefangenenem Pastawasser in der Pfanne. Die Tomaten können etwas zerdrückt werden und die Hitze für die letzten 2 Minuten auf die höchste Stufe gestellt werden. Nachdem alles nun nocheinmal stark köcheln konnte, kann der Käse untergerührt und die Pfanne vom Herd genommen werden. Würze nach Belieben mit Salz und Pfeffer nach und serviere die Pasta mit dem Basilikum als Topping. ",
				stepImage: "./R0005/6.webp"
			}
		]
	},
	{
		id: "R0006",
		geflügel: false,
		rind: false,
		schwein: false,
		fisch: false,
		meeresfrüchte: false,
		milch: false,
		ei: false,
		vegetarisch: true,
		vegan: false,
		lowFat: false,
		LowCarb: false,
		italienisch: true,
		spanisch: true,
		indisch: true,
		japanisch: true,
		chinesisch: true,
		arabisch: true,
		senf: false,
		schwefeldioxid: false,
		sulphite: false,
		nüsse: false,
		gluten: false,
		sellerie: false,
		sesamsamen: false,
		sojabohnen: false,
		laktose: false,
		eierzeugnisse: false,
		sellerieerzeugnisse: false,
		hülsenfrüchte: false,
		titleImage: "./R0006/0.webp",
		name: "Safran-Orangen-Risotto",
		level: 2,
		punkte: 3,
		kochzeit: 20,
		supermarktpreis: 4.73,
		kalorien: 712,
		schärfegrad: 0,
		gerichtbeschreibung:
			"Lorem ipsum dolor sit amet, consectetur adipiscing elit. At gravida faucibus consequat nec, dictum quis enim lacus. Dignissim maecenas velit faucibus diam vitae elementum. Sed risus dui aliquam varius dignissim pharetra, hendrerit. Ullamcorper amet enim ipsum faucibus nibh.",
		fancies: [ "Safranfäden", "Loorbeerblatt", "Parmesan (gerieben)", "Gemüsebrühgewürz" ],
		einkaufsliste: [ "Schalotte", "Orange", "Risotto-Reis", "Trockener Weißwein" ],
		zutatenZuhause: [ "Salz", "Pfeffer", "Olivenöl", "Butter" ],
		kcl: 712,
		fett: 34.52,
		kohlenhydrate: 74.64,
		eiweiß: 26.7,
		utensilien: [ "Kochtopf (mittelgroß)", "Kochtopf (klein)", "Küchenreibe" ],
		allergene: [ "kann Spuren von anderen Allergenen enthalten" ],
		steps: [
			{
				stepName: "Orange schneiden",
				stepDescription:
					"Wasche die Orange gut ab und reibe die Schale ab. Fange den Abrieb in einer kleinen Schale auf. Entferne anschließend das Fruchtfleisch von der verbliebenen Schale.  Schneide die Filets heraus und drittel diese. Möglicher Orangensaft kann gerne aufgefangen werden.",
				stepImage: "./R0006/1.webp"
			},
			{
				stepName: "Brühe aufsetzen",
				stepDescription:
					"Löse die Gemüsebrühe in 500ml heißem Wasser auf und bringe sie gemeinsam mit dem aufgefangenen Orangesaft in einem kleinen Topf zum kochen. Füge das Lorbeerblatt gleich mit hinzu. ",
				stepImage: "./R0006/2.webp"
			},
			{
				stepName: "",
				stepDescription: "",
				stepImage: "./R0001/3.webp"
			},
			{
				stepName: "Safranfäden auflösen, Reis bereitstellen",
				stepDescription:
					"Nimm von der aufgesetzten Gemüsebrühe nun 3 EL in eine kleine Schale ab und zerreibe darüber die Safranfäden zwischen den Fingern. Lasse  sie darin nun ziehen. Wiege den Risotto-Reis ab. ",
				stepImage: "./R0003/4.webp"
			},
			{
				stepName: "Zwiebeln anbraten, Reis hinzugeben",
				stepDescription:
					"Schäle die Schalotte und würfel sie. Brate diese nun in einem größeren Topf  in 3 EL Olivenöl für 2-3 Minuten sanft an. Die Hitze darf hierfür auf mittlerer Stufe eingestellt bleiben. Bevor die Schalotten braun sind, kann der Risotto-Reis unter ständigem Rühren hinzugegeben werden. Lösche nach etwa 2 Minuten den glasig gewordenen Reis mit dem Wein ab. Rühre auch bei den nächsten Schritten ständig kräftig weiter. ",
				stepImage: "./R0006/5.webp"
			},
			{
				stepName: "Brühe hinzugeben",
				stepDescription:
					"Gieße nun etwa 100 ml von der aufgesetzten Brühe hinzu. Sobald der Reis diese Flüssigkeit langsam aufgesogen hat, kann in gleichem Vorgehen die restliche Brühe hinzugegeben werden. Durchmische in der Zwischenzeit die Orangenfilets vorsichtig mit 2 EL Butter. ",
				stepImage: "./R0006/6.webp"
			}
		]
	},
	{
		id: "R0007",
		geflügel: false,
		rind: false,
		schwein: false,
		fisch: false,
		meeresfrüchte: false,
		milch: false,
		ei: false,
		vegetarisch: false,
		vegan: true,
		lowFat: false,
		LowCarb: false,
		italienisch: true,
		spanisch: true,
		indisch: true,
		japanisch: true,
		chinesisch: true,
		arabisch: true,
		senf: false,
		schwefeldioxid: false,
		sulphite: false,
		nüsse: false,
		gluten: true,
		sellerie: false,
		sesamsamen: false,
		sojabohnen: true,
		laktose: false,
		eierzeugnisse: false,
		sellerieerzeugnisse: false,
		hülsenfrüchte: false,
		titleImage: "./R0007/0.webp",
		name: "Kokosreis mit Miso-Ofengemüse auf scharfer Soße",
		level: 2,
		punkte: 3,
		kochzeit: 30,
		supermarktpreis: 2.77,
		kalorien: 704,
		schärfegrad: 3,
		gerichtbeschreibung:
			"Lorem ipsum dolor sit amet, consectetur adipiscing elit. At gravida faucibus consequat nec, dictum quis enim lacus. Dignissim maecenas velit faucibus diam vitae elementum. Sed risus dui aliquam varius dignissim pharetra, hendrerit. Ullamcorper amet enim ipsum faucibus nibh.",
		fancies: [ "Schwarzkümmel", "Sojasauce", "Sweet-Chili-Soße", "veg. weiße Miso-Paste" ],
		einkaufsliste: [
			"Babyspinat",
			"Knoblauchzehe",
			"Ingwer (frisch)",
			"Aubergine",
			"Brokkoli",
			"Limette",
			"Avocado",
			"Kokosmilch",
			"Jasmin-Reis"
		],
		zutatenZuhause: [ "Salz", "Pfeffer", "Zucker (braun)", "Pflanzenöl" ],
		kcl: 704,
		fett: 30.57,
		kohlenhydrate: 88.3,
		eiweiß: 16.4,
		utensilien: [ "Kochtopf (mittelgroß)", "Küchenreibe", "Backofen", "Backblech", "Backpapier" ],
		allergene: [ "Gluten", "Sojabohnen", "kann Spuren von anderen Allergenen enthalten" ],
		steps: [
			{
				stepName: "Gemüse schneiden",
				stepDescription:
					"Heize den Backofen auf 220°C Ober-/Unterhitze oder 200°C Umluft vor. Schneide den Brokkoli in kleine Stücke. Die Auberginen werden in 3cm große Stücke gewürfelt. ",
				stepImage: "./R0007/1.webp"
			},
			{
				stepName: "Marinade zubereiten",
				stepDescription:
					"Reinige die Limette mit heißem Wasser und reibe die Schale fein ab. Halbiere die Limette und schneide sie in 6 Spalten. Nachdem du den Ingwer und Knoblauch geschält hast, werden sie fein gehackt.  Verrühre in einer großen Schüssel den Knoblauch, Ingwer, die Sojasoße, die Miso-Paste mit einem 1 EL Öl und 1 EL Zucker. Der Saft von einer Limettenspalte wird ebenfalls hinzugefügt. ",
				stepImage: "./R0007/2.webp"
			},
			{
				stepName: "Gemüse backen",
				stepDescription:
					"Gib in diese Schüssel mit der Marinade nun das geschnittene Gemüse dazu und vermenge alles zusammen. Verteile das marinierte Gemüse auf einem mit Backpapier ausgelegten Backblech und lasse es für etwa 25 Minuten backen. Nach Belieben kann die Backzeit um 5 Minuten verlängert werden, wenn das Gemüse weicher sein soll.",
				stepImage: "./R0007/3.webp"
			},
			{
				stepName: "Jasminreis kochen",
				stepDescription:
					"Schütte die Kokosmilch mit zusätzlich 150 ml heißem Wasser in einen großen Topf. Der Jasminreis wird gleich am Anfang mit hinzugegeben. Lasse alles einmal aufkochen und decke danach den Topf mit dem Deckel ab, reduziere die Hitze auf die niedrigste Stufe und lass alles ca. 10 Minuten weiter köcheln. Danach kann der Topf vom Herd genommen werden und der Inhalt für weitere 10 Minuten nachziehen. ",
				stepImage: "./R0007/4.webp"
			},
			{
				stepName: "Dip anrühren",
				stepDescription:
					"Halbiere und entsteine die Avocado, um das Fruchtfleisch in kleine Stücke zu schneiden. Gib die Avocado-Stücke in eine kleine Schüssel und benetze sie mit etwas Limettensaft. Mische den Schwarzkümmel unter. In einer zweiten kleinen Schüssel wird die Sweet-Chili-Soße mit 1 TL geriebener Limettenschale, dem Saft und 2 Limettenspalten vermengt. ",
				stepImage: "./R0007/5.webp"
			},
			{
				stepName: "Babyspinat zum Reis hinzufügen",
				stepDescription:
					"Wenn der Reis durchgezogen ist, darfst du ihn mit einer Gabel etwas lockern, 50 ml Wasser hinzugeben und den Babyspinat nach und nach untermischen. Der Spinat wird durch die Resthitze nach ein paar Minuten zusammenfallen. Schmecke mit etwas Pfeffer und Salz ab. Serviere den Reis mit dem Miso-Ofengeüse und streue ein paar Avocadowürfel darüber. Der Sweet-Chili-Dip kann in einer kleinen Schale seperat dazu gereicht oder das Gericht damit betreufelt werden. Dekoriere zum Schluss mit den restlichen Limettenschalen ",
				stepImage: "./R0007/6.webp"
			}
		]
	},
	{
		id: "R0008",
		geflügel: false,
		rind: false,
		schwein: false,
		fisch: false,
		meeresfrüchte: false,
		milch: false,
		ei: false,
		vegetarisch: true,
		vegan: false,
		lowFat: false,
		LowCarb: false,
		italienisch: true,
		spanisch: true,
		indisch: true,
		japanisch: true,
		chinesisch: true,
		arabisch: true,
		senf: false,
		schwefeldioxid: true,
		sulphite: true,
		nüsse: false,
		gluten: true,
		sellerie: false,
		sesamsamen: false,
		sojabohnen: false,
		laktose: true,
		eierzeugnisse: false,
		sellerieerzeugnisse: false,
		hülsenfrüchte: false,
		titleImage: "./R0008/0.webp",
		name: "Scharfe Tomaten-Paprika-Suppe mit überbackenem Ciabatta",
		level: 2,
		punkte: 3,
		kochzeit: 25,
		supermarktpreis: 3.16,
		kalorien: 712,
		schärfegrad: 3,
		gerichtbeschreibung:
			"Lorem ipsum dolor sit amet, consectetur adipiscing elit. At gravida faucibus consequat nec, dictum quis enim lacus. Dignissim maecenas velit faucibus diam vitae elementum. Sed risus dui aliquam varius dignissim pharetra, hendrerit. Ullamcorper amet enim ipsum faucibus nibh.",
		fancies: [ "Sriracha Sauce (scharf)", "Gemüsebrühgewürz" ],
		einkaufsliste: [
			"Knoblauchzehe",
			"Paprika (gelb)",
			"Gehackte Tomaten",
			"Basilikum",
			"Hartkäse",
			"Creme fraiche",
			"Mozzarella",
			"Ciabatta"
		],
		zutatenZuhause: [ "Salz", "Pfeffer", "Zucker(braun)", "Pflanzenöl" ],
		kcl: 712,
		fett: 34.52,
		kohlenhydrate: 74.64,
		eiweiß: 26.7,
		utensilien: [
			"Kochtopf (groß)",
			"Pürierstab",
			"Backofen",
			"Backblech",
			"Backpapier",
			"Gitterrost",
			"Wasserkocher"
		],
		allergene: [
			"Schwefeldioxid",
			"Sulphite",
			"Gluten",
			"Milch/-erzeugnisse, Laktose",
			"kann Spuren von anderen Allergenen enthalten"
		],
		steps: [
			{
				stepName: "Ofen vorheizen, Brühe vorbereiten",
				stepDescription:
					"Heize den Backofen auf 220°C Ober-/Unterhitze oder 200°C Umluft vor. Koche in einem Wasserkocher 1 Liter Wasser. Rühre die Gemüsebrühe mit 300 ml kochendem Wasser an. ",
				stepImage: "./R0008/1.webp"
			},
			{
				stepName: "Zutaten schneiden",
				stepDescription:
					"Wasche das Gemüse gründlich ab und beginne die Paprika zu entkernen und klein zu würfeln. Entferne die Schale der Knoblauchzehe und halbiere sie. Der Hartkäse kann nun auch schon geraspelt werden.  Würfel den Mozarella ebenfalls in kleine Würfel. Hacke ebenfalls die Basilikumblätter fein. Bewahre noch alle vorbereiteten Zutaten getrennt voneinander auf.",
				stepImage: "./R0008/2.webp"
			},
			{
				stepName: "Ciabatta aufbacken",
				stepDescription:
					"Schiebe das Ciabatta auf einem Gitterrost in den vorgeheizten Ofen. Nimm es nach einer Backzeit von 8-10 Minuten aus dem Ofen und lass es kurz abkühlen. ",
				stepImage: "./R0008/3.webp"
			},
			{
				stepName: "Suppe kochen",
				stepDescription:
					"Während der Backzeit des Ciabattas kannst du 1 EL Öl in einem großen Topf erhitzen und darin die Paprikawürfel für 5-6 Minuten anbraten. Lösche nun mit der bereits zubereiteten Gemüsebrühe ab und den gehackten Tomaten ab. Füge die Sriracha-Soße hinzu und würze mit etwas Salz, Pfeffer und einer Prise Zucker nach. Die Suppe darf nun abgedeckt bei schwacher Hitze für weitere 10-15 Minten köcheln. ",
				stepImage: "./R0008/4.webp"
			},
			{
				stepName: "Ciabatta überbacken",
				stepDescription:
					"Nachdem du das Ciabatta aus dem Ofen genommen hast, darfst du auf die Grillfunktion umstellen. Schneide das Ciabatta in Scheiben  und lege diese auf einem mit Backpapier vorbereiteten Backblech aus. Bestreiche jeweils Seite der Brotscheiben mit etwas Öl. Reibe anschließend die halbe Knoblauchzehe  darüber.  Streue nun über alle Ciabatta-Hälften Mozzarellastückchen. Schiebe  das Blech auf den höchsten Einschub und lass den Käse für etwa 3-4 Minuten überbacken. ",
				stepImage: "./R0008/5.webp"
			},
			{
				stepName: "Suppe pürieren und servieren",
				stepDescription:
					"Die Kochzeit der Suppe müsste nun auch erreicht sein, sodass du sie vom Herd nehmen kannst. Rühre die Creme fraiche darunter. Püriere nun die Suppe mittels eines Pürierstabes zu einer cremigen Suppe. Nach dem Pürieren kann die Suppe noch für 2 Minuten bei schwacher wärme nachziehen, aufgekocht sollte sie jedoch nicht mehr werden. Serviere die Tomaten-Paprika-Suppe und streue etwas Hartkäse und von dem geschnittenen Basilikum darüber. ",
				stepImage: "./R0008/6.webp"
			}
		]
	},
	{
		id: "R0009",
		geflügel: true,
		rind: false,
		schwein: false,
		fisch: false,
		meeresfrüchte: false,
		milch: false,
		ei: false,
		vegetarisch: false,
		vegan: false,
		lowFat: false,
		LowCarb: true,
		italienisch: true,
		spanisch: true,
		indisch: true,
		japanisch: true,
		chinesisch: true,
		arabisch: true,
		senf: false,
		schwefeldioxid: true,
		sulphite: true,
		nüsse: true,
		gluten: false,
		sellerie: false,
		sesamsamen: false,
		sojabohnen: false,
		laktose: false,
		eierzeugnisse: false,
		sellerieerzeugnisse: false,
		hülsenfrüchte: false,
		titleImage: "./R0009/0.webp",
		name: "Auberginen-Hähnchen-Tajine mit Rosinen und Mandeln",
		level: 3,
		punkte: 3,
		kochzeit: 30,
		supermarktpreis: 2.51,
		kalorien: 634,
		schärfegrad: 1,
		gerichtbeschreibung:
			"Lorem ipsum dolor sit amet, consectetur adipiscing elit. At gravida faucibus consequat nec, dictum quis enim lacus. Dignissim maecenas velit faucibus diam vitae elementum. Sed risus dui aliquam varius dignissim pharetra, hendrerit. Ullamcorper amet enim ipsum faucibus nibh.",
		fancies: [ "Ras-el-Hanout-Gewürzmischung", "Mandeln", "Gemüsebrühgewürz", "Rosinen" ],
		einkaufsliste: [
			"Tomaten",
			"Zwiebel(rot)",
			"Knoblauchzehe",
			"Süßkartoffel",
			"Aubergine",
			"Petersilie",
			"Minze",
			"Hähnchenschenkelfilet"
		],
		zutatenZuhause: [ "Salz", "Pfeffer", "Olivenöl" ],
		kcl: 634,
		fett: 29.1,
		kohlenhydrate: 48.4,
		eiweiß: 44.5,
		utensilien: [ "Pfanne(klein)", "Kochtopf (mittelgroß)", "Sparschäler" ],
		allergene: [ "Schwefeldioxid", "Sulphite", "Nüsse", "kann Spuren von anderen Allergenen enthalten" ],
		steps: [
			{
				stepName: "Zutaten schneiden",
				stepDescription:
					"Schäle, halbiere und würfel so klein wie möglich die Zwiebel und den Knoblauch. Schäle die Süßkartoffel und würfel sie in mundgerechte Stücke. Nachdem du das Fleisch abgetupft hast, kannst du jenes in ebenso große Stücke schneiden. Die Aubergine darfst du längs halbieren und in dünne Scheiben schneiden. Die Tomaten können in Spalten geschnitten werden. ",
				stepImage: "./R0009/1.webp"
			},
			{
				stepName: "Tajine ansetzen",
				stepDescription:
					"Erhitze 1 EL Olivenöl bei mittlerer Hitze in einem mittelgroßen Topf ung gib Zwiebeln, Knoblauch, Tomaten, Rosinen, die Hälfte des Brühgewürzes und 1 Prise Salz dazu. Gieße ohne Anbratzeit 100 ml Wasser auf und lasse es in dem Topf aufkochen. ",
				stepImage: "./R0009/2.webp"
			},
			{
				stepName: "Tajine schichten",
				stepDescription:
					"Die Zutaten werden nun in Schichten in den Topf gegeben. Verteile zuerst die Süßkartoffeln als unterste Schicht im Kopftopf. Streue darüber etwas von dem Brühgewürz und dem Ras-el-Hanout fein darüber. Schichte als nächstes die Auberginen gleichmäßig darüber. Würze die zweite Schicht nach gleichem Vorgehen. Die dritte Schicht bildet das gewürfelte Fleisch. Du darfst die restliche Gewürzmischung nun gleichmäßig darüber streuen.",
				stepImage: "./R0009/3.webp"
			},
			{
				stepName: "Wasser zugeben und köcheln lassen",
				stepDescription:
					"Gib nun soviel heißes Wasser in den Topf bis die Schicht der Süßkartoffeln bedeckt ist. Setze den Deckel auf den Topf und lass die Tajine aufkochen. Sobald es aufgekocht wurde, kannst du die Hitze auf mittlere Stufe zurück drehen und alles für 15 Minuten garen lassen. Nimm nach den 15 Minuten den Deckel ab und lass die Tajine für weitere 10 Minuten köcheln, sodass die Flüssigkeit eindickt. ",
				stepImage: "./R0009/4.webp"
			},
			{
				stepName: "Mandeln rösten und Kräuter schneiden",
				stepDescription:
					"Während der Garzeit kannst du nun die Mandeln grob hacken. Diese werden in einer kleinen Pfanne ohne Öl oder Fett bei mittlerer Hitzesufe leicht angeröstet. 2-3 Minuten soltlen dabei ausreichen, damit sie goldbraun werden. Lass die Mandeln in einem anderen Behältnis, als der Pfanne abkühlen. Schneide die  Minzblätter und hacke die Petersilie inklusive der Stängel. Die Kräuter müssen für das Gericht nicht zwangsläufig ganz fein zerkleinert werden. Gib die Hälfte der geschnittenen Kräuter der Tajine dazu. Erhalte dabei gerne die typische Schichtung der Tajine und würze nach Belieben mit etwas Salz und Pfeffer nach. ",
				stepImage: "./R0009/5.webp"
			},
			{
				stepName: "Anrichten und Servieren",
				stepDescription:
					"Klassisch wird die Speiße in dem Tajine-Topf serviert. Gerne darfst du die Tajine aber auch in einem tiefen Teller oder in einer Schüssel servieren. Streue zum Garnieren die restlichen Kräuter und Mandeln darüber. ",
				stepImage: "./R0009/6.webp"
			}
		]
	},
	{
		id: "R0010",
		geflügel: false,
		rind: false,
		schwein: false,
		fisch: false,
		meeresfrüchte: false,
		milch: false,
		ei: false,
		vegetarisch: true,
		vegan: false,
		lowFat: false,
		LowCarb: false,
		italienisch: true,
		spanisch: true,
		indisch: true,
		japanisch: true,
		chinesisch: true,
		arabisch: true,
		senf: false,
		schwefeldioxid: false,
		sulphite: false,
		nüsse: false,
		gluten: true,
		sellerie: false,
		sesamsamen: false,
		sojabohnen: false,
		laktose: true,
		eierzeugnisse: true,
		sellerieerzeugnisse: true,
		hülsenfrüchte: false,
		titleImage: "./R0010/0.webp",
		name: "Ravioli mit Steinpilzfüllung in Porree-Champignon-Rahm mit Kampot-Pfeffer",
		level: 1,
		punkte: 3,
		kochzeit: 15,
		supermarktpreis: 2.47,
		kalorien: 738,
		schärfegrad: 0,
		gerichtbeschreibung:
			"Lorem ipsum dolor sit amet, consectetur adipiscing elit. At gravida faucibus consequat nec, dictum quis enim lacus. Dignissim maecenas velit faucibus diam vitae elementum. Sed risus dui aliquam varius dignissim pharetra, hendrerit. Ullamcorper amet enim ipsum faucibus nibh.",
		fancies: [ "Kampot-Pfeffer", "Muskatgewürzmischung" ],
		einkaufsliste: [
			"Champignons",
			"Knoblauchzehe",
			"Porree",
			"Petersilie",
			"Schnittlauch",
			"Hartkäse",
			"Kochsahne",
			"Ravioli Funghi Porcini"
		],
		zutatenZuhause: [ "Salz", "Pfeffer", "Pflanzenöl" ],
		kcl: 738,
		fett: 37.06,
		kohlenhydrate: 68.9,
		eiweiß: 30.67,
		utensilien: [ "Pfanne (groß)", "Sieb" ],
		allergene: [
			"Gluten",
			"Milch/-erzeugnisse, Laktose",
			"Eier/Eierzeugnisse",
			"Sellerie/Sellerieerzeugnisse",
			"kann Spuren von anderen Allergenen enthalten"
		],
		steps: [
			{
				stepName: "Wasser aufsetzen",
				stepDescription:
					"Fülle ausreichend Wasser in einen großen Topf, salze es und bringe es auf dem Herd zum kochen.",
				stepImage: "./R0010/1.webp"
			},
			{
				stepName: "Gemüse und Kräter schneiden",
				stepDescription:
					"Entferne die Schale der Knoblauchzehe und hacke sie so fein wie möglich.  Schneide aus dem Poree Halbringe,  indem du ihn längs halbierst und den weißen und hellgrünen Teil in feine Streifen schneidest. Die Champignons dürfen ebenfalls in feine Streifen geschnitten werden. Hacke die Petersilie und den Schnittlauch fein und halte noch alle Zutaten voneinander getrennt. ",
				stepImage: "./R0010/2.webp"
			},
			{
				stepName: "Ravioli kochen und Käse reiben",
				stepDescription:
					"Sobald das Wasser aufgekocht ist, wird die Hitze der Kochplatte auf die mittlere Stufe reduziert, sodass das Wasser aufhört zu kochen. Nun werden die Ravioli hinzugegen. Lasse sie für 3-4 Minuten ziehen und schütte sie anschließend über einem Sieb ab. Während die Ravioli im heißen Wasser sind, kannst du den Hartkäse reiben und anschließend den Kampot-Pfeffer klein hacken. ",
				stepImage: "./R0010/3.webp"
			},
			{
				stepName: "Gemüse anbraten",
				stepDescription:
					"Lasse in einer großen Pfanne 1 EL Pfalnzenöl bei mittlerer Hitzestufe erhitzen. Brate den geschnittenen Porree gemeinsam mit den Champignons und dem Knoblauch für 6-7 Minuten an. ",
				stepImage: "./R0010/4.webp"
			},
			{
				stepName: "Soße fertigstellen und Ravioli hinzufügen ",
				stepDescription:
					"Lösche das gebratene Gemüse nun mit 50 ml Wasser und der Sahne ab. Gib den geriebenen Hartkäse, den Kampot-Pfeffer und die Muskat-Gewürzmischung hinzu und lass alles kurz aufkochen. Sobald die Soße etwas eingedampft ist und cremiger wird, kann die Hitze nochmals reduziert werden. Gib die Ravioli und Kräuter der Soße hinzu, rühre alles vorsichtig um und würze nach Belieben mit Salz und Pfeffer nach. ",
				stepImage: "./R0010/5.webp"
			},
			{
				stepName: "Anrichten und Servieren",
				stepDescription:
					"Die Raviolin können zum Abschluss nochmals fein mit Kampot-Pfeffer bestreut und mit etwas geschnittenem Schnittlauch und Petersilie garniert werden. ",
				stepImage: "./R0010/6.webp"
			}
		]
	},
	{
		id: "R0011",
		geflügel: false,
		rind: false,
		schwein: false,
		fisch: false,
		meeresfrüchte: false,
		milch: false,
		ei: false,
		vegetarisch: true,
		vegan: false,
		lowFat: false,
		LowCarb: false,
		italienisch: true,
		spanisch: true,
		indisch: true,
		japanisch: true,
		chinesisch: true,
		arabisch: true,
		senf: true,
		schwefeldioxid: false,
		sulphite: false,
		nüsse: false,
		gluten: true,
		sellerie: true,
		sesamsamen: false,
		sojabohnen: false,
		laktose: true,
		eierzeugnisse: true,
		sellerieerzeugnisse: false,
		hülsenfrüchte: false,
		titleImage: "./R0011/0.webp",
		name: "Gemüsemaultaschen-Pilz-Pfanne mit Schmorzwiebeln",
		level: 2,
		punkte: 3,
		kochzeit: 15,
		supermarktpreis: 1.69,
		kalorien: 796,
		schärfegrad: 0,
		gerichtbeschreibung:
			"Lorem ipsum dolor sit amet, consectetur adipiscing elit. At gravida faucibus consequat nec, dictum quis enim lacus. Dignissim maecenas velit faucibus diam vitae elementum. Sed risus dui aliquam varius dignissim pharetra, hendrerit. Ullamcorper amet enim ipsum faucibus nibh.",
		fancies: [ "Paprika-Gewürzmischung", "Worcester-Soße" ],
		einkaufsliste: [
			"Zwiebel (rot)",
			"Lauchzwiebeln",
			"Champignons",
			"Austernpilze",
			"Karotte",
			"Kochsahne",
			"Maultaschen"
		],
		zutatenZuhause: [ "Salz", "Pfeffer", "Pflanzenöl" ],
		kcl: 796,
		fett: 34.28,
		kohlenhydrate: 90.08,
		eiweiß: 28.38,
		utensilien: [ "Pfanne (groß)", "Schäler" ],
		allergene: [
			"Senf",
			"Gluten",
			"Sellerie",
			"Milch/-erzeugnisse, Laktose",
			"Eier/Eierzeugnisse",
			"kann Spuren von anderen Allergenen enthalten"
		],
		steps: [
			{
				stepName: "Gemüse schneiden",
				stepDescription:
					"Schäle die rote Zwiebel und schneide feine Streifen, indem du sie vorher halbierst. Die Frühglingszwiebel kann in Ringe geschnitten und die weißen von den grünen getrennt voneinander aufbewahrt werden. Schäle auch die Karotte, halbiere sie und schneide sie in feine Streifen. Viertel die Champignons und zerkleinere die Austernpilze in etwa so dicke Streifen, wie die Möhre. ",
				stepImage: "./R0011/1.webp"
			},
			{
				stepName: "Zwiebel anbraten ",
				stepDescription:
					"Erhitze bei mittlerer Hitzestufe eine große Pfanne mit 1 El Öl. Brate die Streifen der roten Zwiebel nun darin für 6-7 Minuten an. Wenn du 1 Prise Salz zum anbraten hinzugibst, werden die Zwiebeln zügiger braun. Wenn sie goldbraun angebraten sind, bewahre sie in einer Schüssel seperat auf. ",
				stepImage: "./R0011/2.webp"
			},
			{
				stepName: "Soße vorbereiten",
				stepDescription:
					"Verrühre die Kochsahne mit der Gewürzmischung, der Worcestersoße und 50 ml Wasser in einem seperaten Gefäß. ",
				stepImage: "./R0011/3.webp"
			},
			{
				stepName: "Maultaschen mit dem Gemüse anbraten ",
				stepDescription:
					"In der nun frei gewordene Pfanne darfst du wieder 1 EL Öl erhitzen. Gib die Maultaschen, Pilze, weiße Lauchzwiebel und die Karottenscheiben zeitgleich hinzu. Brate alles unter wenden für 5-6 Minuten an. Die Maultaschen müssten nun goldbraun geworden sein. ",
				stepImage: "./R0011/4.webp"
			},
			{
				stepName: "Soße fertigstellen",
				stepDescription:
					"Die Hitze der Pfanne mit den angebratenen Maultaschen kann nun um die Hälfte reduziert werden. Schütte die vorbereitete Soße hinzu und lass alles gemeinsam für weitere 2-3 Minuten sanft köcheln. Sobald die Soße etwas cremiger geworden ist, darfst du noch nach Belieben mit Salz und Pfeffer nachwürzen. ",
				stepImage: "./R0011/5.webp"
			},
			{
				stepName: "Garnieren und Servieren",
				stepDescription:
					"Serviere die Maultaschen in einem tiefen Teller. Streue ein paar geschmorte Röstzwiebeln und etwas von den grünen Lauchzwiebeln darüber. ",
				stepImage: "./R0011/6.webp"
			}
		]
	},
	{
		id: "R0012",
		geflügel: false,
		rind: false,
		schwein: false,
		fisch: false,
		meeresfrüchte: false,
		milch: false,
		ei: false,
		vegetarisch: true,
		vegan: false,
		lowFat: false,
		LowCarb: false,
		italienisch: true,
		spanisch: true,
		indisch: true,
		japanisch: true,
		chinesisch: true,
		arabisch: true,
		senf: false,
		schwefeldioxid: false,
		sulphite: false,
		nüsse: false,
		gluten: true,
		sellerie: false,
		sesamsamen: false,
		sojabohnen: false,
		laktose: false,
		eierzeugnisse: true,
		sellerieerzeugnisse: false,
		hülsenfrüchte: true,
		titleImage: "./R0012/0.webp",
		name: "Erbsensuppe mit Majoran und Kräuteröl",
		level: 1,
		punkte: 3,
		kochzeit: 25,
		supermarktpreis: 2.46,
		kalorien: 582,
		schärfegrad: 0,
		gerichtbeschreibung:
			"Lorem ipsum dolor sit amet, consectetur adipiscing elit. At gravida faucibus consequat nec, dictum quis enim lacus. Dignissim maecenas velit faucibus diam vitae elementum. Sed risus dui aliquam varius dignissim pharetra, hendrerit. Ullamcorper amet enim ipsum faucibus nibh.",
		fancies: [ "Kräuteröl", "Gemüsebrühgewürz" ],
		einkaufsliste: [
			"Zwiebel (weiß)",
			"Knoblauchzehe",
			"Erbsen  (frisch/tiefgekühlt)",
			"Majoran",
			"Wiener Würstchen"
		],
		zutatenZuhause: [ "Salz", "Pfeffer" ],
		kcl: 582,
		fett: 25.6,
		kohlenhydrate: 55.8,
		eiweiß: 29.9,
		utensilien: [ "Pfanne (groß)", "Pürierstab" ],
		allergene: [ "Gluten", "Eier/Eierzeugnisse", "Hülsenfrüchte", "kann Spuren von anderen Allergenen enthalten" ],
		steps: [
			{
				stepName: "Am Vortrag Erbsen einweichen",
				stepDescription:
					"Wenn du mit frischen Erbsen kochen möchtest, solltest du sie einen Tag vorher in ausreichend Wasser einweichen lassen. Nach Belieben kann bereits etwas Salz hinzugegeben werden. Für eine Erbsensuppe eignen sich jedoch auch die tiefgekühlten sehr gut, denn dann entfällt dieser erste Schritt. ",
				stepImage: "./R0012/1.webp"
			},
			{
				stepName: "Zutaten schneiden",
				stepDescription:
					"Schäle die Zwiebeln und Knoblauchzehen. Würfel sie sehr fein und hacke anschließend den Majoran. Stelle die Zutaten beiseite. Löse die Gemüsebrühe in 1 Liter Wasser auf. ",
				stepImage: "./R0012/2.webp"
			},
			{
				stepName: "Zwiebeln anbraten",
				stepDescription:
					"Erhitze in einem großen Topf 1-2 EL Öl und schwitze darin für 5 Minuten den Knoblauch und die Zwiebeln an. Gib die eingeweichten (oder bereits aufgetauten Erbsen) hinzu und lass sie für weitere 2-3 Minuten mitdünsten. ",
				stepImage: "./R0012/3.webp"
			},
			{
				stepName: "Mit Gemüsebrühe ablöschen",
				stepDescription:
					"Lösche den Inhalt des Topfes nun mit der angerührten Gemüsebrühe ab. Wenn gewünscht kann mit mehr Brühe oder Wasser aufgefüllt werden. ",
				stepImage: "./R0012/4.webp"
			},
			{
				stepName: "Suppe pürieren",
				stepDescription:
					"Nachdem die Suppe für 10-15 Minuten geköchelt hat und die Erbsen weich geworden sind, darfst du alles mit einem Pürierstab grob pürieren. ",
				stepImage: "./R0012/5.webp"
			},
			{
				stepName: "Abschmecken und Servieren",
				stepDescription:
					"Gib den gehackten Majoran dazu und würze mit Salz und Pfeffer nach. Gegebenenfalls können nun noch die Wiener-Würstchen mit in der Suppe erhitzt werden. Serviere die Suppe in einem tiefen Teller. Garniere mit etwas von dem frisch gehackten Majoran und träufel etwas von dem Kräuteröl darüber. ",
				stepImage: "./R0012/6.webp"
			}
		]
	},
	{
		id: "R0013",
		geflügel: false,
		rind: true,
		schwein: false,
		fisch: false,
		meeresfrüchte: false,
		milch: false,
		ei: false,
		vegetarisch: false,
		vegan: false,
		lowFat: false,
		LowCarb: false,
		italienisch: true,
		spanisch: true,
		indisch: true,
		japanisch: true,
		chinesisch: true,
		arabisch: true,
		senf: false,
		schwefeldioxid: false,
		sulphite: false,
		nüsse: true,
		gluten: true,
		sellerie: false,
		sesamsamen: true,
		sojabohnen: false,
		laktose: false,
		eierzeugnisse: false,
		sellerieerzeugnisse: false,
		hülsenfrüchte: false,
		titleImage: "./R0013/0.webp",
		name: " Rindfleisch auf Vollkornreisnudeln und Salat (asiatischer Art)",
		level: 1,
		punkte: 3,
		kochzeit: 20,
		supermarktpreis: 4.21,
		kalorien: 440,
		schärfegrad: 3,
		gerichtbeschreibung:
			"Lorem ipsum dolor sit amet, consectetur adipiscing elit. At gravida faucibus consequat nec, dictum quis enim lacus. Dignissim maecenas velit faucibus diam vitae elementum. Sed risus dui aliquam varius dignissim pharetra, hendrerit. Ullamcorper amet enim ipsum faucibus nibh.",
		fancies: [
			"Chili-Gewürz",
			"Sternanis",
			"Erdnusskerne (ungesalzen)",
			"Sesamöl",
			"Sojasauce",
			"Fischsauce",
			"Honig"
		],
		einkaufsliste: [
			"Kirschtomaten",
			"Lauchzwiebeln",
			"Babyspinat",
			"Knoblauchzehe",
			"Ingwer (frisch)",
			"Radieschen",
			"Karotte",
			"Blattsalat",
			"Limette",
			"Koriander",
			"Kresse",
			"Vollkornreisnudeln",
			"Rinderhack"
		],
		zutatenZuhause: [ "Salz", "Pfeffer", "" ],
		kcl: 440,
		fett: 20.3,
		kohlenhydrate: 37.8,
		eiweiß: 27.5,
		utensilien: [ "", "", "", "", "", "" ],
		allergene: [ "", "", "", "", "" ],
		steps: [
			{
				stepName: "Erdnüsse rösten",
				stepDescription:
					"Röste in einer Pfanne ohne Zugabe von Ölen oder Fetten die Erdnüsse bei mittler Hitzestufe für 3-4 Minuten an. Zerkleinere sie anschließend mittels eines Möhrsers oder hacke sie mit dem Messer klein. Stelle sie beiseite. ",
				stepImage: "./R0013/1.webp"
			},
			{
				stepName: "Hackfleischpfanne zubereiten",
				stepDescription:
					"Schäle die Knoblauchzehen und den Ingwer und würfel sie fein. Erhitze die Pfanne bei mittlerer Temperatur und lass das Sesamöl zusammen mit den Sternanis erhitzen. Brate das Hackfleisch mit an und zerkleinere es währenddessen. Vermenge die Hälfte des Knoblauchs und Ingwers darunter. Rühre den Honig zusammen mit dem Chilipulver zum Schluss mit hinein. Schmecke mit Salz und Pfeffer ab. ",
				stepImage: "./R0013/2.webp"
			},
			{
				stepName: "Dressing anrühren",
				stepDescription:
					"In der Zwischenzeit kannst die Limetten halbieren und auspressen. Fange den Saft in einer kleinen Schale auf. Vermenge den Limettensaft mit der Fisch- und Sojasoße. Zerstoße den restlichen Ingwer und Knoblauch im Mörser breiig und rühre diese Masse ebenfalls in der kleinen Schale unter. ",
				stepImage: "./R0013/3.webp"
			},
			{
				stepName: "Vollkornreisnudeln kochen",
				stepDescription:
					"Setze in einem Topf ausreichend leicht gesalzenes Wasser auf und lass die Nudeln nach Packungsangabe darin kochen.",
				stepImage: "./R0013/4.webp"
			},
			{
				stepName: "Salat vorbereiten",
				stepDescription:
					"Wasche die Lauchzwiebeln und schneide sie in feine Ringe. Schäle die Karotte und schneide sie mit dem Blattsalat, den Radieschen und Kirschtomaten nach Belieben in mundgerechte Stücke. Wasche den Spinat und vermenge ihn mit dem Salatgemüse in einer Schüssel. Je nach Gustus kann zusätzlich eine Chilischote geschnitten und hinzugegeben werden. ",
				stepImage: "./R0013/5.webp"
			},
			{
				stepName: "Anrichten und Servieren",
				stepDescription:
					"Richte zunächst den Salat auf dem Teller an. Schichte die abgetropften Nudeln darüber. Träufel nun etwas Salatdressing über die Nudeln und platziere das knusprige Fleisch darüber. Garniere mit den zerkleinerten Erdnüssen und ein paar Korianderblättern. ",
				stepImage: "./R0013/6.webp"
			}
		]
	},
	{
		id: "R0014",
		geflügel: false,
		rind: false,
		schwein: false,
		fisch: false,
		meeresfrüchte: false,
		milch: false,
		ei: false,
		vegetarisch: false,
		vegan: false,
		lowFat: true,
		LowCarb: false,
		italienisch: true,
		spanisch: true,
		indisch: true,
		japanisch: true,
		chinesisch: true,
		arabisch: true,
		senf: false,
		schwefeldioxid: false,
		sulphite: false,
		nüsse: false,
		gluten: false,
		sellerie: false,
		sesamsamen: false,
		sojabohnen: false,
		laktose: false,
		eierzeugnisse: false,
		sellerieerzeugnisse: false,
		hülsenfrüchte: false,
		titleImage: "./R0014/0.webp",
		name: "Koreanische Rindfleisch-Pfanne mit Gojuchan",
		punkte: 3,
		kalorien: 677,
		gerichtbeschreibung:
			"Lorem ipsum dolor sit amet, consectetur adipiscing elit. At gravida faucibus consequat nec, dictum quis enim lacus. Dignissim maecenas velit faucibus diam vitae elementum. Sed risus dui aliquam varius dignissim pharetra, hendrerit. Ullamcorper amet enim ipsum faucibus nibh.",
		fancies: [],
		einkaufsliste: [ "Fetakäse", "Eier" ],
		zutatenZuhause: [ "Salz", "Pfeffer", "Olivenöl" ],
		kcl: 677,
		fett: 30.5,
		kohlenhydrate: 58.1,
		eiweiß: 50.5,
		utensilien: [],
		allergene: [],
		steps: [
			{
				stepName: "",
				stepDescription: "",
				stepImage: "./R0014/1.webp"
			},
			{
				stepName: "",
				stepDescription: "",
				stepImage: "./R0014/2.webp"
			},
			{
				stepName: "",
				stepDescription: "",
				stepImage: "./R0014/3.webp"
			},
			{
				stepName: "",
				stepDescription: "",
				stepImage: "./R0014/4.webp"
			},
			{
				stepName: "",
				stepDescription: "",
				stepImage: "./R0014/5.webp"
			},
			{
				stepName: "",
				stepDescription: "",
				stepImage: "./R0014/6.webp"
			}
		]
	},
	{
		id: "R0015",
		geflügel: false,
		rind: false,
		schwein: false,
		fisch: false,
		meeresfrüchte: false,
		milch: false,
		ei: false,
		vegetarisch: true,
		vegan: false,
		lowFat: false,
		LowCarb: false,
		italienisch: true,
		spanisch: true,
		indisch: true,
		japanisch: true,
		chinesisch: true,
		arabisch: true,
		senf: false,
		schwefeldioxid: false,
		sulphite: false,
		nüsse: false,
		gluten: false,
		sellerie: false,
		sesamsamen: false,
		sojabohnen: false,
		laktose: true,
		eierzeugnisse: true,
		sellerieerzeugnisse: false,
		hülsenfrüchte: false,
		titleImage: "./R0015/0.webp",
		name: "Shashuka mit Käse und Ei in würziger Gemüse-Tomatensoße",
		level: 2,
		punkte: 3,
		kochzeit: 25,
		supermarktpreis: 3.19,
		kalorien: 582,
		schärfegrad: 1,
		gerichtbeschreibung:
			"Lorem ipsum dolor sit amet, consectetur adipiscing elit. At gravida faucibus consequat nec, dictum quis enim lacus. Dignissim maecenas velit faucibus diam vitae elementum. Sed risus dui aliquam varius dignissim pharetra, hendrerit. Ullamcorper amet enim ipsum faucibus nibh.",
		fancies: [ "Mexikanische Gewürzmischung" ],
		einkaufsliste: [
			"Zwiebel (rot)",
			"Babyspinat",
			"Knoblauchzehe",
			"Paprika (grün)",
			"Süßkartoffel",
			"Passierte Tomaten",
			"Schwarze Bohnen (Dose)"
		],
		zutatenZuhause: [],
		kcl: 582,
		fett: 25.6,
		kohlenhydrate: 55.8,
		eiweiß: 29.9,
		utensilien: [ "Pfanne (groß)", "Sieb", "Messbecher", "Schäler" ],
		allergene: [
			"Milch/-erzeugnisse, Laktose",
			"Eier/Eierzeugnisse",
			"kann Spuren von anderen Allergenen enthalten"
		],
		steps: [
			{
				stepName: "Spinat waschen u. Bohnen abgießen",
				stepDescription:
					"Reinige die frischen Spinatblätter. unter fließendem Wasser Hacke davon die Hälfte grob durch und belasse für die Garnierung die andere Hälfte am Stück. Gieße die Bohnen über einem Sieb ab  und lass danach kurz kaltes Wasser darüber laufen und abtropfen. ",
				stepImage: "./R0015/1.webp"
			},
			{
				stepName: "Gemüse schneiden ",
				stepDescription:
					"Schäle die Zwiebel und den Knoblauch und würfel sie danach in feine Stückchen. Entkerne die Paprika und würfel sie ebenfalls in ca. 0,5cm kleine Stücke. Schäle nun die Süßkartoffel und schneide sie ebenfalls in 0,5 bis 1cm große Stückchen. ",
				stepImage: "./R0015/2.webp"
			},
			{
				stepName: "Gemüse anbraten",
				stepDescription:
					"Erhitze in einer großen Pfanne 1 EL Olivenöl bei mittlerer Hitzestufe. Gib die Kartoffen, Zwiebeln und Paprika zeitgleich hinzu und lasse alles zusammen unter gelegentlichem Rühren für etwa 8-10 Minuten anbraten. Mische jeweils 1 Prise Salz, Pfeffer und Zucker darunter. ",
				stepImage: "./R0015/3.webp"
			},
			{
				stepName: "Gemüse ablöschen",
				stepDescription:
					"Lösche das gebratene Gemüse mit 100-150 ml Wasser ab und gib die passierten Tomaten hinzu. Lasse alles nun einmal unter starker Hitze aufkochen und reduziere die Hitzezufuhr anschließend wieder auf die niedrigste Stufe. Würze nun mit der mexikanischen Gewürzmischung und nach Belieben mit Salz und Pfeffer nach. Gib nun den Knoblauch hinzu und lasse das Wasser langsam verdampfen, indem du alles 10-15 Minuten sanft weiter köcheln lässt. Falls die Kartoffeln nach der Zeit noch nicht gar sein sollten, gib etwas Wasser nach und lasse es nach gleichem Vorgehen wieder verdampfen. ",
				stepImage: "./R0015/4.webp"
			},
			{
				stepName: "Bohnen und Spinat hinzugeben, Eier garen",
				stepDescription:
					"Mische nun den gehackten Spinat und die abgetropften Bohnen unter. Forme nun mit einem Löffel zwei Gruben und gib in jede ein Ei hinein. Bei niedrigster Hitzestufe sollte das Eiweiß nach 5 Minuten anfangen zu stocken.",
				stepImage: "./R0015/5.webp"
			},
			{
				stepName: "Garnieren und Servieren",
				stepDescription:
					"Brösel mit den Fingern den Fetakäse gleichmäßig über das Gericht. Shakshuka wird typischerweise in der Pfanne serviert. Garniere die Speiße einfach noch mit dem restlichen Spinat.",
				stepImage: "./R0015/6.webp"
			}
		]
	},
	{
		id: "R0016",
		geflügel: true,
		rind: false,
		schwein: false,
		fisch: false,
		meeresfrüchte: false,
		milch: false,
		ei: false,
		vegetarisch: false,
		vegan: false,
		lowFat: false,
		LowCarb: false,
		italienisch: true,
		spanisch: true,
		indisch: true,
		japanisch: true,
		chinesisch: true,
		arabisch: true,
		senf: false,
		schwefeldioxid: false,
		sulphite: false,
		nüsse: false,
		gluten: false,
		sellerie: false,
		sesamsamen: false,
		sojabohnen: false,
		laktose: true,
		eierzeugnisse: false,
		sellerieerzeugnisse: false,
		hülsenfrüchte: false,
		titleImage: "./R0016/0.webp",
		name: "Garam-Masala-Hähnchen auf Linsen-Spinat-Curry",
		level: 1,
		punkte: 3,
		kochzeit: 25,
		supermarktpreis: 1.72,
		kalorien: 677,
		schärfegrad: 4,
		gerichtbeschreibung:
			"Lorem ipsum dolor sit amet, consectetur adipiscing elit. At gravida faucibus consequat nec, dictum quis enim lacus. Dignissim maecenas velit faucibus diam vitae elementum. Sed risus dui aliquam varius dignissim pharetra, hendrerit. Ullamcorper amet enim ipsum faucibus nibh.",
		fancies: [ "Garam-Masala-Gewürzmischung", "Gemüsebrühgewürz" ],
		einkaufsliste: [
			"Zwiebel (weiß)",
			"Babyspinat",
			"Knoblauchzehe",
			"Ingwer (frisch)",
			"Gehackte Tomaten",
			"Peperoni (rot)",
			"Naturjoghurt",
			"Linsen (rot)",
			"Hähnchenbrustfilet"
		],
		zutatenZuhause: [ "Salz", "Pfeffer", "Pflanzenöl", "Butter" ],
		kcl: 677,
		fett: 30.5,
		kohlenhydrate: 58.1,
		eiweiß: 50.5,
		utensilien: [ "Pfanne (mittelgroß)", "Kochtopf (mittelgroß)", "Küchenreibe", "Messbecher", "Küchenwaage" ],
		allergene: [ "Milch/-erzeugnisse, Laktose", "kann Spuren von anderen Allergenen enthalten" ],
		steps: [
			{
				stepName: "Gemüse vorbereiten",
				stepDescription:
					"Schäle den Knoblauch und Ingwer. Reibe beides anschließend mit einer Reibe fein. Halbiere die Peperoni. Je nach Gustus können die Kerne entfernt werden, um den Schärfegrad des Gerichts zu reduzieren. Schneide die Peperonie in hauchdünne Streifen. Schäle und Würfel auch die Zwiebel. ",
				stepImage: "./R0016/1.webp"
			},
			{
				stepName: "Hähnchenbrustfilets marinieren",
				stepDescription:
					"Wenn das Filet am Stück gekauft ist, halbiere es horizontal. Tupfe das Fleisch mit etwas Küchenpapier trocken und mariniere jede Seite mit etwas von der Garam-Masala-Gewürzmischung. Hebe jedoch die Hälfte der Gewürzmischung für später auf.",
				stepImage: "./R0016/2.webp"
			},
			{
				stepName: "Gemüse braten und ablöschen",
				stepDescription:
					"Erhitze in einem Topf 1-2 EL Pflanzenöl. Brate bei mittlerer Hitze die Zwiebeln für 2 Minuten an und vermenge den Knoblauch, den Ingwer und die Hälfte der Peperoniestreifen darunter. Gib neben den gehackten Tomaten noch 250 ml Wasser hinzu. Bringe alles einmal kräftig zum kochen.",
				stepImage: "./R0016/3.webp"
			},
			{
				stepName: "Linsen hinzugeben",
				stepDescription:
					"Sobald die Flüssigkeit kocht, darfst du das Gemüsebrühgewürz unterrühren. Gib nun auch die roten Linsen hinzu und reduziere die Hitze auf die mittlere Stufe. Lass das Curry nun für 15 Minuten abgedeckt weiter köcheln. Je nach Gustus kann die Garzeit um weitere 5 Minuten verlängert werden, damit das Gericht noch cremiger wird. ",
				stepImage: "./R0016/4.webp"
			},
			{
				stepName: "Fleisch anbraten, Bratensaft ins Curry rühren",
				stepDescription:
					"Erhitze in einer mittelgroßen Pfanne 1-2 EL Pflanzenöl. Brate das Fleisch auf jeder Seite bei starker Hitze für 2 Minuten scharf an. Nimm es aus der Pfanne, sobald es in der Mitte gar ist. Verrühre in dem Bratensaft nun 1 EL Butter und gib den Bratensaft mit in den Curry-Topf. Die Pfanne wird nun nicht mehr benötigt. Rühre jetzt den Naturjoghurt in den Topf. Würze mit der restlichen Gewürzmischung, Salz und Pfeffer nach. ",
				stepImage: "./R0016/5.webp"
			},
			{
				stepName: "Spinat hinzugeben und Servieren",
				stepDescription:
					"Vermenge zum Schluss den Babyspinat, sodass dieser zusammenfällt. Gib je nach Schärfegrad die restlichen Peperoniestreifen hinzu. Schneide das gebratene Fleisch in feine Streifen und richte es über dem Linsen-Spinat-Curry auf dem Teller an. Der Teller kann mit etwas Joghurt und unverkochten Babyspinatblättern verziert werden. ",
				stepImage: "./R0016/6.webp"
			}
		]
	},
	{
		id: "R0017",
		geflügel: true,
		rind: false,
		schwein: false,
		fisch: false,
		meeresfrüchte: false,
		milch: false,
		ei: false,
		vegetarisch: false,
		vegan: false,
		lowFat: false,
		LowCarb: false,
		italienisch: true,
		spanisch: true,
		indisch: true,
		japanisch: true,
		chinesisch: true,
		arabisch: true,
		senf: false,
		schwefeldioxid: false,
		sulphite: false,
		nüsse: true,
		gluten: true,
		sellerie: false,
		sesamsamen: false,
		sojabohnen: false,
		laktose: true,
		eierzeugnisse: false,
		sellerieerzeugnisse: false,
		hülsenfrüchte: false,
		titleImage: "./R0017/0.webp",
		name: "Hähnchen-Pasta mit Birne",
		level: 1,
		punkte: 3,
		kochzeit: 20,
		supermarktpreis: 3.23,
		kalorien: 893,
		schärfegrad: 0,
		gerichtbeschreibung:
			"Lorem ipsum dolor sit amet, consectetur adipiscing elit. At gravida faucibus consequat nec, dictum quis enim lacus. Dignissim maecenas velit faucibus diam vitae elementum. Sed risus dui aliquam varius dignissim pharetra, hendrerit. Ullamcorper amet enim ipsum faucibus nibh.",
		fancies: [ "Pistazienkerne" ],
		einkaufsliste: [
			"Babyspinat",
			"Knoblauchzehe",
			"Birne",
			"Basilikum",
			"Thymian",
			"Hartkäse",
			"Nudeln",
			"Hähnchenbrustfilet"
		],
		zutatenZuhause: [ "Salz", "Pfeffer", "Olivenöl", "Essig" ],
		kcl: 893,
		fett: 27.9,
		kohlenhydrate: 107.3,
		eiweiß: 49.4,
		utensilien: [
			"Pfanne(mittelgroß)",
			"Kochtopf (mittelgroß)",
			"Küchenreibe",
			"Sieb",
			"Messbecher",
			"Pürierstab"
		],
		allergene: [ "Nüsse", "Gluten", "Milch/-erzeugnisse, Laktose", "kann Spuren von anderen Allergenen enthalten" ],
		steps: [
			{
				stepName: "Pesto pürieren",
				stepDescription:
					"Setze in einem Topf gesalzenes Wasser zum Kochen auf. Schäle den Knoblauch und würfel ihn ganz fein. Reibe den Hartkäse. Wasche die Kräuter, tupfe sie trocken und hacke sie grob durch. Verrühre nun den Knoblauch, die Hälfte des Käses, den Pistazien, 20g Spinat, 3 EL Wasser, 2 EL Olivenöl, 1 EL Essig und einer Prise Salz in einem hohen Gefäß. Nimm nun den Pürierstab, sodass ein Pesto entsteht. ",
				stepImage: "./R0017/1.webp"
			},
			{
				stepName: "Birne würfeln",
				stepDescription: "Entkerne die Birne und würfel sie in möglichst kleine Stückchen. ",
				stepImage: "./R0017/2.webp"
			},
			{
				stepName: "Birne sanft köcheln",
				stepDescription:
					"Gib in eine mittelgroße Pfanne 50 ml Wasser, 2 EL Essig und 1 TL von dem Pesto und erhitze die Flüssigkeit bis kurz vor dem Siedepunkt. Gib die geschnittene Birne hinein und würze mit einer Prise Salz und Pfeffer nach. Reduziere die Hitzezufuhr auf mittlere Stufe und lass bei geschlossenem Deckel die Birne 3 Minuten garen. Die Birne darf gerne noch bissfest sein, wenn du sie über einem Sieb abgießt. Stelle die Birnenstücke beiseite und reinige die Pfanne.",
				stepImage: "./R0017/3.webp"
			},
			{
				stepName: "Nudeln kochen",
				stepDescription:
					"In der Zwischenzeit sollte das aufgesetzte Nudelwasser angefangen haben zu kochen. Gib die Pasta in das Wasser und lass sie für 6-8 Minuten bissfest kochen. Bevor das Nudelwasser abgeschüttet wird, entnehme etwas Pastawasser mit einem Messbecher. Gieße die Pasta über einem Sieb ab und lass sie abtropfen. ",
				stepImage: "./R0017/4.webp"
			},
			{
				stepName: "Hähnchenbrustfilets anbraten",
				stepDescription:
					"Tupfe das Hähnchenfleisch mit Küchenpapier trocken und schneide Würfel daraus. Mariniere sie mit 1 TL des Pesto. Erhitze in der ausgewaschenen Pfanne 1 EL Olivenöl und brate das Fleisch bei hoher Hitzestufe für 4 Minuten auf allen Seiten an. Streue 1 Prise Salz und Pfeffer darüber. ",
				stepImage: "./R0017/5.webp"
			},
			{
				stepName: "Zutaten vermengen",
				stepDescription:
					"Gib die Pasta in den Topf und rühre das restliche Pesto unter. Gib dabei nach Bedarf 20-30ml Pastawasser hinzu. Hebe den restlichen Babyspinat unter und würze nach Belieben mit Salz und Pfeffer nach. Serviere die Pasta auf einem großen Teller und garniere mit den Birnenstückchen und etwas geriebenem Käse.",
				stepImage: "./R0017/6.webp"
			}
		]
	},
	{
		id: "R0018",
		geflügel: false,
		rind: false,
		schwein: true,
		fisch: false,
		meeresfrüchte: false,
		milch: false,
		ei: false,
		vegetarisch: false,
		vegan: false,
		lowFat: false,
		LowCarb: false,
		italienisch: true,
		spanisch: true,
		indisch: true,
		japanisch: true,
		chinesisch: true,
		arabisch: true,
		senf: false,
		schwefeldioxid: false,
		sulphite: false,
		nüsse: false,
		gluten: true,
		sellerie: false,
		sesamsamen: false,
		sojabohnen: false,
		laktose: false,
		eierzeugnisse: true,
		sellerieerzeugnisse: false,
		hülsenfrüchte: false,
		titleImage: "./R0018/0.webp",
		name: "Schnitzel mit Panko-Panade dazu Backkartoffeln und Gurkensalat",
		level: 1,
		punkte: 3,
		kochzeit: 30,
		supermarktpreis: 2.15,
		kalorien: 683,
		schärfegrad: 0,
		gerichtbeschreibung:
			"Lorem ipsum dolor sit amet, consectetur adipiscing elit. At gravida faucibus consequat nec, dictum quis enim lacus. Dignissim maecenas velit faucibus diam vitae elementum. Sed risus dui aliquam varius dignissim pharetra, hendrerit. Ullamcorper amet enim ipsum faucibus nibh.",
		fancies: [ "Panko-Brösel" ],
		einkaufsliste: [ "Salatgurke", "Zitrone", "Dill", "Eier", "Kartoffeln(festkochend)", "Schweinerückensteak" ],
		zutatenZuhause: [ "Salz", "Pfeffer", "Zucker", "Pflanzenöl", "Essig", "Mehl" ],
		kcl: 683,
		fett: 28.4,
		kohlenhydrate: 65.3,
		eiweiß: 44,
		utensilien: [ "Backofen", "Backblech", "Backpapier", "Fleischklopfer", "Küchenwaage", "Frischhaltefolie" ],
		allergene: [ "Gluten", "Eier/Eierzeugnisse", "kann Spuren von anderen Allergenen enthalten" ],
		steps: [
			{
				stepName: "Kartoffelspalten backen",
				stepDescription:
					"Heize den Backofen auf 200°C Umluft oder 220°C Ober-/Unterhitze vor. Schneide die Kartoffeln in Spalten. Kleine Kartoffeln können hierfür geviertelt und größere geachtelt werden. Bereite auf einem Backblech Backpapier vor und verteile die Kartoffelspalten gleichmäßig. Rühre unter 2EL Pflanzenöl etwas Salz und Pfeffer. Träufel das Öl gleichmäßig über den Kartoffeln und lass alles für 25-30 Minuten rösten. ",
				stepImage: "./R0018/1.webp"
			},
			{
				stepName: "Salat zubereiten",
				stepDescription:
					"Wasche die Gurken und halbiere sie längs. Schneide sie in Scheiben und mische 1-2 Prisen Salz darunter. Während sie 5 Minuten ruhen, kannst du die Dillspitzen fein hacken und unterrühren. Rühre als Salatdressing 2 EL Pflanzenöl, 2 EL Essig, 1 Prise Salz und Pfeffer zusammen. Vermenge den Salat gut mit dem Dressing und lass ihn ruhen.",
				stepImage: "./R0018/2.webp"
			},
			{
				stepName: "Schnitzel klopfen",
				stepDescription:
					"Wenn das Fleisch am Stück gekauft wurde, tupfe es mit etwas Küchenpapier trocken und halbiere es längs. Lege über jedes Stück Fleisch ein oder zwei Schichten Frischhaltefolie und bearbeite sie mit einem Fleischklopfer. Das typische Wiener-Schnitzel ist 5mm dick, damit es während des Bratvorgangs wirklich gar wird.",
				stepImage: "./R0018/3.webp"
			},
			{
				stepName: "Schnitzel panieren",
				stepDescription:
					"Würze die Schnitzel beidseitig mit Salz und Pfeffer. Bereite die Panierstraße vor. Du benötigst 3 tiefe Teller. In den Ersten gibst du etwas Mehl, in den Zweiten verquirlst du 2 Eier und in den Dritten tust du die Panko-Brösel. Paniere nun jedes Schnitzel beidseitig in dieser Reihenfolge: erst im Mehl, dann im Ei und anschließend in den Bröseln.",
				stepImage: "./R0018/4.webp"
			},
			{
				stepName: "Schnitzel braten",
				stepDescription:
					"Erhitze in einer großen Pfanne 3 EL Pflanzenöl und brate die Schnitzel von jeder Seite bei mittlerer Hitze für 2-3 Minuten an. Die Panade sollte goldgelb und knusprig werden. ",
				stepImage: "./R0018/5.webp"
			},
			{
				stepName: "Öl abtupfen und Servieren",
				stepDescription:
					"Schneide ein paar Zitronenscheiben oder -Spalten. Lege etwas Küchenpapier aus. Wenn du die Schnitzel aus der Pfanne holst, lege sie kurz auf das Küchenkrepp, damit überschüssiges Fett abtropfen kann. Serviere die Schnitzel mit dem Salat und den Kartoffelspalten und garniere mit den Zitronenscheiben. ",
				stepImage: "./R0018/6.webp"
			}
		]
	},
	{
		id: "R0019",
		geflügel: true,
		rind: false,
		schwein: false,
		fisch: false,
		meeresfrüchte: false,
		milch: false,
		ei: false,
		vegetarisch: false,
		vegan: false,
		lowFat: false,
		LowCarb: false,
		italienisch: true,
		spanisch: true,
		indisch: true,
		japanisch: true,
		chinesisch: true,
		arabisch: true,
		senf: false,
		schwefeldioxid: true,
		sulphite: false,
		nüsse: false,
		gluten: true,
		sellerie: false,
		sesamsamen: false,
		sojabohnen: false,
		laktose: true,
		eierzeugnisse: true,
		sellerieerzeugnisse: false,
		hülsenfrüchte: true,
		titleImage: "./R0019/0.webp",
		name: "Gebratene Ente in Orangensoße dazu Cranberry-Rotkohl und Semmelknödel",
		level: 3,
		punkte: 3,
		kochzeit: 120,
		supermarktpreis: 8.12,
		kalorien: 582,
		schärfegrad: 0,
		gerichtbeschreibung:
			"Lorem ipsum dolor sit amet, consectetur adipiscing elit. At gravida faucibus consequat nec, dictum quis enim lacus. Dignissim maecenas velit faucibus diam vitae elementum. Sed risus dui aliquam varius dignissim pharetra, hendrerit. Ullamcorper amet enim ipsum faucibus nibh.",
		fancies: [
			"Muskatgewürzmischung",
			"Gewürznelken (gemahlen)",
			"Lorbeerblatt",
			"Rotweinessig",
			"Orangenlikör",
			"Geflügelbrühgewürz",
			"Ahornsirup",
			"Gänseschmalz",
			"Orangenmarmelade",
			"Getrocknete Steinpilze",
			"Getrocknete Cranberries",
			"Getrocknete Wacholderbeeren"
		],
		einkaufsliste: [
			"Zwiebel(weiß)",
			"Knoblauchzehe",
			"Rotkohl",
			"Orange",
			"Apfel",
			"Thymian",
			"Eier",
			"Butter",
			"Milch",
			"Semmelwürfel/Semmelbrösel",
			"Entenbrust",
			"Cranberrysaft"
		],
		zutatenZuhause: [ "Salz", "Pfeffer", "Pflanzenöl", "Butter" ],
		kcl: 582,
		fett: 25.6,
		kohlenhydrate: 55.8,
		eiweiß: 29.9,
		utensilien: [
			"Pfanne(mittelgroß)",
			"Schmortopf/Topf(groß, ofenfest)",
			"Kochtopf(groß)",
			"Zitronenpresse",
			"Backofen",
			"Gitterrost",
			"Schüssel(groß)"
		],
		allergene: [
			"Schwefeldioxid",
			"Gluten",
			"Milch/-erzeugnisse, Laktose",
			"Eier/Eierzeugnisse",
			"Hülsenfrüchte",
			"kann Spuren von anderen Allergenen enthalten"
		],
		steps: [
			{
				stepName: "Rotkohl vorbereiten",
				stepDescription:
					"Wasche zuerst den Rotkohl, viertel ihn und entferne den Strunk in der Mitte. Schneide nun ganz feine Streifen und halbiere diese noch einmal. Stelle ihn beiseite. Schäle eine Zwiebel und würfel sie fein. Entferne auch von den 2 Äpfeln die Schale und würfel sie in mundgerechte Stücke. Heize nun den Ofen auf 160°C Umluft vor. Erhitze in einem großen ofenfesten Topf das Gänseschmalz und brate die geschnittene Zwiebel gemeinsam mit den Apfelstücken für 3-5 Minuten an. Gib anschließend das Rotkohl hinzu und lass alles nocheinmal kräftig für 7 Minuten anbraten. Rühre nun die Lorbeerblätter, das Nelkengewürz, den Ahornsirup, den Rotweinessig, 200 ml Cranberrysaft, 300 ml Wasser und die Wacholderbeeren unter. ",
				stepImage: "./R0019/1.webp"
			},
			{
				stepName: "Rotkohl im Ofen schmoren lassen",
				stepDescription:
					"Lasse den Rotkohl für 2 Stunden im Ofen schmoren. Während der ersten Stunde sollte der Deckel geschlossen sein. In der zweiten Stunde kann das Rotkohl ohne Deckel weiter schmoren. Rühre alle 20 Minuten kräftig um. Falls das Wasser zu schnell verdünsten sollte, kannst du 50-100ml zusätzlich hineingeben. Gib erst in der zweiten Stunde die getrockneten Cranberrys hinzu. Du darfst du die Menge der Cranberrys an deinen Geschmack anpassen. ",
				stepImage: "./R0019/2.webp"
			},
			{
				stepName: "Semmelknödel vorbereiten",
				stepDescription:
					"Während das Rotkohl im Ofen ist, darfst du die getrockneten Steinpilze in einer kleinen Schale in etwa 50ml Wasser einweichen lassen. Schäle nun eine Zwiebel und würfel sie klein. Falls du keine fertigen Semmelwürfel gekauft hast, reibe 4 alte Brötchen ab. Gib die Semmelwürfel in eine große Schüssel. Erwärme in einer mittelgroßen Pfanne etwas Butter und dünste die Zwiebel glasig an. Rühre nun 150 ml Milch und die Steinpilze inklusive des Wassers, worin sie eingeweicht sind, mit in die Pfanne. Lasse diese Flüssigkeit nun etwas erwärmen, aber nicht aufkochen. Verquirle 2 Eier miteinander und gib diese mit zu den Semmelwürfeln in die Schüssel. Schütte den Pfanneninhalt nun auch dazu und würze mit dem Muskatgewürz und einer Prise Salz und Pfeffer nach. Verknete alles gut miteinander und lasse die Masse für weitere 10 Minuten ruhen. Falls die Masse nach der Ruhezeit noch zu trocken sein sollte, kannst du etwas Wasser hinzugeben. Forme nun etwa 8 gleich große Klöse aus der Masse. ",
				stepImage: "./R0019/3.webp"
			},
			{
				stepName: "Entenbrust braten und backen",
				stepDescription:
					"In der letzten halben Stunde der Backzeit des Rotkohls, darfst einen mittelgroßen Topf mit leicht gesalzenem Wasser für die Semmelknödel aufsetzen. Entferne nun den Fettrand der Entenbrust. Schneide die Haut kreuzförmig ein (ohne das darunter liegende Fleisch tief einzuschneiden) und mariniere beidseitig mit Salz und Pfeffer. Erhitze in einer mittelgroßen Pfanne 2 EL Pflanzenöl und brate das Fleisch pro Seite für etwa 3 Minuten scharf an. Gib die Entenbrust nach dem Anbraten für weitere 15 Minuten in den Ofen. Bevor das Fleisch angeschnitten wird, lass es kurz ruhen. Solltest du Knoblauch mögen, kannst du 2 Zehen schälen und in einer kleinen Schale mit etwas Wasser auspressen. Mit diesem Knoblauchwasser kann die Oberseite der Entenbrust bestrichen werden, solange sie im Ofen ist.",
				stepImage: "./R0019/4.webp"
			},
			{
				stepName: "Semmelknödel kochen und Orangen-Soße zubereiten",
				stepDescription:
					"Während die Entenbrust und der Rotkohl noch im Ofen ist, müsste nun das Wasser in dem Topf kochen. Gib die Semmelknödel für 15-20 Minuten in das siedende Wasser. In der Zwischenzeit kannst du die Soße zubereiten. Halbiere die Orangen und presse sie aus, sodass etwa 150ml Saft aufgefangen wird. Rühre 300 ml Geflügelbrühe an. Lösche nun das Bratfett der Entenbrust in der Pfanne mit dem Orangenlikör, dem gepressten Orangensaft, der Geflügelbrühe ab. Vermenge die Orangenmarmelade. Gieße die Soße nun über einem Sieb ab und rühre 30g eiskalte Butter hinein. ",
				stepImage: "./R0019/5.webp"
			},
			{
				stepName: "Anrichten und Servieren",
				stepDescription:
					"Hacke die Hälfte des Thymians klein. Schneide die Entenbrust in Streifen an und serviere sie zusammen mit dem Rotkohl und den Semmelknödeln. Tröpfel die Soße fein über das Gericht und streue eine Prise von dem fein gehackten Thymian darüber.  Garniere zusätzlich nach Belieben mit weiterem Thymian.",
				stepImage: "./R0019/6.webp"
			}
		]
	},
	{
		id: "R0020",
		geflügel: false,
		rind: false,
		schwein: false,
		fisch: false,
		meeresfrüchte: false,
		milch: false,
		ei: false,
		vegetarisch: true,
		vegan: false,
		lowFat: false,
		LowCarb: false,
		italienisch: true,
		spanisch: true,
		indisch: true,
		japanisch: true,
		chinesisch: true,
		arabisch: true,
		senf: true,
		schwefeldioxid: false,
		sulphite: false,
		nüsse: true,
		gluten: false,
		sellerie: false,
		sesamsamen: false,
		sojabohnen: false,
		laktose: false,
		eierzeugnisse: false,
		sellerieerzeugnisse: false,
		hülsenfrüchte: false,
		titleImage: "./R0020/0.webp",
		name: "Orangen-Fenchel-Salat mit Honig-Senf-Dressing",
		level: 1,
		punkte: 3,
		kochzeit: 15,
		supermarktpreis: 3.25,
		kalorien: 677,
		schärfegrad: 0,
		gerichtbeschreibung:
			"Lorem ipsum dolor sit amet, consectetur adipiscing elit. At gravida faucibus consequat nec, dictum quis enim lacus. Dignissim maecenas velit faucibus diam vitae elementum. Sed risus dui aliquam varius dignissim pharetra, hendrerit. Ullamcorper amet enim ipsum faucibus nibh.",
		fancies: [ "Pinienkerne", "Walnussöl", "Obstessig", "Dijon-Senf", "Akazienhonig" ],
		einkaufsliste: [ "Zwiebel (rot)", "Fenchel", "Orange", "Petersilie" ],
		zutatenZuhause: [ "Salz", "Pfeffer" ],
		kcl: 677,
		fett: 30.5,
		kohlenhydrate: 58.1,
		eiweiß: 50.5,
		utensilien: [],
		allergene: [ "Senf", "Nüsse", "kann Spuren von anderen Allergenen enthalten" ],
		steps: [
			{
				stepName: "Fenchel schneiden",
				stepDescription:
					"Wasche den Fenchel, schneide den Strunk heraus, halbiere die Fenchelknollen und schneide sie in feine Streifen oder kleine Würfel. Schäle die Zwiebel und schneide sie ebenfalls in dünne Streifen oder kleine Würfel.",
				stepImage: "./R0020/1.webp"
			},
			{
				stepName: "Orangen filetieren",
				stepDescription:
					"Wasche die Orange und tupfe sie kurz trocken. Entferne die Schale bzw. filetiere die Orange, sodass sie frei von weißer Haut ist. Schneide die Orangenspalten zwischen den Trennhäuten heraus. Fange möglichen Saft dabei auf.",
				stepImage: "./R0020/2.webp"
			},
			{
				stepName: "Restliches Fruchtfleisch ausdrücken",
				stepDescription:
					"Oft bleibt beim Herausschneiden der Fielts zwischen den Trennhäuten etwas Fruchtfleisch übrig. Presse alles von der Orange, was nicht zu den Filets gehört aus und fange den Saft auf.",
				stepImage: "./R0020/3.webp"
			},
			{
				stepName: "Pinienkerne rösten",
				stepDescription:
					"Brate in einer kleinen Pfanne ohne Zugabe von Fetten oder Ölen die Pinienkerne bei mittlerer Hitzestufe für 2-3 Minuten goldgelb an.",
				stepImage: "./R0020/4.webp"
			},
			{
				stepName: "Honig-Senf-Dressing anrühren",
				stepDescription:
					"Wasche die Petersilie und tupfe sie trocken. Hacke sie fein. Vermische die geschnittene Petersilie nun mit dem Essig, dem aufgefangenen Orangensaft, dem Senf und Honig. Rühre auch dasd Öl darunter gib eine Prise Salz und Pfeffer dazu. ",
				stepImage: "./R0020/5.webp"
			},
			{
				stepName: "Salat fertigstellen und Servieren",
				stepDescription:
					"Vermenge den Fenchel, die Zwiebel, die Orangenfilets und die Pinienkerne mit dem Dressing und würze nach Gustus mit Salz und Pfeffer nach.",
				stepImage: "./R0020/6.webp"
			}
		]
	},
	{
		id: "R0021",
		geflügel: false,
		rind: false,
		schwein: false,
		fisch: true,
		meeresfrüchte: false,
		milch: false,
		ei: false,
		vegetarisch: false,
		vegan: false,
		lowFat: false,
		LowCarb: true,
		italienisch: true,
		spanisch: true,
		indisch: true,
		japanisch: true,
		chinesisch: true,
		arabisch: true,
		senf: false,
		schwefeldioxid: false,
		sulphite: true,
		nüsse: false,
		gluten: false,
		sellerie: false,
		sesamsamen: false,
		sojabohnen: false,
		laktose: true,
		eierzeugnisse: false,
		sellerieerzeugnisse: false,
		hülsenfrüchte: false,
		titleImage: "./R0021/0.webp",
		name: "Lachsfilet mit gefüllten Auberginen und Zitronenbutter",
		level: 2,
		punkte: 3,
		kochzeit: 30,
		supermarktpreis: 5.23,
		kalorien: 893,
		schärfegrad: 0,
		gerichtbeschreibung:
			"Lorem ipsum dolor sit amet, consectetur adipiscing elit. At gravida faucibus consequat nec, dictum quis enim lacus. Dignissim maecenas velit faucibus diam vitae elementum. Sed risus dui aliquam varius dignissim pharetra, hendrerit. Ullamcorper amet enim ipsum faucibus nibh.",
		fancies: [ "Muskatgewürzmischung", "Pape's Fischgewürz" ],
		einkaufsliste: [ "Knoblauchzehe", "Aubergine", "Zitrone", "Majoran", "Mozzarella", "Butter", "Lachsfilet" ],
		zutatenZuhause: [ "Salz", "Pfeffer", "Pflanzenöl", "Mehl" ],
		utensilien: [ "Pfanne(mittelgroß)", "Auflaufform", "Küchenreibe", "Knoblauchpresse", "Backofen", "Gitterrost" ],
		allergene: [ "Milch/-erzeugnisse, Laktose", "kann Spuren von anderen Allergenen enthalten" ],
		steps: [
			{
				stepName: "Backofen vorheizen",
				stepDescription: "Heize den Backofen auf  180°C Ober-/Unterhitze vor.",
				stepImage: "./R0021/1.webp"
			},
			{
				stepName: "Zutaten vorbereiten",
				stepDescription:
					"Wasche die Zitrone unter warmen Wasser ab. Reibe die Schale ab und fange sie in einer kleinen Schüssel auf. Halbiere anschließend die Zitrone und presse den Saft einer Hälfte  aus. Halte den Abrieb von der Zitronenschale und den ausgepressten Saft noch voneinander getrennt. Wasche den frischen Majoran und Salbei und tupfe die Kräuter trocken. Entferne die Stiele und hacke nur die Blätter fein. Schäle den Knoblauch und presse die Zehe. Halbiere die Aubergine längs und höhle sie aus. Schneide den Mozzarella nach dem Abtropfen in kleine Würfel. ",
				stepImage: "./R0021/2.webp"
			},
			{
				stepName: "Zitronenbutter anrühren",
				stepDescription:
					"Vermische etwa 100g weiche Butter mit dem Knoblauch, der abgeriebenen Zitronenschale und 1-2 TL von dem Zitronensaft. Vermenge auch die gehackten Kräuter darunter und würze mit dem Muskatgewürzpulver, Salz und Pfeffer nach. Wickel nun Dreiviertel von der Buttermischung in Frischhaltefolie und forme daraus eine Rolle. Stelle jene kühl. ",
				stepImage: "./R0021/3.webp"
			},
			{
				stepName: "Auberginen überbacken",
				stepDescription:
					"Verteile die restliche Zitronenbutter über die Auberginenhälften. Platziere die Auberginenhälften danach in einer Auflaufform und fülle die Mulden mit den bereits geschnittenen Mozzarella-Würfel. Lasse die gefüllten Auberginen nun für mindestens 30 Minuten backen. ",
				stepImage: "./R0021/4.webp"
			},
			{
				stepName: "Lachsfilet braten",
				stepDescription:
					"In der Zwischenzeit kannst du den Lachs auf der Hautseite mehlieren und anschließend mit Pape's Fischgewürz würzen. Lasse 1-2 EL Öl in der Pfanne erhitzen und brate den Lachs auf der Hautseite scharf an, drehe ihn danach um. Gib etwa 20g Butter hinzu, sobald der Fisch gewendet ist und brate ihn bei reduzierter Hitze fertig. ",
				stepImage: "./R0021/5.webp"
			},
			{
				stepName: "Anrichten und mit Zitronenbutter belegen",
				stepDescription:
					"Richte das Lachsfilet und die Auberginenhälften auf einem Teller an und belege nach Belieben mit der Zitronenbutter. ",
				stepImage: "./R0021/6.webp"
			}
		]
	},
	{
		id: "R0022",
		geflügel: true,
		rind: false,
		schwein: false,
		fisch: false,
		meeresfrüchte: false,
		milch: false,
		ei: false,
		vegetarisch: false,
		vegan: false,
		lowFat: false,
		LowCarb: false,
		italienisch: true,
		spanisch: true,
		indisch: true,
		japanisch: true,
		chinesisch: true,
		arabisch: true,
		senf: false,
		schwefeldioxid: false,
		sulphite: false,
		nüsse: false,
		gluten: false,
		sellerie: false,
		sesamsamen: false,
		sojabohnen: false,
		laktose: true,
		eierzeugnisse: false,
		sellerieerzeugnisse: false,
		hülsenfrüchte: false,
		titleImage: "./R0022/0.webp",
		name: "Puten-Kebab-Spieße mit Tzatziki und Ofengemüse",
		level: 1,
		punkte: 3,
		kochzeit: 30,
		supermarktpreis: 4.21,
		kalorien: 683,
		schärfegrad: 1,
		gerichtbeschreibung:
			"Lorem ipsum dolor sit amet, consectetur adipiscing elit. At gravida faucibus consequat nec, dictum quis enim lacus. Dignissim maecenas velit faucibus diam vitae elementum. Sed risus dui aliquam varius dignissim pharetra, hendrerit. Ullamcorper amet enim ipsum faucibus nibh.",
		fancies: [ "Al Amier Kebab-Gewürz", "Cayenne-Pfeffer" ],
		einkaufsliste: [
			"Zwiebel(rot)",
			"Knoblauchzehe",
			"Paprika(rot)",
			"Paprika(gelb)",
			"Salatgurke",
			"Aubergine",
			"Zucchini",
			"Limette",
			"Naturjoghurt",
			"Putenschnitzel"
		],
		zutatenZuhause: [ "Salz", "Pfeffer", "Olivenöl", "Pflanzenöl" ],
		kcl: 683,
		fett: 28.4,
		kohlenhydrate: 65.3,
		eiweiß: 44,
		utensilien: [
			"Pfanne(mittelgroß)",
			"Auflaufform",
			"Küchenreibe",
			"Zitronenpresse",
			"Knoblauchpresse",
			"Sparschäler",
			"Backofen",
			"Gitterrost"
		],
		allergene: [ "Milch/-erzeugnisse, Laktose", "kann Spuren von anderen Allergenen enthalten" ],
		steps: [
			{
				stepName: "Backofen vorheizen",
				stepDescription: "Heize den Backofen auf  180°C Umluft vor.",
				stepImage: "./R0022/1.webp"
			},
			{
				stepName: "Gemüse marinieren",
				stepDescription:
					"Schäle die Zwiebel und den Knoblauch und schneide sie in feine Würfel. Entkerne die Paprika und schneide sie mit der Zucchini und der Aubergine in gleich große Stücke. Du verkürzt später die Backzeit, umso feiner du  würfelst. Mariniere das Gemüse nun in etwa 10 ml Olivenöl, Salz und Pfeffer. ",
				stepImage: "./R0022/2.webp"
			},
			{
				stepName: "Ofengemüse backen",
				stepDescription:
					"Gib das marinierte Gemüse in eine Auflaufform. Je nach Größe und Dicke sollte das Ofengemüse nun für 30 Minuten backen. ",
				stepImage: "./R0022/3.webp"
			},
			{
				stepName: "Tzatziki zubereiten",
				stepDescription:
					"In der Zwischenzeit kannst du die halbe Salatgurke schälen und fein würfeln. Schäle den Knoblauch und presse dies Zehe aus. Wasche die Limette mit warmen Wasser ab und reibe die Schale ab. Presse den Limettensaft anschließend aus. Mische nun den Naturjoghurt mit der Salatgurke, der ausgepressten Knoblauchzehe, dem Limetten-Abrieb und 1-2 TL von dem Limettensaft zusammen. Gib den Cayenne-Pfeffer hinzu und würze mit Belieben mit Pfeffer und Salz nach. ",
				stepImage: "./R0022/4.webp"
			},
			{
				stepName: "Putenspieße braten",
				stepDescription:
					"Schneide das Putenschnitzel in Würfel und spieße sie auf einem langen Holzspieß auf. Brate die Spieße auf beiden Seiten für ca. 8 Minuten an. Würze dabei mit dem Al Amir Kebabgewürz. ",
				stepImage: "./R0022/5.webp"
			},
			{
				stepName: "Servieren",
				stepDescription: "Richte die Kebabspieße nun mit dem Tzatziki und dem Ofengemüse an. ",
				stepImage: "./R0022/6.webp"
			}
		]
	},
	{
		id: "R0023",
		geflügel: false,
		rind: false,
		schwein: true,
		fisch: false,
		meeresfrüchte: true,
		milch: false,
		ei: false,
		vegetarisch: false,
		vegan: false,
		lowFat: false,
		LowCarb: false,
		italienisch: true,
		spanisch: true,
		indisch: true,
		japanisch: true,
		chinesisch: true,
		arabisch: true,
		senf: false,
		schwefeldioxid: false,
		sulphite: false,
		nüsse: false,
		gluten: false,
		sellerie: false,
		sesamsamen: false,
		sojabohnen: false,
		laktose: false,
		eierzeugnisse: false,
		sellerieerzeugnisse: false,
		hülsenfrüchte: true,
		titleImage: "./R0023/0.webp",
		name: "Simple Paella mit Garnelen und Chorizo",
		level: 2,
		punkte: 3,
		supermarktpreis: 3.43,
		kalorien: 582,
		schärfegrad: 3,
		gerichtbeschreibung:
			"Lorem ipsum dolor sit amet, consectetur adipiscing elit. At gravida faucibus consequat nec, dictum quis enim lacus. Dignissim maecenas velit faucibus diam vitae elementum. Sed risus dui aliquam varius dignissim pharetra, hendrerit. Ullamcorper amet enim ipsum faucibus nibh.",
		fancies: [ "Safranfäden", "Pimentón de la Vera" ],
		einkaufsliste: [
			"Fleischtomate",
			"Zwiebel(weiß)",
			"Knoblauchzehe",
			"Paprika(rot)",
			"Erbsen(frisch/tiefgekühlt)",
			"Brechbohnen",
			"Paellareis(ggf. Risottoreis)",
			"Garnelen(Tiefgekühlt, entdarmt)",
			"Chorizo-Wurst"
		],
		zutatenZuhause: [ "Salz", "Pfeffer", "Olivenöl" ],
		kcl: 582,
		fett: 25.6,
		kohlenhydrate: 55.8,
		eiweiß: 29.9,
		utensilien: [ "Pfanne(Ofenfest, groß)", "Backofen", "Gitterrost" ],
		allergene: [ "Hülsenfrüchte", "kann Spuren von anderen Allergenen enthalten" ],
		steps: [
			{
				stepName: "Garnelen auftauen",
				stepDescription:
					"Lasse die Garnelen in kaltem Wasser auftauen. Da das Auftauen der Garnelen mit kaltem Wasser etwa 20 Minuten dauert, solltest du dies vor Kochbeginn vorbereiten.",
				stepImage: "./R0023/1.webp"
			},
			{
				stepName: "Gemüse schneiden und Ofen vorheizen",
				stepDescription:
					"Schäle Zwiebel und Knoblauch und schneide sie in feine Würfel. Entkerne die Paprika und schneide sie in gleich große Stücke wie die große Fleischtomate. Die Chorizowurst kann in Daumendicke Würfel oder Scheiben zerkleinert werden. Wiege die Erbsen und Brechbohnen ab und stelle alle Zutaten beiseite. Heize den Backofen auf 200℃ Ober-/Unterhitze vor.",
				stepImage: "./R0023/2.webp"
			},
			{
				stepName: "Chorizo anbraten",
				stepDescription:
					"Erhitze in einer großen Ofenfesten Pfanne 1-2 EL Olivenöl und lass brate die Chorizo-Wurst für 2 Minuten an. Gib Zwiebel und Knoblauch hinzu und dünste sie mit an. ",
				stepImage: "./R0023/3.webp"
			},
			{
				stepName: "Zutaten hinzugeben und ablöschen",
				stepDescription:
					"Gib die Paprikawürfel, den Safran, Pimento de la Vera und Pfeffer dazu und lasse alles für eine weitere Minute mit anbraten. Füge nun die Tomatenwürfel, den Paellareis, die Brechbohnen und Erbsen hinzu. Lösche entweder mit 850 ml Wasser oder nach Belieben mit Gemüsebrühe ab. Lasse alles erneut aufkochen. Schmecke ab und würze ggf. mit Salz unf Pfeffer nach. ",
				stepImage: "./R0023/4.webp"
			},
			{
				stepName: "Paella im Ofen backen",
				stepDescription:
					"Lasse die Paella für 25 Minuten im Ofen backen. Während des Backens darf die Paella nicht umgerührt werden. Es ist nicht falsch, wenn der Boden eine Kruste bildet. ",
				stepImage: "./R0023/5.webp"
			},
			{
				stepName: "Garnelen hinzugeben",
				stepDescription:
					"Gib die Garnelen etwa 10 Minuten vor Ablauf der Backzeit hinzu. Serviere wenn der Paellareis gar ist.",
				stepImage: "./R0023/6.webp"
			}
		]
	},
	{
		id: "R0024",
		geflügel: false,
		rind: false,
		schwein: false,
		fisch: false,
		meeresfrüchte: false,
		milch: false,
		ei: false,
		vegetarisch: true,
		vegan: false,
		lowFat: false,
		LowCarb: false,
		italienisch: true,
		spanisch: true,
		indisch: true,
		japanisch: true,
		chinesisch: true,
		arabisch: true,
		senf: false,
		schwefeldioxid: false,
		sulphite: false,
		nüsse: false,
		gluten: true,
		sellerie: false,
		sesamsamen: false,
		sojabohnen: false,
		laktose: true,
		eierzeugnisse: true,
		sellerieerzeugnisse: false,
		hülsenfrüchte: true,
		titleImage: "./R0024/0.webp",
		name: "Zucchini-Tarte",
		level: 2,
		punkte: 3,
		kochzeit: 40,
		supermarktpreis: 2.31,
		kalorien: 677,
		schärfegrad: 0,
		gerichtbeschreibung:
			"Lorem ipsum dolor sit amet, consectetur adipiscing elit. At gravida faucibus consequat nec, dictum quis enim lacus. Dignissim maecenas velit faucibus diam vitae elementum. Sed risus dui aliquam varius dignissim pharetra, hendrerit. Ullamcorper amet enim ipsum faucibus nibh.",
		fancies: [ "Muskatgewürzmischung", "Zitronenpfeffer" ],
		einkaufsliste: [
			"Zucchini",
			"Oliven(entsteint)",
			"Minze",
			"Käse(gerieben)",
			"Fetakäse",
			"Schlagsahne",
			"Schmand",
			"Eier",
			"Filoteigblätter(ggf. Blätterteig)"
		],
		zutatenZuhause: [ "Salz", "Pfeffer", "Butter" ],
		kcl: 677,
		fett: 30.5,
		kohlenhydrate: 58.1,
		eiweiß: 50.5,
		utensilien: [ "Küchenreibe", "Backofen", "Backblech", "Tarteform(ggf. Auflaufform)" ],
		allergene: [
			"Gluten",
			"Milch/-erzeugnisse, Laktose",
			"Eier/Eierzeugnisse",
			"kann Spuren von anderen Allergenen enthalten"
		],
		steps: [
			{
				stepName: "Zutaten vorbereiten",
				stepDescription:
					"Die Teigblätter können bei Raumtemepratur 20 Minuten ruhen. Wasche die Zucchini und reibe oder schneide sie anschließend fein. Mariniere sie mit 0,5 TL Salz und lass sie ebenfalls 10-15 Minuten ruhen. Heize den Ofen auf 180° C Ober/Unterhitze vor. Wasche die Minze, und hacke sie fein. Zerbrösel den Feta in kleine Stücke. ",
				stepImage: "./R0024/1.webp"
			},
			{
				stepName: "Füllung anrühren",
				stepDescription:
					"Drücke die Zucchini aus und fange den Saft auf. Verquirle jenen nun in einer Schüssel mit den Eiern, Schmand, Sahne und dem geriebenen Käse. Rühre nun die Minze, Zucchini und die Fetastückchen unter. Würze mit der Muskatgewürzmischung und dem Zitronenpfeffer und schmecke nach Belieben mit Salz und Pfeffer ab. ",
				stepImage: "./R0024/2.webp"
			},
			{
				stepName: "Tarteform einfetten",
				stepDescription:
					"Schmelze die Butter in der Mikrowelle oder einem kleinen Topf. Fette zusätzlich die Tarteform ein und kleide sie mit den Filoteig aus. Dabei wird jedes Blatt mit flüssiger Butter bestrichen. ",
				stepImage: "./R0024/3.webp"
			},
			{
				stepName: "Zucchini-Masse einfüllen",
				stepDescription:
					"Fülle nun die Zucchini-Masse ein, verteile sie gleichmäßig in der Form und schieb die Tarte nun in den vorgeheizten Ofen. ",
				stepImage: "./R0024/4.webp"
			},
			{
				stepName: "Oliven belegen",
				stepDescription:
					"Die Tarte benötigt etwa 30 Minuten Backzeit, damit die Masse fest und die Oberfläche goldgelb wird. Belege etwa 10 Minuten vor Backende mit den Oliven. ",
				stepImage: "./R0024/5.webp"
			},
			{
				stepName: "Tarte stocken lassen",
				stepDescription:
					"Nachdem du die Tarte aus dem Ofen geholt hast, sollte sie nach dem Backen für weitere 10 Minuten abkühlen. Lasse sie für die wenigen Minuten stockeb, bevor du sie anschniedest. Zur Verzierung kann auf dem Teller noch etwas frisch gehackte Minze darüber gestreut werden.",
				stepImage: "./R0024/6.webp"
			}
		]
	},
	{
		id: "R0025",
		geflügel: false,
		rind: false,
		schwein: true,
		fisch: false,
		meeresfrüchte: false,
		milch: false,
		ei: false,
		vegetarisch: false,
		vegan: false,
		lowFat: false,
		LowCarb: false,
		italienisch: true,
		spanisch: true,
		indisch: true,
		japanisch: true,
		chinesisch: true,
		arabisch: true,
		senf: false,
		schwefeldioxid: false,
		sulphite: false,
		nüsse: true,
		gluten: true,
		sellerie: false,
		sesamsamen: false,
		sojabohnen: false,
		laktose: true,
		eierzeugnisse: true,
		sellerieerzeugnisse: false,
		hülsenfrüchte: true,
		titleImage: "./R0025/0.webp",
		name: "Schweineröllchen mit Pistazienfüllung",
		level: 2,
		punkte: 3,
		kochzeit: 50,
		supermarktpreis: 3.56,
		kalorien: 893,
		schärfegrad: 0,
		gerichtbeschreibung:
			"Lorem ipsum dolor sit amet, consectetur adipiscing elit. At gravida faucibus consequat nec, dictum quis enim lacus. Dignissim maecenas velit faucibus diam vitae elementum. Sed risus dui aliquam varius dignissim pharetra, hendrerit. Ullamcorper amet enim ipsum faucibus nibh.",
		fancies: [ "Parmesan(gerieben)", "Pistazienkerne", "Gemüsebrühgewürz", "Speisestärke" ],
		einkaufsliste: [ "Zitrone", "Basilikum", "Ricotta", "Schlagsahne", "Nudeln", "Schweineschnitzel" ],
		zutatenZuhause: [ "Salz", "Pfeffer", "Pflanzenöl", "Butter" ],
		kcl: 893,
		fett: 27.9,
		kohlenhydrate: 107.3,
		eiweiß: 49.4,
		utensilien: [
			"Pfanne(mittelgroß)",
			"Kochtopf (mittelgroß)",
			"Küchenreibe",
			"Zitronenpresse",
			"Holz-/Rouladen-Spieße",
			"Fleischklopfer"
		],
		allergene: [
			"Nüsse",
			"Gluten",
			"Milch/-erzeugnisse, Laktose",
			"Eier/Eierzeugnisse",
			"kann Spuren von anderen Allergenen enthalten"
		],
		steps: [
			{
				stepName: "Zutaten vorbereiten",
				stepDescription:
					"Hacke die Pistazien klein und stelle davon 2 EL seperat beiseite. Wasche den Basilikum, tupfe ihn trocken und schneide ihn in feine Streifen. Wasche die Zitrone in heißem Wasser ab, reibe die Schale ab und halbiere sie anschließend. Presse eine Hälfte der Zitrone aus.",
				stepImage: "./R0025/1.webp"
			},
			{
				stepName: "Pistazienfüllung anrühren",
				stepDescription:
					"Vermische den Ricotta, Parmesan, die Zitronenschale, die gehackten Pistazien und den Basilikum miteinander. Würze mit einer Prise Salz und Pfeffer nach und schmecke die Füllung ab. ",
				stepImage: "./R0025/2.webp"
			},
			{
				stepName: "Schweineschnitzel füllen",
				stepDescription:
					"Falls nicht schon vorgeschnitten, schneide aus dem Schweinefleisch zwei Schnitzel. Klopfe das Schweinefleisch auf ca. 1cm dicke Platten. Würze diese beidseitig mit Salz und Pfeffer. Bestreiche nun eine Seite mit der Pistazien-Ricotta-Masse und rolle sie anschließend ein. Fixiere jedes Schweineröllchen mit 1-2 Holzspießen. ",
				stepImage: "./R0025/3.webp"
			},
			{
				stepName: "Schweineröllchen schmoren",
				stepDescription:
					"Erhitze in einer Pfanne 2 EL Pflanzenöl und brate die Röllchen von allen Seiten scharf an. Lösche anschließend mit 175-200 ml Gemüsebouillon und 2 EL Zitronensaft ab. Lass die Pfanne nun noch einmal aufkochen, bevor du die Hitze um die Hälfte reduzierst. Lasse alles für ca. 30 Minuten abgedeckt schmoren. Gib nach Ablauf der 30 Minuten Butter und Sahne hinzu und lass die Schweineröllchen für weitere 20 Minuten schmoren.",
				stepImage: "./R0025/4.webp"
			},
			{
				stepName: "Pasta kochen",
				stepDescription:
					"Während des Schmorens kannst du gesalzenes Kochwasser aufstellen. Passe die Kochzeit der Pasta mit der Schmorzeit der Schweineröllchen ab. Wenn die Nudeln fertig gekocht sind, nimm die Schweineröllchen aus dem Schmorsud und gib die abgetropfte Pasta in den Sud hinein. Füge den Parmesan hinzu. Gegebenenfalls kannst du die Speisestärke mit etwas Nudelwasser abbinden. ",
				stepImage: "./R0025/5.webp"
			},
			{
				stepName: "Abschmecken und Servieren",
				stepDescription:
					"Schmecke Pasta und die Soße ab und serviere sie zusammen mit den Schweineröllchen. Streue etwas von den gehackten Pistazien darüber.",
				stepImage: "./R0025/6.webp"
			}
		]
	},
	{
		id: "R0026",
		geflügel: false,
		rind: false,
		schwein: false,
		fisch: false,
		meeresfrüchte: false,
		milch: false,
		ei: false,
		vegetarisch: true,
		vegan: false,
		lowFat: false,
		LowCarb: false,
		italienisch: true,
		spanisch: true,
		indisch: true,
		japanisch: true,
		chinesisch: true,
		arabisch: true,
		senf: false,
		schwefeldioxid: false,
		sulphite: false,
		nüsse: true,
		gluten: true,
		sellerie: false,
		sesamsamen: false,
		sojabohnen: false,
		laktose: false,
		eierzeugnisse: false,
		sellerieerzeugnisse: false,
		hülsenfrüchte: true,
		titleImage: "./R0026/0.webp",
		name: "Couscous-Salat",
		level: 1,
		punkte: 3,
		kochzeit: 15,
		supermarktpreis: 2.15,
		kalorien: 683,
		schärfegrad: 0,
		gerichtbeschreibung:
			"Lorem ipsum dolor sit amet, consectetur adipiscing elit. At gravida faucibus consequat nec, dictum quis enim lacus. Dignissim maecenas velit faucibus diam vitae elementum. Sed risus dui aliquam varius dignissim pharetra, hendrerit. Ullamcorper amet enim ipsum faucibus nibh.",
		fancies: [ "Cayenne-Pfeffer", "Zitronenpfeffer", "Pinienkerne", "Kräuteröl", "Gemüsebrühgewürz" ],
		einkaufsliste: [ "Zwiebel(weiß)", "Knoblauchzehe", "Karotte", "Zitrone", "Avocado", "Petersilie", "Couscous" ],
		zutatenZuhause: [ "Salz", "Pfeffer", "Zucker", "Pflanzenöl" ],
		kcl: 683,
		fett: 28.4,
		kohlenhydrate: 65.3,
		eiweiß: 44,
		utensilien: [
			"Pfanne(klein)",
			"Pfanne(mittelgroß)",
			"Kochtopf(mittelgroß)",
			"Küchenreibe",
			"Zitronenpresse",
			"Schüssel(groß)"
		],
		allergene: [ "Nüsse", "Gluten", "Hülsenfrüchte", "kann Spuren von anderen Allergenen enthalten" ],
		steps: [
			{
				stepName: "Couscous zubereiten",
				stepDescription:
					"Löse als erstes etwa 200ml Gemüsebouillon an. Bringe diese mit 2 EL ÖL und einer Prise Salz zum Kochen. Gib in das kochende Wasser den Couscous hinzu und nimm den Topf anschließend vom Herd. Lasse ihn circa 5 Minuten abgedeckt ziehen.",
				stepImage: "./R0026/1.webp"
			},
			{
				stepName: "Gemüse schneiden und braten",
				stepDescription:
					"Schäle die Zwiebel und die Karotte und schneide sie jeweils in 4x4mm dicke Würfel. Erhitze in einer Pfanne 1 EL Öl und brate die Karottenwürfel für 2-3 Minuten an. Gib die Zwiebelwürfel dazu und lass sie kurz mitdünsten. Gib 2 EL Zucker und etwas Salz hinzu, sodass der Zucker leicht karamellisiert.",
				stepImage: "./R0026/2.webp"
			},
			{
				stepName: "Pinienkerne rösten",
				stepDescription:
					"Lasse in einer kleinen Pfanne ohne Zusatz von Ölen oder Butter die Pinienkerne leicht rösten, bis sie goldbraun sind. ",
				stepImage: "./R0026/3.webp"
			},
			{
				stepName: "Salatzutaten schneiden",
				stepDescription:
					"Hacke die Petersilie fein. Halbiere und entkerne die Avocado und würfel das Fruchtfleisch. Wasche die Zitrone heiß ab. Reibe die Schale ab, halbiere die Zitrone und presse den Saft einer halben Zitrone aus. ",
				stepImage: "./R0026/4.webp"
			},
			{
				stepName: "Salat vermischen",
				stepDescription:
					"Vermische in einer großen Schüssel den Couscous, das Gemüse, die Pinienkerne und die Avocado.",
				stepImage: "./R0026/5.webp"
			},
			{
				stepName: "Salat garnieren und servieren",
				stepDescription:
					"Beträufel den Salat mit dem Zitronensaft und -schale, dem Kräuteröl, den Gewürzen und der Petersilie. Nach Belieben kann auch Knoblauch hinzugegeben werden. Am besten schmeckt der Salat gekühlt, nachdem er durchgezogen ist.",
				stepImage: "./R0026/6.webp"
			}
		]
	},
	{
		id: "R0027",
		geflügel: false,
		rind: false,
		schwein: false,
		fisch: false,
		meeresfrüchte: false,
		milch: false,
		ei: false,
		vegetarisch: true,
		vegan: false,
		lowFat: false,
		LowCarb: false,
		italienisch: true,
		spanisch: true,
		indisch: true,
		japanisch: true,
		chinesisch: true,
		arabisch: true,
		senf: false,
		schwefeldioxid: true,
		sulphite: false,
		nüsse: false,
		gluten: true,
		sellerie: false,
		sesamsamen: false,
		sojabohnen: false,
		laktose: true,
		eierzeugnisse: true,
		sellerieerzeugnisse: false,
		hülsenfrüchte: false,
		titleImage: "./R0027/0.webp",
		name: "Bombardoni mit Pilzfüllung und Spinat",
		level: 2,
		punkte: 3,
		kochzeit: 20,
		supermarktpreis: 4.73,
		kalorien: 582,
		schärfegrad: 0,
		gerichtbeschreibung:
			"Lorem ipsum dolor sit amet, consectetur adipiscing elit. At gravida faucibus consequat nec, dictum quis enim lacus. Dignissim maecenas velit faucibus diam vitae elementum. Sed risus dui aliquam varius dignissim pharetra, hendrerit. Ullamcorper amet enim ipsum faucibus nibh.",
		fancies: [
			"Muskatgewürzmischung",
			"Steinpilzpulver",
			"Parmesan (gerieben)",
			"Gemüsebrühgewürz",
			"Getrocknete Steinpilze"
		],
		einkaufsliste: [
			"Kirschtomaten",
			"Zwiebel(rot)",
			"Spinat (frisch)",
			"Champignons",
			"Knoblauchzehe",
			"Creme fraiche",
			"Eier",
			"Butter",
			"Milch",
			"Bombardoni (z.B. Pastifico dei Campi)",
			"Räucherspeck(optional)"
		],
		zutatenZuhause: [ "Salz", "Pfeffer", "Pflanzenöl", "Mehl" ],
		kcl: 582,
		fett: 25.6,
		kohlenhydrate: 55.8,
		eiweiß: 29.9,
		utensilien: [
			"Pfanne(klein)",
			"Pfanne(mittelgroß)",
			"Kochtopf(mittelgroß)",
			"Auflaufform",
			"Knoblauchpresse",
			"Backofen",
			"Gitterrost"
		],
		allergene: [
			"Schwefeldioxid",
			"Gluten",
			"Milch/-erzeugnisse, Laktose",
			"",
			"Eier/Eierzeugnisse",
			"kann Spuren von anderen Allergenen enthalten"
		],
		steps: [
			{
				stepName: "Knoblauch pressen und würfeln",
				stepDescription:
					"Schäle die Knoblauchzehe und presse die Hälfte davon aus. Vermische diese halbe Knoblauchzehe mit 1 EL Öl und stelle es beiseite. Würfel die zweite Knoblauchhälfte. Je nach Gustus kann das Rezept um eine Knoblauchzehe erweitert werden, damit jeweils eine gepresst und gewürfelt wird. Bereite schon gesalzenes Wasser in einem Kochtopf vor und lass es auf dem Herd erhitzen. ",
				stepImage: "./R0027/1.webp"
			},
			{
				stepName: "Gemüse schneiden und anbraten ",
				stepDescription:
					"Schäle die Zwiebel und würfel sie klein. Schneide auch die Champignons, die getrockneten Steinpilze und den Räucherspeck (optional) möglichst klein. Setze die Gemüsebrühe an. Erhitze 1 EL Pflanzenöl in der Pfanne und schwitze die Zwiebel und den Speck (optional) gemeinsam mit dem gewürfelten Knoblauch an. Gib die getrockneten Steinpilze und Champignons dazu und lasse sie mitdünsten. Rühre das Steinpilzpulver und die Muskatgewürzmischung unter. Schmecke mit etwas Salz und Pfeffer ab. Lösche nun das Gemüse mit der Gemüsebrühe ab. ",
				stepImage: "./R0027/2.webp"
			},
			{
				stepName: "Bombardoni kochen, Füllung vorbereiten",
				stepDescription:
					"Während die Pilzpfanne langsam einkocht, können die Bombardoni für etwa 7 Minuten in dem Salzwasser kochen. Vermische in einem Rührgefäß die Creme Fraiche, den Parmesan und das Vollei gut durch. ",
				stepImage: "./R0027/3.webp"
			},
			{
				stepName: "Bombardoni füllen",
				stepDescription:
					"Heize den Backofen auf 180° Umluft vor. Gib die angerührte Creme nun in die Pilzpfanne. Mische alles gut durch. Sollte die Konsistenz noch zu flüssig sein, kannst du etwas Mehl zufügen, um die Masse einzudicken. Die Bombardoni werden nun mit der Masse gefüllt. ",
				stepImage: "./R0027/4.webp"
			},
			{
				stepName: "Bechamel-Soße übergießen und backen",
				stepDescription:
					"Schmelze die Butter in einer kleinen Pfanne oder Mikrowelle an. Schlage in einem Rührgefäß nun 2-3 EL Mehr unter die Butter und gib die Milch dazu. Platziere in einer Auflaufform die gefüllten Bombardoni und übergieße sie mit der Bechamel-Soße. Lasse alles bei 180° für etwa 10 Minuten backen. ",
				stepImage: "./R0027/5.webp"
			},
			{
				stepName: "Beilage anbraten und servieren",
				stepDescription:
					"Brate in der Zwischenzeit die Tomaten und den Spinat in den vorbereiteten Knoblauchöl an. Schmecke mit Salz und Pfeffer ab und serviere die Beilage zusammen mit den gefüllten Bombardoni. ",
				stepImage: "./R0027/6.webp"
			}
		]
	},
	{
		id: "R0028",
		geflügel: false,
		rind: true,
		schwein: true,
		fisch: false,
		meeresfrüchte: false,
		milch: false,
		ei: false,
		vegetarisch: false,
		vegan: false,
		lowFat: false,
		LowCarb: true,
		italienisch: true,
		spanisch: true,
		indisch: true,
		japanisch: true,
		chinesisch: true,
		arabisch: true,
		senf: false,
		schwefeldioxid: true,
		sulphite: false,
		nüsse: false,
		gluten: false,
		sellerie: false,
		sesamsamen: false,
		sojabohnen: false,
		laktose: true,
		eierzeugnisse: true,
		sellerieerzeugnisse: false,
		hülsenfrüchte: false,
		titleImage: "./R0028/0.webp",
		name: "Käse-Lauch-Suppe mit Trüffelöl",
		level: 1,
		punkte: 3,
		kochzeit: 25,
		supermarktpreis: 1.89,
		kalorien: 677,
		schärfegrad: 0,
		gerichtbeschreibung:
			"Lorem ipsum dolor sit amet, consectetur adipiscing elit. At gravida faucibus consequat nec, dictum quis enim lacus. Dignissim maecenas velit faucibus diam vitae elementum. Sed risus dui aliquam varius dignissim pharetra, hendrerit. Ullamcorper amet enim ipsum faucibus nibh.",
		fancies: [ "Muskatgewürzmischung", "Parmesan(gerieben)", "Trüffelöl", "Gemüsebrühgewürz" ],
		einkaufsliste: [
			"Zwiebel(weiß)",
			"Knoblauchzehe",
			"Porree",
			"Schmelzkäse",
			"Schlagsahne",
			"Hackfleisch(gemischt)"
		],
		zutatenZuhause: [ "Salz", "Pfeffer", "Pflanzenöl" ],
		kcl: 677,
		fett: 30.5,
		kohlenhydrate: 58.1,
		eiweiß: 50.5,
		utensilien: [ "Kochtopf(groß)" ],
		allergene: [
			"Schwefeldioxid",
			"Milch/-erzeugnisse, Laktose",
			"Eier/Eierzeugnisse",
			"kann Spuren von anderen Allergenen enthalten"
		],
		steps: [
			{
				stepName: "Gemüse schneiden",
				stepDescription:
					"Schäle die Knoblauchzehe und die Zwiebel und würfel sie anschließend ganz fein. Wasche den Porree und schneide ihn in feine Streifen.",
				stepImage: "./R0028/1.webp"
			},
			{
				stepName: "Knoblauch, Zwiebeln anschwitzen",
				stepDescription:
					"Erhitze in einem großen Topf 1 EL Öl. Lass den Knoblauch und die Zwiebeln darin anschwitzen.",
				stepImage: "./R0028/2.webp"
			},
			{
				stepName: "Hackfleisch dazugeben",
				stepDescription:
					"Gib das Hackfleisch dazu und lasse es für etwa 6 Minuten mitdünsten. Würze mit Salz und Pfeffer nach. Setze in der Zwischenzeit die Gemüsebrühe an.",
				stepImage: "./R0028/3.webp"
			},
			{
				stepName: "Brätmasse ablöschen",
				stepDescription:
					"Lösche das Hackfleisch nun mit der Gemüsebrühe ab, lasse es einmal kräftig aufkochen und anschließend für weitere 10 Minuten bei etwas reduzierter Hitze köcheln. ",
				stepImage: "./R0028/4.webp"
			},
			{
				stepName: "Schmelzkäse, Parmesan, Porree hinzugeben",
				stepDescription:
					"Gib den Schmelzkäse, den Parmesan und den Porree hinzu und lasse alles zusammen für weitere 5 Minuten köcheln. Würze mit der Muskatgewürzmischung und etwas Salz und Pfeffer nach. ",
				stepImage: "./R0028/5.webp"
			},
			{
				stepName: "Schlagsahne hinzugeben und servieren",
				stepDescription:
					"Gib am Ende der Kochzeit die Schlagsahne hinzu. Lass die Suppe noch einmal aufkochen und nimm sie dann vom Herd. Serviere in einem Suppenteller mit dem Trüffelöl darüber.",
				stepImage: "./R0028/6.webp"
			}
		]
	},
	{
		id: "R0029",
		geflügel: false,
		rind: false,
		schwein: false,
		fisch: false,
		meeresfrüchte: false,
		milch: false,
		ei: false,
		vegetarisch: false,
		vegan: true,
		lowFat: false,
		LowCarb: false,
		italienisch: true,
		spanisch: true,
		indisch: true,
		japanisch: true,
		chinesisch: true,
		arabisch: true,
		senf: false,
		schwefeldioxid: false,
		sulphite: false,
		nüsse: false,
		gluten: true,
		sellerie: false,
		sesamsamen: true,
		sojabohnen: true,
		laktose: false,
		eierzeugnisse: false,
		sellerieerzeugnisse: false,
		hülsenfrüchte: false,
		titleImage: "./R0029/0.webp",
		name: "Gemüse-Dumplings mit Tomaten-Chutney (asiatischer Art)",
		level: 2,
		punkte: 3,
		kochzeit: 15,
		supermarktpreis: 3.42,
		kalorien: 893,
		schärfegrad: 3,
		gerichtbeschreibung:
			"Lorem ipsum dolor sit amet, consectetur adipiscing elit. At gravida faucibus consequat nec, dictum quis enim lacus. Dignissim maecenas velit faucibus diam vitae elementum. Sed risus dui aliquam varius dignissim pharetra, hendrerit. Ullamcorper amet enim ipsum faucibus nibh.",
		fancies: [ "Gemahlener Kreuzkümmel", "Chili-Gewürz", "Sesamöl", "Sojasauce", "Worcester-Soße", "Maisstärke" ],
		einkaufsliste: [
			"Kirschtomaten",
			"Fleischtomate",
			"Zwiebel(rot)",
			"Lauchzwiebeln",
			"Champignons",
			"Knoblauchzehe",
			"Ingwer(frisch)",
			"Schalotte",
			"Karotte",
			"Spitzkohl",
			"Mehl"
		],
		zutatenZuhause: [ "Salz", "Pfeffer", "Zucker", "Pflanzenöl", "Essig", "Mehl" ],
		kcl: 893,
		fett: 27.9,
		kohlenhydrate: 107.3,
		eiweiß: 49.4,
		utensilien: [
			"Pfanne(mittelgroß)",
			"Kochtopf(mittelgroß)",
			"Kochtopf(klein)",
			"Küchenreibe",
			"Sparschäler",
			"Pürierstab",
			"Küchenstampfer"
		],
		allergene: [ "Gluten", "Sesamsamen", "Sojabohnen", "kann Spuren von anderen Allergenen enthalten" ],
		steps: [
			{
				stepName: "Teig zubereiten",
				stepDescription:
					"Mische 150g Mehl, 80ml Wasser und eine Prise Salz zusammen und knete den Teig gut durch.",
				stepImage: "./R0029/1.webp"
			},
			{
				stepName: "Füllung vorbereiten",
				stepDescription:
					"Schäle die Ingwer und reibe ihn fein ab. Schäle die Schalotte, Karotte, Knoblauchzehe und den Spitzkohl und schneide alles in kleine Würfel. Würfel auch die Champignons. Die Lauchzwiebeln können in kleine Halbringe zerkleinert werden. Erhitze in einem großen Topf 1 EL Pflanzenöl zusammen mit dem Sesamöl. Dünste das soeben geschnittene Gemüse darin an. Gib 1 EL von dem geriebenen Ingwer dazu und stelle den Rest beiseite. Schmecke mit etwas Salz und Pfeffer ab. Wenn die Füllung cremiger sein soll, kannst du das Gemüse nun klein stampfen. Reduziere die Hitze auf niedrigste Stufe. ",
				stepImage: "./R0029/2.webp"
			},
			{
				stepName: "Chutney zubereiten",
				stepDescription:
					"Stelle einen weiteren kleinen Topf mit Wasser auf. Entferne den Strunk der Fleischtomaten und blanchiere sie in dem nun siedenden Wasser. Häute die Tomaten und schneide sie in kleine Würfel. Häute und schneide auch die rote Zwiebel klein. Hacke auch die Kirschtomaten möglichst klein. Belasse nur etwa 200 ml Wasser in dem Topf, in dem die Fleischtomaten eben blanchierten und gib die klein gewürfelten Tomaten, Zwiebel, 1 EL von dem geriebenen Ingwer, 100g Zucker, 100 ml Essig, die Worcester-Soße, den Kreuzkümmel, das Chiligewürz hinzu. Lass alles zusammen einkochen und würze mit etwas Salz und Pfeffer nach. Je nach gewünschter Konstistenz kann etwas Wasser ergänzt oder das Chutney püriert werden. ",
				stepImage: "./R0029/3.webp"
			},
			{
				stepName: "Teig ausrollen, ausstechen, füllen",
				stepDescription:
					"Rolle den Teig aus, steche mit einer großen runden Ausstechform Kreise aus oder schneide so gut es geht Kreise aus dem Teig. Rühre die Maisstärke mit 4-5 EL Wasser an und bestreiche damit den Teig. Fülle die Teigausstiche nun mit dem zuerst gebratenen Gemüse. Klappe die Teigenden zusammen und drücke sie aneinenader fest, sodass die Gemüse-Dumplings verschlossen sind. ",
				stepImage: "./R0029/4.webp"
			},
			{
				stepName: "Dumplings blanchieren und anbraten ",
				stepDescription:
					"Lasse in dem frei werdenden Topf etwas Wasser kochen und blanchiere die Gemüse-Dumplings für 2-3 Minuten darin. Erhitze in der Zwischenzeit 1-2 EL Pflanzenöl in einer Pfanne. Nimm die Gemüsetaschen aus dem kochenden Wasser und brate sie beidseitig kurz an. ",
				stepImage: "./R0029/5.webp"
			},
			{
				stepName: "Servieren",
				stepDescription: "Serviere die Gemüse-Dumplings zusammen mit dem Chutney.",
				stepImage: "./R0029/6.webp"
			}
		]
	},
	{
		id: "R0030",
		geflügel: false,
		rind: false,
		schwein: false,
		fisch: false,
		meeresfrüchte: false,
		milch: false,
		ei: false,
		vegetarisch: false,
		vegan: true,
		lowFat: true,
		LowCarb: false,
		italienisch: true,
		spanisch: true,
		indisch: true,
		japanisch: true,
		chinesisch: true,
		arabisch: true,
		senf: false,
		schwefeldioxid: true,
		sulphite: true,
		nüsse: true,
		gluten: false,
		sellerie: false,
		sesamsamen: true,
		sojabohnen: false,
		laktose: false,
		eierzeugnisse: false,
		sellerieerzeugnisse: false,
		hülsenfrüchte: true,
		titleImage: "./R0030/0.webp",
		name: "Buchweizen-Sesam-Salat mit gepickelten roten Zwiebeln",
		level: 2,
		punkte: 3,
		supermarktpreis: 2.41,
		kalorien: 683,
		schärfegrad: 0,
		gerichtbeschreibung:
			"Lorem ipsum dolor sit amet, consectetur adipiscing elit. At gravida faucibus consequat nec, dictum quis enim lacus. Dignissim maecenas velit faucibus diam vitae elementum. Sed risus dui aliquam varius dignissim pharetra, hendrerit. Ullamcorper amet enim ipsum faucibus nibh.",
		fancies: [
			"Gemahlener Kreuzkümmel",
			"Sesamsamen",
			"Sesamöl",
			"Weißweinessig",
			"Limettensaft",
			"Tahin-Gewürzpaste",
			"Getrocknete Aprikosen",
			"Getrocknete Cranberries"
		],
		einkaufsliste: [ "Zwiebel(rot)", "Lauchzwiebeln", "Ingwer(frisch)", "Buchweizen" ],
		zutatenZuhause: [ "Salz", "Pfeffer", "Zucker" ],
		kcl: 683,
		fett: 28.4,
		kohlenhydrate: 65.3,
		eiweiß: 44,
		utensilien: [ "Pfanne(klein)", "Kochtopf(mittelgroß)", "Kochtopf(klein)", "Schäler", "Schüssel(groß)" ],
		allergene: [
			"Schwefeldioxid",
			"Sulphite",
			"Nüsse",
			"Sesamsamen",
			"Hülsenfrüchte",
			"kann Spuren von anderen Allergenen enthalten"
		],
		steps: [
			{
				stepName: "Buchweizen kochen ",
				stepDescription:
					"Setze einen großen Topf mit einem Liter gesalzenem Wasser auf. Wasche die Buchweizen in einem Sieb ab und gib sie danach in das kochende Wasser. Drehe die Hitze etwas zurück und lasse sie für 15 Minuten garen. ",
				stepImage: "./R0030/1.webp"
			},
			{
				stepName: "Zwiebeln pickeln",
				stepDescription:
					"Schäle die Zwiebel und schneide sie in feine Streifen. Lass in einem kleinen Topf den Essig mit  3 EL Wasser und 1 EL Zucker aufkochen. Gib die Zwiebeln in eine kleine Schale und schütte das aufgekochte Essigwasser darüber. Stelle die Schale beiseite. ",
				stepImage: "./R0030/2.webp"
			},
			{
				stepName: "Salatdressing anrühren",
				stepDescription:
					"Schäle den Ingwer und hacke ihn ganz fein. Mische das Sesamöl, die Tahin-Gewürzpaste, 1 EL Zucker, den Limettensaft, den Ingwer und etwa 4 EL Wasser mit dem Kümmel, einer Prise Salz und Pfeffer zusammen. ",
				stepImage: "./R0030/3.webp"
			},
			{
				stepName: "Trockenfrüchte zerkleinern",
				stepDescription: "Hacke die getrockneten Aprikosen und die Cranberries fein.",
				stepImage: "./R0030/4.webp"
			},
			{
				stepName: "Sesam rösten",
				stepDescription:
					"Röste in einer kleinen Pfanne ohne Zugabe von Fetten oder Ölen die Sesamsamen goldbraun.",
				stepImage: "./R0030/5.webp"
			},
			{
				stepName: "Salat fertigstellen und Servieren ",
				stepDescription:
					"Vermische die fertig gekochten Buchweizen mit dem Dressing und den Sesamsamen. Rühre die Trockendrüchte unter und garniere mit ein paar Ringen der Lauchzwiebel und den gepickelten Zwiebeln.",
				stepImage: "./R0030/6.webp"
			}
		]
	},
	{
		id: "R0031",
		geflügel: false,
		rind: true,
		schwein: false,
		fisch: false,
		meeresfrüchte: false,
		milch: false,
		ei: false,
		vegetarisch: false,
		vegan: false,
		lowFat: false,
		LowCarb: false,
		italienisch: true,
		spanisch: true,
		indisch: true,
		japanisch: true,
		chinesisch: true,
		arabisch: true,
		senf: false,
		schwefeldioxid: false,
		sulphite: false,
		nüsse: true,
		gluten: false,
		sellerie: false,
		sesamsamen: false,
		sojabohnen: false,
		laktose: true,
		eierzeugnisse: false,
		sellerieerzeugnisse: false,
		hülsenfrüchte: false,
		titleImage: "./R0031/0.webp",
		name: "Rinderfilet mit Kartoffel-Pastinakenpüree dazu Sauce Vierge",
		level: 2,
		punkte: 3,
		kochzeit: 20,
		supermarktpreis: 5.45,
		kalorien: 582,
		schärfegrad: 0,
		gerichtbeschreibung:
			"Lorem ipsum dolor sit amet, consectetur adipiscing elit. At gravida faucibus consequat nec, dictum quis enim lacus. Dignissim maecenas velit faucibus diam vitae elementum. Sed risus dui aliquam varius dignissim pharetra, hendrerit. Ullamcorper amet enim ipsum faucibus nibh.",
		fancies: [ "Muskatgewürzmischung", "Pape's Steakgewürz", "Kräuteröl" ],
		einkaufsliste: [
			"Fleischtomate",
			"Knoblauchzehe",
			"Schalotte",
			"Pastinaken",
			"Zitrone",
			"Petersilie",
			"Basilikum",
			"Schnittlauch",
			"Koriander",
			"Butter",
			"Milch",
			"Kartoffel (vorwiegend festkochend)",
			"Rinderfilet"
		],
		zutatenZuhause: [ "Salz", "Pfeffer", "Olivenöl" ],
		kcl: 582,
		fett: 25.6,
		kohlenhydrate: 55.8,
		eiweiß: 29.9,
		utensilien: [
			"Pfanne(mittelgroß)",
			"Kochtopf(groß)",
			"Kochtopf(mittelgroß)",
			"Küchenreibe",
			"Zitronenpresse",
			"Küchenstampfer"
		],
		allergene: [ "Nüsse", "Milch/-erzeugnisse, Laktose", "kann Spuren von anderen Allergenen enthalten" ],
		steps: [
			{
				stepName: "Kartoffeln und Pastinaken schneiden",
				stepDescription:
					"Setze in einem großen Topf gesalzenes Wasser auf. Während dieses erhitzt, wasche die Kartoffeln und Pastinaken. Schneide sie nach dem Schälen in etwa 5cm große Würfel. Gib die Würfel nun in das kochende Wasser und lasse sie für etwa 10 Minuten weich garen. ",
				stepImage: "./R0031/1.webp"
			},
			{
				stepName: "Sauce Vierge zubereiten",
				stepDescription:
					"In der Zwischenzeit kannst du für die Sauce Vierge die Fleischtomaten waschen sehr feine (ca. 4mm) würfeln. Wasche und hacke die Kräuter. Schäle die Knoblauchzehe und Schalotte und schneide sie auch in ganz feine Würfel. Wasche die Zitrone heiß ab, reibe die Zitronenschale und press die Hälfte der Zitrone aus. Mische nun die Tomaten-, Knoblauch-, Schalottenwürfel mit den Kräutern und gib 1 EL Zitronensaft, 1 TL von dem Zitronenabrieb, das Kräuteröl in einer Schüssel zusammen. Würze mit etwas Pfeffer und Salz nach. ",
				stepImage: "./R0031/2.webp"
			},
			{
				stepName: "Kartoffel-Pastinaken-Püree stampfen ",
				stepDescription:
					"Lasse in einem seperaten Kochtopf die Milch und 30g Butter erhitzen. Schmecke mit dem Muskatgewürz und einer Prise Salz ab. Nun müssten auch die Kartoffel- und Pastinakenwürfel weich sein. Gib die abgetropften Würfel in den Topf mit der erhitzten Milch. Stampfe nun den Inhalt des Topfes zu einer homogenen Masse. ",
				stepImage: "./R0031/3.webp"
			},
			{
				stepName: "Rinderfilets würzen",
				stepDescription:
					"Schneide das Rinderfleisch, wenn am Stück gekauft, in zwei dicke Steaks. Würze die Filets beidseitig mit dem Steakgewürz. Erhitze in einer Pfanne 2 EL Öl und brate die Rinderfilets von beiden Seiten für 2 Minuten (medium rare) scharf an. ",
				stepImage: "./R0031/4.webp"
			},
			{
				stepName: "Fleisch ruhen lassen",
				stepDescription:
					"Decke das Fleisch sofort nach dem Braten mit Folie ab und lass es für 5 Minuten ruhen.",
				stepImage: "./R0031/5.webp"
			},
			{
				stepName: "Anrichten und Servieren",
				stepDescription:
					"Platziere das Püree auf einem Teller ansehnlich. Gib das Fleisch darüber und garniere mit der Sauce Vierge. ",
				stepImage: "./R0031/6.webp"
			}
		]
	},
	{
		id: "R0032",
		geflügel: false,
		rind: false,
		schwein: false,
		fisch: false,
		meeresfrüchte: false,
		milch: false,
		ei: false,
		vegetarisch: false,
		vegan: true,
		lowFat: true,
		LowCarb: false,
		italienisch: true,
		spanisch: true,
		indisch: true,
		japanisch: true,
		chinesisch: true,
		arabisch: true,
		senf: false,
		schwefeldioxid: false,
		sulphite: false,
		nüsse: false,
		gluten: false,
		sellerie: false,
		sesamsamen: false,
		sojabohnen: false,
		laktose: false,
		eierzeugnisse: false,
		sellerieerzeugnisse: false,
		hülsenfrüchte: true,
		titleImage: "./R0032/0.webp",
		name: "Linsen-Kürbis-Curry",
		level: 1,
		punkte: 3,
		kochzeit: 25,
		supermarktpreis: 2.87,
		kalorien: 677,
		schärfegrad: 4,
		gerichtbeschreibung:
			"Lorem ipsum dolor sit amet, consectetur adipiscing elit. At gravida faucibus consequat nec, dictum quis enim lacus. Dignissim maecenas velit faucibus diam vitae elementum. Sed risus dui aliquam varius dignissim pharetra, hendrerit. Ullamcorper amet enim ipsum faucibus nibh.",
		fancies: [
			"Nelkenpfeffer(gemahlen)",
			"Gewürznelken(gemahlen)",
			"Chili-Gewürz",
			"Gemüsebrühgewürz",
			"Rote Currypaste",
			"Tomatenmark"
		],
		einkaufsliste: [
			"Zwiebel(weiß)",
			"Knoblauchzehe",
			"Passierte Tomaten",
			"Hokkaido-Kürbis",
			"Koriander",
			"Kokosmilch",
			"Linsen(rot)"
		],
		zutatenZuhause: [ "Salz", "Pfeffer", "Pflanzenöl" ],
		kcl: 677,
		fett: 30.5,
		kohlenhydrate: 58.1,
		eiweiß: 50.5,
		utensilien: [ "Kochtopf(groß)", "Messbecher" ],
		allergene: [ "Hülsenfrüchte", "kann Spuren von anderen Allergenen enthalten" ],
		steps: [
			{
				stepName: "Kürbis schneiden",
				stepDescription:
					"Wasche den Kürbis, halbiere ihn, löse die Kerne aus und schneide das Fruchtfleisch in mundgerechte Stücke. Schäle den Knoblauch und die Zwiebel und würfel sie fein. Wasche die Chilischote, entkerne und hacke sie anschließend fein. Je nach Schärfewunsch können die Kerne aufgehoben werden. ",
				stepImage: "./R0032/1.webp"
			},
			{
				stepName: "Currypaste auflösen",
				stepDescription:
					"Setze 1500 ml Gemüsebrühe an. Entnehme davon 500 ml und löse darin die Currypaste auf.",
				stepImage: "./R0032/2.webp"
			},
			{
				stepName: "Kürbis anbraten",
				stepDescription:
					"Erhitze in einem großen Topf 2 EL Pflanzenöl. Schwitze die Zwiebeln und Knoblauch darin an. Gib nach 2-3 Minuten den Kürbis und die Chili dazu und dünste sie für 5 Minuten mit an.",
				stepImage: "./R0032/3.webp"
			},
			{
				stepName: "Linsen zugeben und kochen",
				stepDescription:
					"Lösche mit den passierten Tomaten, Tomatenmark, Currybrühe und der restlichen Gemüsebrühe ab. Gib die Linsen in die Brühe und würze mit dem Nelkenpfeffer und Gewürznelken nach. Lasse den Topf nun abgedeckt bei etwas reduzierter Hitze für weitere 20 Minuten köcheln.",
				stepImage: "./R0032/4.webp"
			},
			{
				stepName: "Kokosmilch hinzugeben",
				stepDescription:
					"Nachdem die Linsen nun weich geworden sind, gib die Kokosmilch mit hinein. Lass alles zusammen noch für weitere 3-5 Minuten köcheln. Wasche in der Zwischenzeit den Koriander und hacke ihn fein. ",
				stepImage: "./R0032/5.webp"
			},
			{
				stepName: "Servieren",
				stepDescription: "Serviere das Curry mit etwas frischem Koriander.",
				stepImage: "./R0032/6.webp"
			}
		]
	},
	{
		id: "R0033",
		geflügel: false,
		rind: false,
		schwein: false,
		fisch: true,
		meeresfrüchte: false,
		milch: false,
		ei: false,
		vegetarisch: false,
		vegan: false,
		lowFat: false,
		LowCarb: true,
		italienisch: true,
		spanisch: true,
		indisch: true,
		japanisch: true,
		chinesisch: true,
		arabisch: true,
		senf: false,
		schwefeldioxid: false,
		sulphite: false,
		nüsse: false,
		gluten: false,
		sellerie: false,
		sesamsamen: false,
		sojabohnen: false,
		laktose: false,
		eierzeugnisse: false,
		sellerieerzeugnisse: false,
		hülsenfrüchte: false,
		titleImage: "./R0033/0.webp",
		name: "Saibling auf Kartoffel-Lauch-Gemüse",
		level: 2,
		punkte: 3,
		kochzeit: 25,
		supermarktpreis: 4.21,
		kalorien: 893,
		schärfegrad: 1,
		gerichtbeschreibung:
			"Lorem ipsum dolor sit amet, consectetur adipiscing elit. At gravida faucibus consequat nec, dictum quis enim lacus. Dignissim maecenas velit faucibus diam vitae elementum. Sed risus dui aliquam varius dignissim pharetra, hendrerit. Ullamcorper amet enim ipsum faucibus nibh.",
		fancies: [
			"Pfefferkörner(bunt)",
			"Muskatgewürzmischung",
			"Pape's Fischgewürz",
			"Chili-Gewürz",
			"Fenchelsamen",
			"Gemüsebrühgewürz"
		],
		einkaufsliste: [ "Porree", "Salbei", "Butter", "Kartoffel(vorwiegend festkochend)", "Seiblingsfilet" ],
		zutatenZuhause: [ "Salz", "Pfeffer", "Butter", "Mehl" ],
		kcl: 893,
		fett: 27.9,
		kohlenhydrate: 107.3,
		eiweiß: 49,
		utensilien: [ "Pfanne(klein)", "Pfanne(mittelgroß)", "Pfanne(groß)", "Kochtopf(mittelgroß)", "Messbecher" ],
		allergene: [ "kann Spuren von anderen Allergenen enthalten" ],
		steps: [
			{
				stepName: "Kartoffeln kochen",
				stepDescription:
					"Setze ausreichend leicht gesalzenes Wasser in einem mittelgroßen Topf auf. Wasche die Kartoffeln und lass sie für 20 Minuten in dem kochenden Wasser garen. Wasche den Poree und schneide etwa die Hälfte in feine Streifen. Wasche den Salbei und hacke ihn fein. ",
				stepImage: "./R0033/1.webp"
			},
			{
				stepName: "Fenchelsamen rösten",
				stepDescription:
					"Röste in einer Pfanne ohne Zugabe von Ölen die Fenchelsamen bei mittlerer Hitze für etwa 2 Minuten an. ",
				stepImage: "./R0033/2.webp"
			},
			{
				stepName: "Kartoffeln pellen und würfeln",
				stepDescription:
					"Lass die Kartoffeln nach der Kochzeit ausdampfen. Pelle sie anschließend und schneide sie in mundgerechte Würfel. Setze 100 ml Gemüsebrühe an. ",
				stepImage: "./R0033/3.webp"
			},
			{
				stepName: "Kartoffel-Lauch-Gemüse dünsten",
				stepDescription:
					"Schmelze in einer Pfanne etwa 30g Butter bei mittlerer Hitze. Gib den Lauch dazu und lass ihn für 3 Minuten darin dünsten. Lösche mit der Gemüsebrühe ab. Gib die Kartoffeln, den Fenchel, die Pfefferkörner, den Salbei, das Muskat- und Chiligewürz dazu. Schmecke mit einer Prise Salz und Pfeffer ab. ",
				stepImage: "./R0033/4.webp"
			},
			{
				stepName: "Saiblingfilets braten",
				stepDescription:
					"Falls Saiblingfilet am Stück gekauft ist, schneide zwei schöne Filets daraus. Mehliere nun den Saibling auf der Hautseite und würze mit dem Fischgewürz. Erhitze in einer Pfanne 2 EL Pfalnzenöl und brate den Fisch auf der Hautseite für 4 Minuten an. Gib etwa 20g Butter dazu und wende den Fisch. Falls die Filets scharf angebraten sind und dennoch unsicher bist, ob sie gar sind, können die Filets je nach Dicke und Größe bei 180°C im Ofen nachziehen. ",
				stepImage: "./R0033/5.webp"
			},
			{
				stepName: "Anrichten und Servieren",
				stepDescription: "Serviere das Gemüse zusammen mit dem Fisch und etwas Bratbutter.",
				stepImage: "./R0033/6.webp"
			}
		]
	},
	{
		id: "R0034",
		geflügel: false,
		rind: false,
		schwein: false,
		fisch: false,
		meeresfrüchte: false,
		milch: false,
		ei: false,
		vegetarisch: true,
		vegan: false,
		lowFat: false,
		LowCarb: false,
		italienisch: true,
		spanisch: true,
		indisch: true,
		japanisch: true,
		chinesisch: true,
		arabisch: true,
		senf: false,
		schwefeldioxid: false,
		sulphite: false,
		nüsse: false,
		gluten: false,
		sellerie: false,
		sesamsamen: false,
		sojabohnen: false,
		laktose: true,
		eierzeugnisse: false,
		sellerieerzeugnisse: false,
		hülsenfrüchte: true,
		titleImage: "./R0034/0.webp",
		name: "Kürbis-Süßkartoffel-Tagliatelle",
		level: 1,
		punkte: 3,
		kochzeit: 30,
		supermarktpreis: 3.12,
		kalorien: 683,
		schärfegrad: 1,
		gerichtbeschreibung:
			"Lorem ipsum dolor sit amet, consectetur adipiscing elit. At gravida faucibus consequat nec, dictum quis enim lacus. Dignissim maecenas velit faucibus diam vitae elementum. Sed risus dui aliquam varius dignissim pharetra, hendrerit. Ullamcorper amet enim ipsum faucibus nibh.",
		fancies: [ "Chili-Gewürz", "Parmesan(gerieben)", "Kürbiskerne", "Gemüsebrühgewürz", "Getrocknete Cranberries" ],
		einkaufsliste: [
			"Zwiebel(weiß)",
			"Knoblauchzehe",
			"Hokkaido-Kürbis",
			"Limette",
			"Tagliatelle",
			"Süßkartoffeln"
		],
		zutatenZuhause: [ "Salz", "Pfeffer", "Pflanzenöl" ],
		kcl: 683,
		fett: 28.4,
		kohlenhydrate: 65.3,
		eiweiß: 44,
		utensilien: [
			"Pfanne(klein)",
			"Kochtopf(groß)",
			"Kochtopf(mittelgroß)",
			"Küchenreibe",
			"Schäler",
			"Pürierstab",
			"Küchenwaage"
		],
		allergene: [ "Milch/-erzeugnisse, Laktose", "Hülsenfrüchte", "kann Spuren von anderen Allergenen enthalten" ],
		steps: [
			{
				stepName: "Gemüse schneiden",
				stepDescription:
					"Wasche, halbiere und entkerne den Kürbis. Für 2 Personen reicht die Verwendung einer Kürbishälfte. Schneide davon die Hälfte in grobe Stücke und die andere Hälfte in möglichst feine, gleichgroße Würfel. Würfel nun auch die Chili, den Knoblauch und die Zwiebel so fein wie möglich. Schäle die Limette und schneide das Fruchtfleisch klein. Wiege etwa 100g von dem Limettenfruchtfleisch ab. Schäle die Süßkartoffel und schneide sie ebenfalls in grobe Stücke. ",
				stepImage: "./R0034/1.webp"
			},
			{
				stepName: "Topf aufsetzen, Zutaten garen",
				stepDescription:
					"Setze in einem großen Topf 450 ml Gemüsebrühe an und bringe sie darin zum Kochen. Gib den Knoblauch, die Chili, Zwiebel und groben Gemüsewürfel hinein und lass alles für 15 Minuten garen. ",
				stepImage: "./R0034/2.webp"
			},
			{
				stepName: "Topfinhalt pürieren",
				stepDescription:
					"Sobald der Kürbis und die Süßkartoffeln weich gekocht sind, püriere alles gut durch, bis eine feine Soße entsteht. Schmecke mit Salz und Pfeffer ab. Gib nun das abgewogenen und klein geschnittene Limettenfruchtfleisch zusammen mit den feinen Kürbiswürfeln dazu. Lass diese nun für 10-15 Minuten einköcheln. ",
				stepImage: "./R0034/3.webp"
			},
			{
				stepName: "Tagliatelle kochen",
				stepDescription:
					"Setze in der Zwischenzeit einen seperaten Topf mit ausreichend leicht gesalzenem Wasser für die Nudeln auf. Lass die Tagliatelle in dem kochenden Salzwasser garen. ",
				stepImage: "./R0034/4.webp"
			},
			{
				stepName: "Kürbiskerne rösten, Cranberry hacken",
				stepDescription:
					"Röste währenddessen in einer kleinen Pfanne ohne Zugabe von Ölen oder Fetten die Kürbiskerne für 3-4 Minuten an. Hacke die getrockneten Cranberries und stelle den geriebenen Parmesan bereit. ",
				stepImage: "./R0034/5.webp"
			},
			{
				stepName: "Anrichten und Servieren",
				stepDescription:
					"Die fertig gekochten, noch bissfesten Nudeln können nach dem Abtropfen mit in die Kürbissoße vermengt werden. Serviere das Gericht auf einem großen Teller und garniere mit den Kürbiskernen, Cranberries und dem Parmesan darüber. ",
				stepImage: "./R0034/6.webp"
			}
		]
	},
	{
		id: "R0035",
		geflügel: false,
		rind: false,
		schwein: true,
		fisch: false,
		meeresfrüchte: false,
		milch: false,
		ei: false,
		vegetarisch: false,
		vegan: false,
		lowFat: false,
		LowCarb: false,
		italienisch: true,
		spanisch: true,
		indisch: true,
		japanisch: true,
		chinesisch: true,
		arabisch: true,
		senf: false,
		schwefeldioxid: false,
		sulphite: false,
		nüsse: false,
		gluten: true,
		sellerie: false,
		sesamsamen: false,
		sojabohnen: false,
		laktose: true,
		eierzeugnisse: false,
		sellerieerzeugnisse: false,
		hülsenfrüchte: false,
		titleImage: "./R0035/0.webp",
		name: "Schweinesteaks in Pfefferschaum mit Parmesan-Polenta",
		punkte: 3,
		supermarktpreis: 3.42,
		kalorien: 582,
		schärfegrad: 0,
		gerichtbeschreibung:
			"Lorem ipsum dolor sit amet, consectetur adipiscing elit. At gravida faucibus consequat nec, dictum quis enim lacus. Dignissim maecenas velit faucibus diam vitae elementum. Sed risus dui aliquam varius dignissim pharetra, hendrerit. Ullamcorper amet enim ipsum faucibus nibh.",
		fancies: [ "Pfefferkörner(bunt)", "Muskatgewürzmischung", "Parmesan(gerieben)", "Gemüsebrühgewürz" ],
		einkaufsliste: [ "Butter", "Milch", "Polenta", "Schweinerückensteak" ],
		zutatenZuhause: [ "Salz", "Pfeffer", "Pflanzenöl", "Butter" ],
		kcl: 582,
		fett: 25.6,
		kohlenhydrate: 55.8,
		eiweiß: 29.9,
		utensilien: [ "Pfanne(mittelgroß)", "Kochtopf(mittelgroß)", "Kochtopf(klein)", "Pürierstab" ],
		allergene: [ "Gluten", "Milch/-erzeugnisse, Laktose", "kann Spuren von anderen Allergenen enthalten" ],
		steps: [
			{
				stepName: "Gemüsebrühe ansetzen",
				stepDescription:
					"Vermenge in einem mittelgroßen Topf 500ml Milch und 250 ml Wasser. Lass es aufkochen und rühre das Gemüsebrühgewürz ein.",
				stepImage: "./R0035/1.webp"
			},
			{
				stepName: "Polenta garen",
				stepDescription:
					"Reduziere auf die geringste Hitzestufe, sobald die Gemüsebrühe anfängt zu kochen. Lass die Polenta nun unter Rühren einrieseln und bei schwacher Hitze für ungefähr 10 Minuten garen. Wenn der Polenta schön gequollen ist, rühre den Parmesan und 25g Butter unter. Stelle die Herdplatten aus und lass die Polenta bei geschlossenem Deckel nachziehen. ",
				stepImage: "./R0035/2.webp"
			},
			{
				stepName: "Pfefferschaum zubereiten",
				stepDescription:
					"Lass für den Schaum 250ml Milch mit den Pfefferbeeren auf schwacher Hitze einköcheln. Schmecke mit Salz und Muskat ab. Schäume erst kurz vor dem Servieren mit einem Mixstab die Milch auf. ",
				stepImage: "./R0035/3.webp"
			},
			{
				stepName: "Schweinesteaks vorbereiten",
				stepDescription:
					"Falls das Fleisch am Stück gekauft wurde, schneide zwei schöne Steaks. Tupfe die Schweinesteaks ab und würze beidseitig mit Salz und Pfeffer.",
				stepImage: "./R0035/4.webp"
			},
			{
				stepName: "Steaks scharf anbraten",
				stepDescription:
					"Erhitze in einer Pfanne 3 EL Pflanzenöl und brate die Steaks von beiden Seiten für 2-3 Minuten scharf an. Nimm sie dann aus der Pfanne und lass sie für 2-3 Minuten ruhen, bevor du sie anschneidest. Die Zeit kannst du perfekt zum Pürieren des Pfefferschaums nutzen.",
				stepImage: "./R0035/5.webp"
			},
			{
				stepName: "Anrichten und Servieren",
				stepDescription:
					"Serviere die Polenta auf einem großen Teller, richte die geschnittenen Schweinesteaks darauf an und gib den Pfefferschaum darüber. Nach Belieben kann mit etwas frischen Gewürzen (z.B. Dill) garniert werden.",
				stepImage: "./R0035/6.webp"
			}
		]
	},
	{
		id: "R0036",
		geflügel: false,
		rind: false,
		schwein: false,
		fisch: true,
		meeresfrüchte: false,
		milch: false,
		ei: false,
		vegetarisch: false,
		vegan: false,
		lowFat: false,
		LowCarb: true,
		italienisch: true,
		spanisch: true,
		indisch: true,
		japanisch: true,
		chinesisch: true,
		arabisch: true,
		senf: false,
		schwefeldioxid: false,
		sulphite: false,
		nüsse: false,
		gluten: false,
		sellerie: false,
		sesamsamen: false,
		sojabohnen: false,
		laktose: true,
		eierzeugnisse: false,
		sellerieerzeugnisse: false,
		hülsenfrüchte: false,
		titleImage: "./R0036/0.webp",
		name: "Matjestatar auf Gemüserösti",
		level: 2,
		punkte: 3,
		kochzeit: 15,
		supermarktpreis: 2.96,
		kalorien: 677,
		schärfegrad: 0,
		gerichtbeschreibung:
			"Lorem ipsum dolor sit amet, consectetur adipiscing elit. At gravida faucibus consequat nec, dictum quis enim lacus. Dignissim maecenas velit faucibus diam vitae elementum. Sed risus dui aliquam varius dignissim pharetra, hendrerit. Ullamcorper amet enim ipsum faucibus nibh.",
		fancies: [ "Muskatgewürzmischung", "Cayenne-Pfeffer", "Currypulver", "Rote Currypaste", "Speisestärke" ],
		einkaufsliste: [
			"Zwiebel (rot)",
			"Knoblauchzehe",
			"Schalotte",
			"Radieschen",
			"Karotte",
			"Zucchini",
			"Zitrone",
			"Apfel",
			"Schnittlauch",
			"Saure Sahne",
			"Eier",
			"Kartoffeln(festkochend)",
			"Matjesfilet"
		],
		zutatenZuhause: [ "Salz", "Pfeffer", "Olivenöl", "Honig" ],
		kcl: 677,
		fett: 30.5,
		kohlenhydrate: 58.1,
		eiweiß: 50.5,
		utensilien: [ "Pfanne(mittelgroß)", "Küchenreibe", "Zitronenpresse", "Schäler", "Schüssel(groß)" ],
		allergene: [ "Milch/-erzeugnisse, Laktose", "kann Spuren von anderen Allergenen enthalten" ],
		steps: [
			{
				stepName: "Matjestatar zubereiten",
				stepDescription:
					"Schäle die rote Zwiebel und schneide sie gemeinsam mit dem Apfel, den Radieschen und den Matjesfilets in ganz feine (ca. 4mm große) Würfel und mische alles in einer Schüssel gut durch. ",
				stepImage: "./R0036/1.webp"
			},
			{
				stepName: "Vinaigrette verrühren",
				stepDescription:
					"Wasche die Zitrone unter heißem Wasser und reibe die Schale ab. Presse eine Zitronenhälfte aus. Verrühre in der Sauren Sahne 1 TL Honig zusammen mit der Currypaste, dem Currypulver, 2 EL Olivenöl, 1 TL geriebener Zitronenschale und 1 EL Zitronensaft zu einer Vinaigrette. ",
				stepImage: "./R0036/2.webp"
			},
			{
				stepName: "Vinaigrette und Matjestatar mische",
				stepDescription: "Mische die Vinaigrette mit den Tatarwürfeln und stelle es kalt.",
				stepImage: "./R0036/3.webp"
			},
			{
				stepName: "Gemüseröstis formen",
				stepDescription:
					"Schäle die Kartoffeln und raspel sie zusammen mit den Zuchhinis. Schäle die Schalotte und die Zehe Knoblauch und würfel nach Belieben jeweils die Hälfte oder mehr fein. Mische in einer Schüssel alles miteinander und gib das Muskatgewürz und Ceyenne-Pfeffer hinzu. Schmecke mit einer Prise Salz und Pfeffer ab. Knete das Ei unter, sodass sich nun aus der Masse Röstis formen lassen. ",
				stepImage: "./R0036/4.webp"
			},
			{
				stepName: "Gemüseröstis braten",
				stepDescription:
					"Erhitze in einer mittelgroßen Pfanne 2 EL Pflanzenöl und lass die rund geformten Röstis darin ausbacken.",
				stepImage: "./R0036/5.webp"
			},
			{
				stepName: "Schnittlauch schneiden und servieren",
				stepDescription:
					"Wasche den Schnittlauch, schüttel ihn trocken und schneide ihn fein. Schmecke das Matjestatar erneut ab. Je nach Geschmack kann erneut eine Messerspitze Honig untergerührt werden. Serviere es auf den Gemüserösti mit etwas Schnittlauch als Verzierung. ",
				stepImage: "./R0036/6.webp"
			}
		]
	},
	{
		id: "R0037",
		geflügel: true,
		rind: false,
		schwein: false,
		fisch: false,
		meeresfrüchte: false,
		milch: false,
		ei: false,
		vegetarisch: false,
		vegan: false,
		lowFat: false,
		LowCarb: false,
		italienisch: true,
		spanisch: true,
		indisch: true,
		japanisch: true,
		chinesisch: true,
		arabisch: true,
		senf: false,
		schwefeldioxid: false,
		sulphite: false,
		nüsse: false,
		gluten: false,
		sellerie: false,
		sesamsamen: false,
		sojabohnen: false,
		laktose: false,
		eierzeugnisse: false,
		sellerieerzeugnisse: false,
		hülsenfrüchte: false,
		titleImage: "./R0037/0.webp",
		name: "Coq au Riesling",
		level: 2,
		punkte: 3,
		kochzeit: 60,
		supermarktpreis: 4.23,
		kalorien: 893,
		schärfegrad: 0,
		gerichtbeschreibung:
			"Lorem ipsum dolor sit amet, consectetur adipiscing elit. At gravida faucibus consequat nec, dictum quis enim lacus. Dignissim maecenas velit faucibus diam vitae elementum. Sed risus dui aliquam varius dignissim pharetra, hendrerit. Ullamcorper amet enim ipsum faucibus nibh.",
		fancies: [
			"Loorbeerblatt",
			"Quatre Epices",
			"Gewürzsäckchen",
			"Geflügelbrühgewürz",
			"Tomatenmark",
			"Getrocknete Wacholderbeeren"
		],
		einkaufsliste: [
			"Champignons",
			"Knoblauchzehe",
			"Schalotte",
			"Suppengrün(fertiger Mix)",
			"Petersilie",
			"Rosmarin",
			"Jasmin-Reis",
			"Hähnchenbrustfilet",
			"Hähnchenflügel",
			"Bacon-Speckwürfel",
			"Trockener Riesling",
			"Apfelsaft"
		],
		zutatenZuhause: [ "Salz", "Pfeffer", "Pflanzenöl" ],
		kcl: 893,
		fett: 27.9,
		kohlenhydrate: 107.3,
		eiweiß: 49.4,
		utensilien: [ "Bräter/Topf (groß)", "Kochtopf(klein)", "Messbecher" ],
		allergene: [ "kann Spuren von anderen Allergenen enthalten" ],
		steps: [
			{
				stepName: "Gemüse schneiden",
				stepDescription:
					"Schäle die Zwiebel und Knoblauch und würfel alles fein. Wasche das Suppengrün und würfel es ebenfalls möglich klein. Putze die Champignons aus und halbiere sie. ",
				stepImage: "./R0037/1.webp"
			},
			{
				stepName: "Hähnchenfleisch anbraten",
				stepDescription:
					"Erhitze in einem großen Bräter 3 EL Pflanzenöl. Brate darin die Hähnchenbrust und -flügel von beiden Seiten für 3 Minuten scharf an. Nimm sie heraus und stelle sie beiseite.",
				stepImage: "./R0037/2.webp"
			},
			{
				stepName: "Gemüse anschwitzen und ablöschen",
				stepDescription:
					"Schwitze nun den Speck, die Knoblauch- und Zwiebelwürfel in dem gleichen Bräter an. Gib das Gemüse und die Champignons dazu und dünste sie kurz mit. Rühre das Tomatenmark ein. Lösche in kleinen Portionen nach und nach mit dem Riesling und Apfelsaft ab. Reduziere die Hitze auf die mittlere Stufe und lass alles langsam Einreduzieren.",
				stepImage: "./R0037/3.webp"
			},
			{
				stepName: "Brühe, Gewürze, Fleisch köcheln lassen",
				stepDescription:
					"Sobald die Flüssigkeit reduziert ist, gib das Hähnchenfleisch wieder zurück in den Bräter. Rühre die Hühnerbrühe an und fülle sie danach mit in den Bräter. Gib das Loorbeerblatt, den Rosmarin und Wacholder in ein Gewürzsäckchen und lass alles zusammen für 1 Stunde bei mittlerer Hitze köcheln.",
				stepImage: "./R0037/4.webp"
			},
			{
				stepName: "Reis garen",
				stepDescription:
					"Setze in der Zwischenzeit in einem kleinen Topf leicht gesalzenes Wasser für den Reis auf. Lass den Reis nach Packungsanleitung garen.",
				stepImage: "./R0037/5.webp"
			},
			{
				stepName: "Coq au Riesling abschmecken und Servieren",
				stepDescription:
					"Schmecke den Coq au Riesling mit Salz, Pfeffer und Quatre Epic ab, entferne das Gewürzsäckchen und binde eventuell den Jus ab.  Serviere das Coq au Riesling mit dem Reis und etwas gehackter Petersilie.",
				stepImage: "./R0037/6.webp"
			}
		]
	},
	{
		id: "R0038",
		geflügel: false,
		rind: true,
		schwein: false,
		fisch: false,
		meeresfrüchte: false,
		milch: false,
		ei: false,
		vegetarisch: false,
		vegan: false,
		lowFat: false,
		LowCarb: false,
		italienisch: true,
		spanisch: true,
		indisch: true,
		japanisch: true,
		chinesisch: true,
		arabisch: true,
		senf: false,
		schwefeldioxid: false,
		sulphite: false,
		nüsse: false,
		gluten: false,
		sellerie: false,
		sesamsamen: false,
		sojabohnen: false,
		laktose: false,
		eierzeugnisse: false,
		sellerieerzeugnisse: false,
		hülsenfrüchte: false,
		titleImage: "./R0038/0.webp",
		name: "Rumpsteak mit Schmelzzwiebeln und Kartoffelsalat",
		punkte: 3,
		supermarktpreis: 5.12,
		kalorien: 683,
		gerichtbeschreibung:
			"Lorem ipsum dolor sit amet, consectetur adipiscing elit. At gravida faucibus consequat nec, dictum quis enim lacus. Dignissim maecenas velit faucibus diam vitae elementum. Sed risus dui aliquam varius dignissim pharetra, hendrerit. Ullamcorper amet enim ipsum faucibus nibh.",
		fancies: [
			"Pape's Steakgewürz",
			"Worcester-Soße",
			"Kräuteröl",
			"Weißweinessig",
			"Gemüsebrühgewürz",
			"Rinderbrühgewürz",
			"Senf (mittelscharf)"
		],
		einkaufsliste: [ "Gemüsezwiebel", "Schalotte", "Rosmarin ", "Butter", "Kartoffeln (festkochend)", "Rumpsteak" ],
		zutatenZuhause: [ "Salz", "Pfeffer", "Zucker", "Pflanzenöl" ],
		kcl: 683,
		fett: 28.4,
		kohlenhydrate: 65.3,
		eiweiß: 44,
		utensilien: [],
		allergene: [],
		steps: [
			{
				stepName: "Kartoffeln pellen und schneiden",
				stepDescription:
					"Setze in einem mittelgroßen Topf ausreichend leicht gesalzenes Wasser auf und koche die Kartoffeln darin gar. Pelle die Kartoffeln, solange sie heiß sind und schneide sie danach in Scheiben. Gib die Kartoffeln in eine Schüssel.",
				stepImage: "./R0038/1.webp"
			},
			{
				stepName: "Rinderbrühe ansetzen",
				stepDescription:
					"Setze in einem kleinen Topf 250 ml Rinderbrühe auf. Schütte diese, sobald sie kocht, über die geschnittenen Kartoffeln. Schäle 1 oder 2 Schalotten und würfel sie fein. Verrühre in einem kleinen Gefäß die Schalottenwürfel mit dem Kräuteröl, 1 TL Senf, 1/2 TL Zucker, 1 Prise Salz und Pfeffer. Vermische das Dressing mit den Kartoffeln und stelle die Schüssel beiseite.",
				stepImage: "./R0038/2.webp"
			},
			{
				stepName: "Schmelzzwiebeln zubereiten",
				stepDescription:
					"Schäle die Gemüsezwiebeln in feine Scheiben und schwitze sie in einer großen Pfanne mit 1 EL Butter und 1 EL Pflanzenöl an. Sobald sie glasig sind, darfst du 1 Prise Salz und Zucker dazu geben und mit der Worcester-Soße würzen. Rühre die Gemüsebrühe an und lösche immer wieder mit etwas Brühe ab.",
				stepImage: "./R0038/3.webp"
			},
			{
				stepName: "Steaks würzen und anbraten",
				stepDescription:
					"Tupfe die Steaks mit etwas Küchenpapier trocken und würze sie beidseitig mit dem Steakgewürz. Erhitze in einer seperaten Pfanne 2 EL Pflanzenöl und brate die Steaks beidseitig für 2-3 Minuten an.",
				stepImage: "./R0038/4.webp"
			},
			{
				stepName: "Rosmarinbutter zugeben und Ruhen lassen",
				stepDescription:
					"Gib etwa 30g Butter und 2 Stiele Rosmarin mit zu den Steaks in die Pfanne. Stelle die Pfanne bei 85°C Ober- und Unterhitze für 5 Minuten in den Ofen.",
				stepImage: "./R0038/5.webp"
			},
			{
				stepName: "Anrichten und Servieren",
				stepDescription:
					"Gib den Kartoffelsalat mit den Rumpsteak auf einen Teller. Lege die Schmelzzwiebeln darüber und beträufel das Essen mit etwas Rosmarin-Butter.",
				stepImage: "./R0038/6.webp"
			}
		]
	},
	{
		id: "R0039",
		geflügel: false,
		rind: false,
		schwein: false,
		fisch: false,
		meeresfrüchte: false,
		milch: false,
		ei: false,
		vegetarisch: true,
		vegan: false,
		lowFat: false,
		LowCarb: false,
		italienisch: true,
		spanisch: true,
		indisch: true,
		japanisch: true,
		chinesisch: true,
		arabisch: true,
		senf: true,
		schwefeldioxid: true,
		sulphite: true,
		nüsse: true,
		gluten: false,
		sellerie: false,
		sesamsamen: false,
		sojabohnen: false,
		laktose: true,
		eierzeugnisse: true,
		sellerieerzeugnisse: false,
		hülsenfrüchte: false,
		titleImage: "./R0039/0.webp",
		name: "Mandel-Backkartoffeln auf Rucola-Salat dazu Aioli",
		level: 2,
		punkte: 3,
		kochzeit: 25,
		supermarktpreis: 2.91,
		kalorien: 634,
		schärfegrad: 0,
		gerichtbeschreibung:
			"Lorem ipsum dolor sit amet, consectetur adipiscing elit. At gravida faucibus consequat nec, dictum quis enim lacus. Dignissim maecenas velit faucibus diam vitae elementum. Sed risus dui aliquam varius dignissim pharetra, hendrerit. Ullamcorper amet enim ipsum faucibus nibh.",
		fancies: [ "Mandelblättchen", "Weißweinessig", "Mayonnaise", "Oliven(schwarz)", "Getrocknete Tomaten" ],
		einkaufsliste: [
			"Kirschtomaten",
			"Knoblauchzehe",
			"Petersilie",
			"Rucola",
			"Fetakäse",
			"Kartoffeln(festkochend)"
		],
		zutatenZuhause: [ "Salz", "Pfeffer", "Olivenöl" ],
		kcl: 634,
		fett: 38,
		kohlenhydrate: 49,
		eiweiß: 23,
		utensilien: [ "Knoblauchpresse", "Backofen", "Backblech", "Backpapier", "Schüssel(groß)" ],
		allergene: [
			"Senf",
			"Schwefeldioxid",
			"Sulphite",
			"Nüsse",
			"Milch/-erzeugnisse, Laktose",
			"Eier/Eierzeugnisse",
			"kann Spuren von anderen Allergenen enthalten"
		],
		steps: [
			{
				stepName: "Ofen vorheizen",
				stepDescription:
					"Heize den Backofen auf 220°C Ober-/Unterhitze oder 200°C Umluft vor. Bereite auf einem Backblech das Backpapier vor.",
				stepImage: "./R0039/1.webp"
			},
			{
				stepName: "Kartoffeln schneiden und backen",
				stepDescription:
					"Reinige die Kartoffeln mit lauwarmen Wasser. Kleine Kartoffeln kannst du längs vierteln und große Kartoffeln achteln. Verteile sie gleichmäßig auf dem Backblech und träufel 3-4 EL Ölivenöl darüber und würze mit einer Prise Salz und Pfeffer nach. Lass sie für 20-25 Minuten goldbraun backen. Stelle dir einen Wecker für die letzten 5 Minuten, damit zum Schluss die Mandelblättchen darüber gestreut und kurz mitgebacken werden können. ",
				stepImage: "./R0039/2.webp"
			},
			{
				stepName: "Rucola-Salat zubereiten",
				stepDescription:
					"Während der Backzeit kannst du den Rucola, die Petersilie und die Kirschtomaten waschen. Schneide den Rucola klein, hacke die Petersilie und halbiere die Kirschtomaten. Verrühre alle Zutaten in einer Schüssel. ",
				stepImage: "./R0039/3.webp"
			},
			{
				stepName: "Trockenfrüchte schneiden",
				stepDescription:
					"Schneide die getrockneten Oliven in Ringe und die getrocknete Tomaten nach Belieben in kleine Stücke. Vermenge sie unter den Rucola-Salat.",
				stepImage: "./R0039/4.webp"
			},
			{
				stepName: "Aioli anrühren",
				stepDescription:
					"Gib die Mayonnaise und den Weißweinessig zusammen mit 2 EL Olivenöl in eine kleine Schüssel. Schäle die Knoblauchzehe und presse sie mit hinein. Rühre alle Zutaten kräftig durch und würze nach Belieben mit ein wenig Salz und Pfeffer nach.",
				stepImage: "./R0039/5.webp"
			},
			{
				stepName: "Backkartoffeln anrichten",
				stepDescription:
					"Schichte in einem tiefen Teller zunächst etwas von dem Rucola-Salat. Belege diesen mit ein paar fertig gebackenen Kartoffeln inklusve der Mandelblätter. Zerbrösel etwas Fetakäse darüber und beträufel die Speiße mit etwas Aioli. Garniere mit etwas gehackter Petersilie. ",
				stepImage: "./R0039/6.webp"
			}
		]
	},
	{
		id: "R0040",
		geflügel: false,
		rind: false,
		schwein: false,
		fisch: false,
		meeresfrüchte: false,
		milch: false,
		ei: false,
		vegetarisch: true,
		vegan: false,
		lowFat: false,
		LowCarb: false,
		italienisch: true,
		spanisch: true,
		indisch: true,
		japanisch: true,
		chinesisch: true,
		arabisch: true,
		senf: false,
		schwefeldioxid: false,
		sulphite: true,
		nüsse: false,
		gluten: false,
		sellerie: false,
		sesamsamen: false,
		sojabohnen: true,
		laktose: false,
		eierzeugnisse: false,
		sellerieerzeugnisse: false,
		hülsenfrüchte: false,
		titleImage: "./R0040/0.webp",
		name: "Linsenauflauf mit braunen Champignons",
		level: 2,
		punkte: 3,
		kochzeit: 35,
		supermarktpreis: 2.45,
		kalorien: 579,
		schärfegrad: 1,
		gerichtbeschreibung:
			"Lorem ipsum dolor sit amet, consectetur adipiscing elit. At gravida faucibus consequat nec, dictum quis enim lacus. Dignissim maecenas velit faucibus diam vitae elementum. Sed risus dui aliquam varius dignissim pharetra, hendrerit. Ullamcorper amet enim ipsum faucibus nibh.",
		fancies: [ "Mediterrane Gewürzmischung", "Sojasauce", "Gemüsebrühgewürz", "Senf(mittelscharf)", "Tomatenmark" ],
		einkaufsliste: [
			"Zwiebel(weiß)",
			"Babyspinat",
			"Champignons",
			"Knoblauchzehe",
			"Paprika(rot)",
			"Ofenkartoffel",
			"Karotte",
			"Braune Linsen(Dose)",
			"Petersilie",
			"Käse(gerieben)"
		],
		zutatenZuhause: [ "Salz", "Pfeffer", "Pflanzenöl", "Butter" ],
		kcl: 579,
		fett: 24,
		kohlenhydrate: 58,
		eiweiß: 30,
		utensilien: [ "Kochtopf(groß)", "Auflaufform", "Sieb", "Schäler", "Backofen", "Backblech", "Wasserkocher" ],
		allergene: [ "Sulphite", "Sojabohnen" ],
		steps: [
			{
				stepName: "Ofen vorheizen, Gemüse schneiden",
				stepDescription:
					"Heize den Backofen auf 220°C Ober- und Unterhitze oder 200°C Umluft vor. Lass 300ml Wasser im Wasserkocher erhitzen. Schäle die Zwiebeln und Karotte und würfel sie klein (5mm). Halbiere die Paprika, entkerne und wasche sie gründlich ab. Schneide die Paprika in etwas größere Würfel (10mm) und viertel die Champignons. Wasche die braunen Linsen über einem Sieb mit kaltem Wasser ab. Wasche auch die Petersilie, tupfe sie trocken und hacke sie grob durch. Die Ofenkartoffeln musst du nur gründlich abwaschen, jedoch nicht schälen, bevor du sie in dünne Scheiben (5mm) schneidest.",
				stepImage: "./R0040/1.webp"
			},
			{
				stepName: "Zwiebeln anbraten",
				stepDescription:
					"Erhitze in einem großen Topf bei mittlerer Hitzestufe 2 EL Pflanzenöl und brate die Zwiebelwürfel für 4 Minuten an. Gib nun die Karotten-, Paprika- und Champignonstücke dazu und brate alles zusammen für weitere 5 Minuten an. Schäle den Knoblauch und presse ihn mit hinein. Setze die Gemüsebrühe mit dem kochenden Wasser an. ",
				stepImage: "./R0040/2.webp"
			},
			{
				stepName: "Linsen untermengen und ablöschen",
				stepDescription:
					"Gib die abgetropften Linsen zusammen mit der Gewürzmischung, dem Tomatenmark, der Sojasoße, dem mittelscharfen Senf in den Topf. Lösche mit der Gemüsebrühe ab. Würze mit einer Prise Salz und Pfeffer. Nachdem alles für 2 Minuten köchelte, darfst du den Babyspinat und etwas mehr als die Hälfte der gehackten Petersilie unterrühren. Lass alles für weitere 4 Minuten köcheln. ",
				stepImage: "./R0040/3.webp"
			},
			{
				stepName: "Auflauf schichten",
				stepDescription:
					"Schichte das Linsengemüse in eine Auflaufform. Lege darüber die Kartoffelscheiben gleichmäßig aus, ohne dass diese sich überschneiden. Verteile gleichmäßig 1 EL Butter darüber und gib die Auflaufform für 30 Minuten in den vorgeheizten Ofen. ",
				stepImage: "./R0040/4.webp"
			},
			{
				stepName: "Käse überbacken",
				stepDescription:
					"Hole nach der Backzeit den Auflauf aus dem Ofen und brösel den geriebenen Cheddar darüber. Falls dein Ofen eine Grillfunktion hat, kann diese für die letzten 5 Minuten eingeschaltet werden, sodass der Käse goldbraun wird. ",
				stepImage: "./R0040/5.webp"
			},
			{
				stepName: "Mit Petersilie anrichten",
				stepDescription:
					"Serviere den Linsenauflauf auf einem Teller und garniere mit der gehackten Petersilie. ",
				stepImage: "./R0040/6.webp"
			}
		]
	},
	{
		id: "R0041",
		geflügel: false,
		rind: false,
		schwein: false,
		fisch: false,
		meeresfrüchte: false,
		milch: false,
		ei: false,
		vegetarisch: true,
		vegan: false,
		lowFat: false,
		LowCarb: false,
		italienisch: true,
		spanisch: true,
		indisch: true,
		japanisch: true,
		chinesisch: true,
		arabisch: true,
		senf: true,
		schwefeldioxid: false,
		sulphite: false,
		nüsse: false,
		gluten: true,
		sellerie: false,
		sesamsamen: false,
		sojabohnen: false,
		laktose: true,
		eierzeugnisse: false,
		sellerieerzeugnisse: false,
		hülsenfrüchte: false,
		titleImage: "./R0041/0.webp",
		name: "Cremiges Pilzragout mit Blätterteigspiralen und Salat",
		level: 2,
		punkte: 3,
		kochzeit: 35,
		supermarktpreis: 3.72,
		kalorien: 1002,
		schärfegrad: 0,
		gerichtbeschreibung:
			"Lorem ipsum dolor sit amet, consectetur adipiscing elit. At gravida faucibus consequat nec, dictum quis enim lacus. Dignissim maecenas velit faucibus diam vitae elementum. Sed risus dui aliquam varius dignissim pharetra, hendrerit. Ullamcorper amet enim ipsum faucibus nibh.",
		fancies: [ "Paprika-Gewürzmischung", "Maisstärke", "Senf(mittelscharf)", "Honig" ],
		einkaufsliste: [
			"Champignons",
			"Knoblauchzehe",
			"Schalotte",
			"Porree",
			"Karotte",
			"Salatmischung(fertig verpackt)",
			"Thymian",
			"Kochsahne",
			"Milch",
			"Blätterteig"
		],
		zutatenZuhause: [ "Salz", "Pfeffer", "Olivenöl", "Pflanzenöl" ],
		kcl: 1002,
		fett: 70.71,
		kohlenhydrate: 66.95,
		eiweiß: 21.33,
		utensilien: [
			"Pfanne(groß)",
			"Auflaufform",
			"Schäler",
			"Backofen",
			"Backblech",
			"Backpapier",
			"Gitterrost",
			"Schüssel(groß)",
			"Pinsel"
		],
		allergene: [ "Senf", "Gluten", "Milch/-erzeugnisse, Laktose", "kann Spuren von anderen Allergenen enthalten" ],
		steps: [
			{
				stepName: "Ofen vorheizen, Gemüse schneiden",
				stepDescription:
					"Heize den Ofen auf 200°C Ober-/Unterhitze oder 180°C Umluft vor. Schäle die Knoblauchzehe, die Schalotte und die Karotten. Halbiere die Karotten und schneide dünne Halbkreise daraus (5mm). Den Knoblauch darfst du im Ganzen hacken, bei der Schalotte nur die Hälfte. Schneide die andere Hälfte der Schalotte in dünne Streifen. Viertel die Champignons. Halbiere den Poree längs und schneide den weißen und hellgrünen Teil in feine Ringe.",
				stepImage: "./R0041/1.webp"
			},
			{
				stepName: "Gemüse anbraten",
				stepDescription:
					"Erhitze in einer Pfanne bei mittlerer Hitzestufe 1 EL Pflanzenöl und schwitze die Schalotten-Streifen zusammen mit dem Knoblauch für 2 Minuten an. Gib nun das restliche geschnittene Gemüse (bis auf die Schalottenwürfel) hinzu und lass alles zusammen für weitere 3 Minuten braten. Lösche anschließend mit 100ml Wasser ab. Würze mit einer Prise Salz und Pfeffer und lass das Gemüse nun für 5 Minuten köcheln, sodass es langsam weich wird. In der Zwischenzeit kannst du die Stärke auflösen. Verrühre dazu die Maisstärke mit 4 EL Wasser in einer kleinen Schüssel und stelle sie vorerst beiseite. ",
				stepImage: "./R0041/2.webp"
			},
			{
				stepName: "Salatdressing anrühren",
				stepDescription:
					"Gib für das Salatdressing 2 EL Olivenöl, den Senf und Honig zusammen mit den Schalottenwürfeln in eine große Schüssel. Würze mit einer Prise Salz und Pfeffer und verrühre alles zu einem homogenen Dressing. Jetzt kannst du auch schon die Blätter von dem Thymianstängel abzupfen und fein hacken. ",
				stepImage: "./R0041/3.webp"
			},
			{
				stepName: "Pilzragout backen",
				stepDescription:
					"Lösche das gebratene Gemüse mit der Kochsahne ab und rühre die Paprika-Gewürzmischung unter.  Würze mit einer Prise Salz und Pfeffer nach und erhöhe die Hitzestufe der Pfanne, sodass die Sahne aufkocht. Gib die gelöste Stärke aus der kleinen Schüssel dazu und lass die Soße nun für 3-4 Minuten eindicken. Gib das eingeköchelte Gemüse nun in eine eingefettete Auflaufform. Lege den Blätterteig darüber und schneide die überstehenden Ränder ab. Bestreiche den Blätterteig zart mit Milch. Ein Pinsel eignet sich hierfür besonders. Stell das Ragout nun für 20 Minuten in den vorgeheizten Ofen, bis der Blätterteig goldbraun gebacken ist.",
				stepImage: "./R0041/4.webp"
			},
			{
				stepName: "Blätterteigspiralen vorbereiten und backen ",
				stepDescription:
					"Schneide den restlichen Blätterteig in 2cm breite Streifen. Bestreiche diese nun auch mit etwas Milch und streue den Thymian gleichmäßig darüber. Nach Belieben darfst du eine Prise Salz und Pfeffer zart darüber stäuben. Drehe die Blätterteigstreifen nun mit den Händen spiralförmig ein. Bereite ein Blech mit Backpapier vor und lass die Streifen nun darauf für die letzten 15 Minuten mitbacken. ",
				stepImage: "./R0041/5.webp"
			},
			{
				stepName: "Ragout stocken lassen und servieren",
				stepDescription:
					"Bevor du das Ragout anschneidest, lass es noch für 1 Minute kurz abkühlen. Vermenge in der Zeit den fertig gekauften Saslatmix in der Schüssel mit dem Dressing. Serviere das Blätterteig-Ragout nun zusammen mit dem Salat und den Blätterteigspiralen. Zur Garnierung darfst du etwas von dem gehackten Thymian darüber streuen. ",
				stepImage: "./R0041/6.webp"
			}
		]
	},
	{
		id: "R0042",
		geflügel: false,
		rind: false,
		schwein: true,
		fisch: false,
		meeresfrüchte: false,
		milch: false,
		ei: false,
		vegetarisch: false,
		vegan: false,
		lowFat: false,
		LowCarb: false,
		italienisch: true,
		spanisch: true,
		indisch: true,
		japanisch: true,
		chinesisch: true,
		arabisch: true,
		senf: false,
		schwefeldioxid: false,
		sulphite: false,
		nüsse: true,
		gluten: false,
		sellerie: false,
		sesamsamen: false,
		sojabohnen: false,
		laktose: true,
		eierzeugnisse: false,
		sellerieerzeugnisse: false,
		hülsenfrüchte: true,
		titleImage: "./R0042/0.webp",
		name: "Risotto mit gebratenem Bacon, Birne, Walnüssen und Parmesan ",
		level: 2,
		punkte: 3,
		kochzeit: 30,
		supermarktpreis: 3.25,
		kalorien: 689,
		schärfegrad: 0,
		gerichtbeschreibung:
			"Lorem ipsum dolor sit amet, consectetur adipiscing elit. At gravida faucibus consequat nec, dictum quis enim lacus. Dignissim maecenas velit faucibus diam vitae elementum. Sed risus dui aliquam varius dignissim pharetra, hendrerit. Ullamcorper amet enim ipsum faucibus nibh.",
		fancies: [ "Parmesan(gerieben)", "Walnüsse", "Gemüsebrühgewürz" ],
		einkaufsliste: [
			"Zwiebel(weiß)",
			"Champignons",
			"Knoblauchzehe",
			"Birne",
			"Schnittlauch",
			"Risotto-Reis",
			"Baconscheiben ",
			"Trockener Weißwein"
		],
		zutatenZuhause: [ "Salz", "Pfeffer", "Olivenöl", "Zucker", "Butter" ],
		kcl: 689,
		fett: 24,
		kohlenhydrate: 93,
		eiweiß: 23,
		utensilien: [ "Pfanne(groß)", "Kochtopf(groß)", "Küchenreibe", "Wasserkocher" ],
		allergene: [
			"Nüsse",
			"Milch/-erzeugnisse, Laktose",
			"Hülsenfrüchte",
			"kann Spuren von anderen Allergenen enthalten"
		],
		steps: [
			{
				stepName: "Zwiebel und Knoblauch würfeln und anbraten",
				stepDescription:
					"Stelle zuerst den gefüllten Wasserkocher an. Schäle die Zwiebel und die Knoblauchzehe und hacke sie fein. Setze nun mit dem kochenden Wasser 450ml Gemüsebrühe an und stelle sie noch beiseite. Erhitze in einem großen Topf 1 EL Olivenöl bei mittlerer Hitzestufe und schwitze darin die Zwiebel und den Knoblauch für eine Minute an.",
				stepImage: "./R0042/1.webp"
			},
			{
				stepName: "Risottoreis garen",
				stepDescription:
					"Gib nun den Risottoreis hinzu und lass ihn ebenfalls für eine Minute anschwitzen. Achte währenddessen darauf, dass der Topf nicht zu heiß wird, sodass nichts in der Pfanne anbrät. Für ein intensiveres Aroma kann nun mit etwas Weißwein abgelöscht werden. Reduziere die Hitze auf eine der kleinsten Stufen und gib portionsweise von der Gemüsebrühe in den Topf. Lass den Risottoreis die beigefügte Flüssigkeit immer nahezu vollständig aufsaugen, bevor du portionsweise die restliche Brühe unterrührst. Lass den Reis nun für 20 Minuten garen, bis er weich ist. Rühre regelmäßig kräftig um. ",
				stepImage: "./R0042/2.webp"
			},
			{
				stepName: "Champignons, Birne, Schnittlauch schneiden ",
				stepDescription:
					"Schneide die Champignons in feine Streifen. Schneide den Schnittlauch in dünne Ringe. Halbiere die Birne, entferne die Kerne und schneide aus der ganzen Birne etwa 8 Spalten. ",
				stepImage: "./R0042/3.webp"
			},
			{
				stepName: "Bacon anbraten",
				stepDescription:
					"Gib 1 EL Pflanzenöl in eine große Pfanne. Gib die Baconscheiben mit hinein, während sie noch kalt ist. Lass die Pfanne auf mittlere Hitze erwärmen und lass dabei den Bacon langsam für 5 Minuten anbraten. Stelle ihn dann auf einem Teller beiseite. ",
				stepImage: "./R0042/4.webp"
			},
			{
				stepName: "Champignons anbraten",
				stepDescription:
					"Brate in der nun frei gewordenen Pfanne die Champignons für 3-4 Minuten an, damit sie etwas von dem Bacongeschmack aufnehmen. Würze mit einer Prise Salz und Pfeffer nach und rühre die Champignons nun in das Risotto unter.",
				stepImage: "./R0042/5.webp"
			},
			{
				stepName: "Birnen und Walnüsse karamellisieren, servieren",
				stepDescription:
					"Verwende nun eine neue Pfanne oder wische die Benutzte gut aus. Erwärme darin 1 gehäuften TL Butter bei mittlerer Hitzestufe. Gib die Birnenspalten und Walnüsse dazu und brate diese für 3 Minuten sanft an. Streue nun 1 TL Zucker darüber und lass diesen für 2-3 Minuten leicht karamellisieren. Serviere nun das Risotto auf einem Teller und lege die Baconscheiben, die karamellisierten Birnenspalten und die Walnüsse ansehnlich darüber. Bestreue zum Schluss das Gericht mit etwas Parmesan und dem Schnittlauch. ",
				stepImage: "./R0042/6.webp"
			}
		]
	},
	{
		id: "R0043",
		geflügel: false,
		rind: false,
		schwein: false,
		fisch: false,
		meeresfrüchte: false,
		milch: false,
		ei: false,
		vegetarisch: false,
		vegan: true,
		lowFat: false,
		LowCarb: false,
		italienisch: true,
		spanisch: true,
		indisch: true,
		japanisch: true,
		chinesisch: true,
		arabisch: true,
		senf: false,
		schwefeldioxid: false,
		sulphite: false,
		nüsse: true,
		gluten: true,
		sellerie: false,
		sesamsamen: false,
		sojabohnen: false,
		laktose: false,
		eierzeugnisse: false,
		sellerieerzeugnisse: false,
		hülsenfrüchte: true,
		titleImage: "./R0043/0.webp",
		name: "Veganer Burger mit Süßkartoffel-Pommes und Ofengemüse dazu Guacamole und Salsa-Dip",
		level: 3,
		punkte: 3,
		kochzeit: 40,
		supermarktpreis: 4.79,
		kalorien: 689,
		schärfegrad: 0,
		gerichtbeschreibung:
			"Lorem ipsum dolor sit amet, consectetur adipiscing elit. At gravida faucibus consequat nec, dictum quis enim lacus. Dignissim maecenas velit faucibus diam vitae elementum. Sed risus dui aliquam varius dignissim pharetra, hendrerit. Ullamcorper amet enim ipsum faucibus nibh.",
		fancies: [
			"Kurkuma",
			"Oregano",
			"Semmelbrösel",
			"Backpulver",
			"Sojamehl",
			"Haferflocken",
			"Sonnenblumenkerne",
			"Sesamsamen",
			"Chiasamen",
			"Kräuteröl"
		],
		einkaufsliste: [
			"Fleischtomate",
			"Zwiebel(weiß)",
			"Knoblauchzehe",
			"Paprika(rot)",
			"Fenchel",
			"Schalotte",
			"Karotte",
			"Zucchini",
			"Zitrone",
			"Avocado",
			"Petersilie",
			"Basilikum",
			"Schnittlauch",
			"Koriander",
			"Sojajoghurt",
			"Räuchertofu",
			"Dinkelvollkornmehl",
			"Süßkartoffeln"
		],
		zutatenZuhause: [ "Salz", "Pfeffer", "Olivenöl", "" ],
		kcl: 689,
		fett: 24,
		kohlenhydrate: 93,
		eiweiß: 23,
		utensilien: [
			"Pfanne(klein)",
			"Pfanne(mittelgroß)",
			"Auflaufform",
			"Küchenreibe",
			"Zitronenpresse",
			"Messbecher",
			"Schäler",
			"Pürierstab",
			"Backofen",
			"Backblech",
			"Backpapier",
			"Gitterrost",
			"Schüssel(groß)"
		],
		allergene: [ "Nüsse", "Gluten", "Hülsenfrüchte", "kann Spuren von anderen Allergenen enthalten" ],
		steps: [
			{
				stepName: "Burgerbrötchen kneten und backen",
				stepDescription:
					"Heize den Backofen auf 180°C Ober- und Unterhitze vor. Vermenge in einer großen Schüssel das Dinkelvollkornmehl mit dem Backpulver und gib den Sojajoghurt, das Kurkumagewürz, 1 Prise Salz und 1/2 EL Olivenöl dazu. Rühre nun in einer kleinen seperaten Schale das Sojamehl mit 2 EL Wasser an und gib dieses als Eiersatz dem Teig dazu. Knete alles gut durch und forme 2 Brötchen daraus. Streue die schwarzen Sesamsamen und Haferflocken nach Belieben darüber. Backe die Brötchen auf. Stell dir einen Wecker auf 15-20 Minuten. Hole die Brötchen nach der Backzeit aus dem Ofen und lasse sie abkühlen.",
				stepImage: "./R0043/1.webp"
			},
			{
				stepName: "Dips anrühren und kalt stellen",
				stepDescription:
					"In der Zwischenzeit können die beiden Dips gemacht werden. Wasche zunächst die Zitrone mit heißem Wasser ab. Reibe die Schale in eine kleine Schüssel ab. Presse danach die beiden Zitronenhälften aus und stelle den Zitronensaft beiseite. Für den Salsa-Dip schneidest du die Fleischtomate in sehr feine Würfel (4mm). Wasche und hacke die Kräuter. Schäle die Schalotte und 1 Knoblauchzehe und würfel beides so klein wie möglich. Mische nun in einer entsprechend großen Schüssel die geschnittene Fleischtomate, Schalotte, Knoblauchzehe mit den Kräutern und gib das Kräuteröl, 1 EL Zitronensaft und 1 TL Zitronenschale dazu. Würze mit einer Prise Salz und Pfeffer nach. Für den Guacamole-Dip zerdrückst du nun in einer weiteren Schale die Avocado. Gib 2-3 EL Zitronensaft und 1 TL Zitronenschale hinzu. Würze mit einer Prise Salz und Pfeffer nach. Stelle beide Dips zum Nachziehen kalt. ",
				stepImage: "./R0043/2.webp"
			},
			{
				stepName: "Ofengemüse schneiden und backen",
				stepDescription:
					"Schneide für das Ofengemüse 1/2 Zucchini in mundgerechte Würfel, die Paprika in 3x5cm große und den Fenchel in 3x5 cm große Stücke. Gib 3 EL Olivenöl in eine Auflaufform und lege das Gemüse hinein. Das Ofengemüse kann auch gerne mit Zutaten, die du zu Hause hast, variiert werden. Würfel eine Knoblauchzehe klein und verteile die Stückchen gleichmäßig. Würze mit 1 Prise Salz und Pfeffer. Träufel nun noch 3 EL Olivenöl darüber und lass das Ofengemüse nun für etwa 30 Minuten im Ofen auch bei 180°C Ober- und Unterhitze garen. Stell dir am Besten einen Wecker. Um am Ende einen extra saftigen Burger zusammenzustellen, kann 1/3 des gebackenen Ofengemüses all zusätzliche Burgersoße püriert werden. ",
				stepImage: "./R0043/3.webp"
			},
			{
				stepName: "Süßkartoffel-Pommes zubereiten und backen",
				stepDescription:
					"Bereite ein Backblech mit Backpapier vor. Schäle die Süßkartoffeln und schneide sie in etwa 1x7 cm große Streifen. Vermenge in einer großen Schüssel  Kartoffeln mit 4 EL Olivenöl und 1/2 EL Salz und verteile sie anschließend gleichmäßig auf dem Backblech. Backe sie im Ofen für 10 Minuten goldbraun, wende sie und lasse sie für weitere 10 Minuten von der anderen Seite backen. Stell dir am Besten einen Wecker, damit die Backzeit auf die des Ofengemüses angepasst werden kann. ",
				stepImage: "./R0043/4.webp"
			},
			{
				stepName: "Patties zubereiten",
				stepDescription:
					"Schäle die Karotte und reibe sie zusammen mit der halben Zucchini in eine Schüssel. Röste die Sonnenblumenkerne in einer Pfanne bei mittlerer Hitze ohne Zugabe von Ölen oder Fetten für 3-4 Minuten an. Gib sie mit in die Schüssel. Schäle die Zwiebel und würfel sie fein. Brate mit 1 EL Öl diese nun an und gib nach einer Minute den Schüsselinhalt hinzu. Würze mit dem Oregano und 1 Prise Salz und Pfeffer. Lass alles für 5 Minuten braten. In der Zwischenzeit kannst du in einer kleinen Schüssel die Chiasamen mit 4-5 EL Wasser mischen und zum Binden der Masse hinzufügen. Mische das soeben gebratene Gemüse mit den Semmelbröseln in einer Schüssel und knete alles gut durch. Forme aus der Masse die Burger-Patties und brate sie mit 2 EL Olivenöl goldbraun an. ",
				stepImage: "./R0043/5.webp"
			},
			{
				stepName: "Räuchertofu braten und Burger fertigstellen",
				stepDescription:
					"Schneide den Räuchertofu in dünne Scheiben und brate sie in der Pfanne kross an. Schneide nun die Burgerbrötchen auf, bestreiche sie mit etwas Guacamole-Dip, schichte nun etwas Ofengemüse darauf, lege einen Pattie darüber, träufel etwas von dem pürierten Ofengemüse darüber, lege eine Scheibe Räuchertofu darauf und bedecke den Burger mit der zweiten Brötchenhälfte (ggf. nochmals mit Guacamole bestrichen). Serviere zusammen mit den Süßkartoffel-Pommes, etwas Ofengemüse als seperate Beilage und der Salsa-Soße in einem kleinen Schälchen. ",
				stepImage: "./R0043/6.webp"
			}
		]
	},
	{
		id: "R0044",
		geflügel: false,
		rind: false,
		schwein: false,
		fisch: false,
		meeresfrüchte: false,
		milch: false,
		ei: false,
		vegetarisch: false,
		vegan: true,
		lowFat: false,
		LowCarb: true,
		italienisch: true,
		spanisch: true,
		indisch: true,
		japanisch: true,
		chinesisch: true,
		arabisch: true,
		senf: false,
		schwefeldioxid: false,
		sulphite: false,
		nüsse: false,
		gluten: false,
		sellerie: false,
		sesamsamen: false,
		sojabohnen: false,
		laktose: false,
		eierzeugnisse: false,
		sellerieerzeugnisse: false,
		hülsenfrüchte: false,
		titleImage: "./R0044/0.webp",
		name: "Apfel-Paprika-Suppe",
		level: 1,
		punkte: 3,
		kochzeit: 25,
		supermarktpreis: 1.45,
		kalorien: 229,
		schärfegrad: 0,
		gerichtbeschreibung:
			"Lorem ipsum dolor sit amet, consectetur adipiscing elit. At gravida faucibus consequat nec, dictum quis enim lacus. Dignissim maecenas velit faucibus diam vitae elementum. Sed risus dui aliquam varius dignissim pharetra, hendrerit. Ullamcorper amet enim ipsum faucibus nibh.",
		fancies: [ "Kürbiskerne", "Gemüsebrühgewürz" ],
		einkaufsliste: [ "Zwiebel(weiß)", "Paprika(rot)", "Apfel ", "Basilikum" ],
		zutatenZuhause: [ "Olivenöl" ],
		kcl: 229,
		fett: 13.62,
		kohlenhydrate: 24.55,
		eiweiß: 4.32,
		utensilien: [ "Pfanne(klein)", "Kochtopf(groß)", "Küchenreibe", "Schäler", "Pürierstab" ],
		allergene: [ "kann Spuren von anderen Allergenen enthalten" ],
		steps: [
			{
				stepName: "Paprika und Äpfel würfeln",
				stepDescription:
					"Wasche die Paprika, halbiere sie, entferne die Kerne und schneide sie in kleine Stücke. Schäle die Äpfel, viertel und entkerne und würfel sie.",
				stepImage: "./R0044/1.webp"
			},
			{
				stepName: "Zwiebel schneiden und braten",
				stepDescription:
					"Schäle die Zwiebel und würfel sie. Erhitze 1 EL Olivenöl in einem Top und schwitze sie für 4 Minuten bei mittlerer Hitze an.",
				stepImage: "./R0044/2.webp"
			},
			{
				stepName: "Paprika und Äpfel zugeben und ablöschen",
				stepDescription:
					"Gib die Paprika und Äpfel in den Topf mit den Zwiebeln hinzu und lass alles gemeinsam 5 Minuten braten. Setze in der Zwischenzeit 300ml Gemüsebrühe an. Lösche nach dem Anbraten mit der Brühe ab und lass alles für 15 Minuten köcheln.",
				stepImage: "./R0044/3.webp"
			},
			{
				stepName: "Kürbiskerne rösten",
				stepDescription:
					"Röste in einer kleinen Pfanne ohne Zugabe von Öl oder Fetten die Sonnenblumenkerne bei mittlerer Hitze für 5 Minuten an.",
				stepImage: "./R0044/4.webp"
			},
			{
				stepName: "Suppe pürieren",
				stepDescription: "Püriere die Suppe mit einem Pürierstab und schmecke mit Salz und Pfeffer ab.",
				stepImage: "./R0044/5.webp"
			},
			{
				stepName: "Apfelstreifen reiben und anrichten",
				stepDescription:
					"Reibe einen weiteren Apfel mit der Küchenreibe in dünne Streifen. Serviere die Suppe mit den Apfelstreifen, Kürbiskernen und etwas grob gehackten Basilikum. Nach Belieben kannst du auch ein wenig Olivenöl darüber träufeln.",
				stepImage: "./R0044/6.webp"
			}
		]
	},
	{
		id: "R0045",
		geflügel: false,
		rind: false,
		schwein: false,
		fisch: false,
		meeresfrüchte: false,
		milch: false,
		ei: false,
		vegetarisch: true,
		vegan: false,
		lowFat: false,
		LowCarb: false,
		italienisch: true,
		spanisch: true,
		indisch: true,
		japanisch: true,
		chinesisch: true,
		arabisch: true,
		senf: false,
		schwefeldioxid: false,
		sulphite: false,
		nüsse: false,
		gluten: false,
		sellerie: false,
		sesamsamen: false,
		sojabohnen: false,
		laktose: false,
		eierzeugnisse: true,
		sellerieerzeugnisse: false,
		hülsenfrüchte: false,
		titleImage: "./R0045/0.webp",
		name: "Pochiertes Ei mit Avocadomus auf geröstetem Brot",
		level: 1,
		punkte: 3,
		kochzeit: 8,
		supermarktpreis: 2.58,
		kalorien: 258,
		schärfegrad: 3,
		gerichtbeschreibung:
			"Lorem ipsum dolor sit amet, consectetur adipiscing elit. At gravida faucibus consequat nec, dictum quis enim lacus. Dignissim maecenas velit faucibus diam vitae elementum. Sed risus dui aliquam varius dignissim pharetra, hendrerit. Ullamcorper amet enim ipsum faucibus nibh.",
		fancies: [ "Chili-Gewürz" ],
		einkaufsliste: [ "Tomaten", "Schalotte", "Limette", "Avocado", "Koriander", "Eier", "Vollkornbrot" ],
		zutatenZuhause: [ "Salz", "Pfeffer", "Olivenöl" ],
		kcl: 258,
		fett: 13.9,
		kohlenhydrate: 23,
		eiweiß: 12.6,
		utensilien: [ "Kochtopf(klein)", "Küchenreibe", "Toaster" ],
		allergene: [ "Eier/Eierzeugnisse", "kann Spuren von anderen Allergenen enthalten" ],
		steps: [
			{
				stepName: "Hitzebeständiger Frischhaltefolie vorbereiten",
				stepDescription:
					"Breite zwei 30cm große Stücke hitzebeständiger Frischhaltefolie auf der Arbeitsfläche aus. Verteile jeweils in der Mitte 1 EL Olivenöl. Falls eine frische Chilischote zur Hand ist, kann die Hälfte davon fein gewürfelt werden. Verteile ansonsten jeweils die Hälfte des Chili-Gewürzes gleichmäßig über dem Olivenöl. Zerschlage nun vorsichtig pro Frischhaltefolie ein Ei in die Mitte. ",
				stepImage: "./R0045/1.webp"
			},
			{
				stepName: "Foliepäckchen verschließen",
				stepDescription:
					"Klappe die Seiten der Folie über dem Ei zusammen. Achte dabei darauf, die gesamte Luft herauszupressen. Verknote nun die Foliepäckchen und lege sie vorerst beiseite. ",
				stepImage: "./R0045/2.webp"
			},
			{
				stepName: "Tomaten blanchieren",
				stepDescription:
					"Bringe in einem Topf ausreichend Wasser bei mittlerer Hitzestufe zum köcheln. Entferne in der Zwischenzeit den Strunk der Tomaten. Gib sie nun für etwa 40 Sekunden in das köchelnde Wasser. Die Haut sollte nun beginnen sich langsam abzulösen. Nimm die Tomaten aus dem heißen Wasser und schrecke sie in kaltem Wasser ab. Häute und achtel sie und entferne das weiche Innere der Tomate.",
				stepImage: "./R0045/3.webp"
			},
			{
				stepName: "Tomaten würzen",
				stepDescription:
					"Gib die Tomaten mit 1 TL Olivenöl in eine Schüssel. Schäle die Zwiebel und raspel sie dazu. Presse eine Limettenhälfte aus und würze die Tomaten mit dem Limettensaft, 1 Prise Salz und Pfeffer nach.",
				stepImage: "./R0045/4.webp"
			},
			{
				stepName: "Eierpäckchen in das köchelnde Wasser geben",
				stepDescription:
					"Gib die Eier für 6-7 Minuten in das köchelnde Wasser. Verlängere die Kochzeit, je nach Konsistenzwunsch.",
				stepImage: "./R0045/5.webp"
			},
			{
				stepName: "Avocadomus herstellen, Brot belegen ",
				stepDescription:
					"Schäle die Avocado, entferne den Stein und zerdrücke das Fruchtfleisch. Presse die zweite Limettenhälfte aus und vermenge den Saft mit der Avocado. Toaste das Brot und streiche die Avocadocreme darauf. Serviere das Brot mit den Tomaten und belege es mit dem ausgepacktem Ei. Streue ein paar Korianderblätter als Garnitur darüber. ",
				stepImage: "./R0045/6.webp"
			}
		]
	}
];

export default database;
