import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import ReactImageFallback from "react-image-fallback";
import { useSelector, useDispatch } from "react-redux";

import {
	setGeflügel,
	setRind,
	setSchwein,
	setFisch,
	setMeeresfrüchte,
	setMilchEi,
	setVegan,
	setVegetarisch,
	setLowfat,
	setLowCarb,
	setItalienisch,
	setSpanisch,
	setIndisch,
	setJapanisch,
	setChinesisch,
	setArabisch,
	setAfrikanisch,
	setLaktose,
	setGluten,
	setNüsse,
	setSenf,
	initalizeList
} from "../store/slice";

import CheckboxOption from "../components/CheckboxOption";
import "../styles/filterspage.css";

const FiltersPage = () => {
	const geflügel = useSelector((state) => state.geflügel);
	const rind = useSelector((state) => state.rind);
	const schwein = useSelector((state) => state.schwein);
	const fisch = useSelector((state) => state.fisch);
	const meeresfrüchte = useSelector((state) => state.meeresfrüchte);
	const milch = useSelector((state) => state.milchEi);
	const vegan = useSelector((state) => state.vegan);
	const vegetarisch = useSelector((state) => state.vegetarisch);
	const lowFat = useSelector((state) => state.lowFat);
	const lowCarb = useSelector((state) => state.lowCarb);

	const italienisch = useSelector((state) => state.italienisch);
	const spanisch = useSelector((state) => state.spanisch);
	const indisch = useSelector((state) => state.indisch);
	const japanisch = useSelector((state) => state.japanisch);
	const chinesisch = useSelector((state) => state.chinesisch);
	const arabisch = useSelector((state) => state.arabisch);
	const afrikanisch = useSelector((state) => state.afrikanisch);

	const laktose = useSelector((state) => state.laktose);
	const gluten = useSelector((state) => state.gluten);
	const nüsse = useSelector((state) => state.nüsse);
	const senf = useSelector((state) => state.senf);

	const [ optionOpen, setOptionOpen ] = useState(0);
	const [ faq1, setFaq1 ] = useState(false);
	const [ faq2, setFaq2 ] = useState(false);
	const [ faq0, setFaq0 ] = useState(false);

	const dispatch = useDispatch();

	useEffect(
		() => {
			window.scrollTo(0, 0);
		},
		[ optionOpen ]
	);

	return (
		<div id="filter-page">
			<section id="options-section1" className="section">
				<ReactImageFallback
					className="bannerimg"
					fallbackImage={`/images/photooption${optionOpen}.jpg`}
					initialImage="images/spinner.svg"
					src={`/images/photooption${optionOpen}.webp`}
				/>
				<div className="three-dots-container">
					<p className={`dot ${optionOpen === 0 ? "dot-open" : ""}`} onClick={() => setOptionOpen(0)}>
						1
					</p>
					<svg
						className="arrow"
						width="60"
						height="8"
						viewBox="0 0 60 8"
						fill="none"
						xmlns="http://www.w3.org/2000/svg">
						<path
							d="M59.3535 4.35355C59.5488 4.15829 59.5488 3.84171 59.3535 3.64645L56.1716 0.464466C55.9763 0.269204 55.6597 0.269204 55.4645 0.464466C55.2692 0.659728 55.2692 0.976311 55.4645 1.17157L58.2929 4L55.4645 6.82843C55.2692 7.02369 55.2692 7.34027 55.4645 7.53553C55.6597 7.7308 55.9763 7.7308 56.1716 7.53553L59.3535 4.35355ZM0 4.5H59V3.5H0V4.5Z"
							fill="#8C8C8C"
						/>
					</svg>
					<p className={`dot ${optionOpen === 1 ? "dot-open" : ""}`} onClick={() => setOptionOpen(1)}>
						2
					</p>
					<svg
						className="arrow"
						width="60"
						height="8"
						viewBox="0 0 60 8"
						fill="none"
						xmlns="http://www.w3.org/2000/svg">
						<path
							d="M59.3535 4.35355C59.5488 4.15829 59.5488 3.84171 59.3535 3.64645L56.1716 0.464466C55.9763 0.269204 55.6597 0.269204 55.4645 0.464466C55.2692 0.659728 55.2692 0.976311 55.4645 1.17157L58.2929 4L55.4645 6.82843C55.2692 7.02369 55.2692 7.34027 55.4645 7.53553C55.6597 7.7308 55.9763 7.7308 56.1716 7.53553L59.3535 4.35355ZM0 4.5H59V3.5H0V4.5Z"
							fill="#8C8C8C"
						/>
					</svg>
					<p className={`dot ${optionOpen === 2 ? "dot-open" : ""}`} onClick={() => setOptionOpen(2)}>
						3
					</p>
				</div>
			</section>

			<section id="options-section2" className="section">
				{optionOpen === 0 && (
					<div>
						<p className="headingText">Wie ernährst du dich am liebsten?</p>
						<CheckboxOption
							selected={geflügel}
							onClick={() => dispatch(setGeflügel())}
							text="Geflügel"
						/>{" "}
						<br />
						<CheckboxOption selected={rind} onClick={() => dispatch(setRind())} text="Rind" /> <br />
						<CheckboxOption selected={schwein} onClick={() => dispatch(setSchwein())} text="Schwein" />{" "}
						<br />
						<CheckboxOption selected={fisch} onClick={() => dispatch(setFisch())} text="Fisch" /> <br />
						<CheckboxOption
							selected={meeresfrüchte}
							onClick={() => dispatch(setMeeresfrüchte())}
							text="Meeresfrüchte"
						/>
						<br />
						<CheckboxOption
							selected={milch}
							onClick={() => dispatch(setMilchEi())}
							text="Milch / Ei"
						/>{" "}
						<br />
						<CheckboxOption selected={vegan} onClick={() => dispatch(setVegan())} text="Vegan" /> <br />
						<CheckboxOption
							selected={vegetarisch}
							onClick={() => dispatch(setVegetarisch())}
							text="Vegetarisch"
						/>
						<br />
						<CheckboxOption selected={lowFat} onClick={() => dispatch(setLowfat())} text="Low-Fat" /> <br />
						<CheckboxOption
							selected={lowCarb}
							onClick={() => dispatch(setLowCarb())}
							text="Low-Carb"
						/>{" "}
						<br />
					</div>
				)}
				{optionOpen === 1 && (
					<div>
						<p className="headingText">Welche typische Landesküche magst du?</p>
						<CheckboxOption
							selected={italienisch}
							onClick={() => dispatch(setItalienisch())}
							text="Italienisch"
						/>
						<br />
						<CheckboxOption selected={spanisch} onClick={() => dispatch(setSpanisch())} text="Spanisch" />
						<br />
						<CheckboxOption selected={indisch} onClick={() => dispatch(setIndisch())} text="Indisch" />{" "}
						<br />
						<CheckboxOption
							selected={japanisch}
							onClick={() => dispatch(setJapanisch())}
							text="Japanisch"
						/>
						<br />
						<CheckboxOption
							selected={chinesisch}
							onClick={() => dispatch(setChinesisch())}
							text="Chinesisch"
						/>
						<br />
						<CheckboxOption selected={arabisch} onClick={() => dispatch(setArabisch())} text="Arabisch " />
						<br />
						<CheckboxOption
							selected={afrikanisch}
							onClick={() => dispatch(setAfrikanisch())}
							text="Afrikanisch"
						/>
						<br />
					</div>
				)}
				{optionOpen === 2 && (
					<div>
						<p className="headingText">Hast du Unverträglichkeiten?</p>
						<CheckboxOption selected={laktose} onClick={() => dispatch(setLaktose())} text="Laktose " />
						<br />
						<CheckboxOption selected={gluten} onClick={() => dispatch(setGluten())} text="Gluten " /> <br />
						<CheckboxOption selected={nüsse} onClick={() => dispatch(setNüsse())} text="Nüsse " /> <br />
						<CheckboxOption selected={senf} onClick={() => dispatch(setSenf())} text="Senf " /> <br />
					</div>
				)}
			</section>

			{optionOpen === 0 && (
				<div className="redButton-container">
					<button className="redButton" onClick={() => setOptionOpen(1)}>
						Landesküche auswählen
					</button>
				</div>
			)}
			{optionOpen === 1 && (
				<div className="redButton-container">
					<button className="redButton" onClick={() => setOptionOpen(2)}>
						Unverträglichkeiten auswählen
					</button>
				</div>
			)}
			{optionOpen === 2 && (
				<div className="redButton-container">
					<Link to="/recpies">
						<button className="redButton">Zu den Rezepten</button>
					</Link>
				</div>
			)}

			<section id="faqs-section" className="section">
				<p className="headingText">FAQ</p>
				<div>
					<div
						className="faq-button"
						onClick={() => {
							setFaq0(!faq0);
						}}>
						<svg
							className="faq-arrow"
							width="12"
							height="8"
							viewBox="0 0 12 8"
							fill="none"
							xmlns="http://www.w3.org/2000/svg">
							<path d="M1.41 0L6 4.58L10.59 0L12 1.41L6 7.41L0 1.41L1.41 0Z" fill="#333333" />
						</svg>
						<p className="faq-text">Was kostet FlavourFlip?</p>
						{faq0 && (
							<p className="paragraphText">
								<br />
								Flavour Flip ist ein wöchentliches Abonnement. Für jede Woche bekommst du eine Punktzahl
								deiner Wahl gutgeschrieben, zum Beispiel 10 Punkte für 7,99 Euro pro Woche. Der Versand
								ist immer kostenlos. Jedes Rezept hat eine Punktzahl zwischen 0 und 5 Punkten, so dass
								du deine Kosten immer unter Kontrolle hast.
							</p>
						)}
					</div>
				</div>
				<br />
				<div>
					<div
						className="faq-button"
						onClick={() => {
							setFaq1(!faq1);
						}}>
						<svg
							className="faq-arrow"
							width="12"
							height="8"
							viewBox="0 0 12 8"
							fill="none"
							xmlns="http://www.w3.org/2000/svg">
							<path d="M1.41 0L6 4.58L10.59 0L12 1.41L6 7.41L0 1.41L1.41 0Z" fill="#333333" />
						</svg>
						<p className="faq-text">Wie groß sind die Portionen?</p>
						{faq1 && (
							<p className="paragraphText">
								<br />
								Flavour Flip ist ein wöchentliches Abonnement. Für jede Woche bekommst du eine Punktzahl
								deiner Wahl gutgeschrieben, zum Beispiel 10 Punkte für 7,99 Euro pro Woche. Der Versand
								ist immer kostenlos. Jedes Rezept hat eine Punktzahl zwischen 0 und 5 Punkten, so dass
								du deine Kosten immer unter Kontrolle hast.
							</p>
						)}
					</div>
				</div>
				<br />
				<div>
					<div
						className="faq-button"
						onClick={() => {
							setFaq2(!faq2);
						}}>
						<svg
							className="faq-arrow"
							width="12"
							height="8"
							viewBox="0 0 12 8"
							fill="none"
							xmlns="http://www.w3.org/2000/svg">
							<path d="M1.41 0L6 4.58L10.59 0L12 1.41L6 7.41L0 1.41L1.41 0Z" fill="#333333" />
						</svg>
						<p className="faq-text">Sind alle Produkte Bio?</p>
						{faq2 && (
							<p className="paragraphText">
								<br />
								Wir verwenden Bioprodukte wo immer es geht. Einige Produkte sind leider noch nicht als
								Bioprodukt verfügbar, das weisen wir dann aber aus.
							</p>
						)}
					</div>
				</div>
			</section>
		</div>
	);
};

export default FiltersPage;
