import React, { useEffect, useState, Fragment } from "react";
import { useSelector } from "react-redux";
import { useLocation, Link } from "react-router-dom";
import emailjs from "emailjs-com";
const publicIp = require("public-ip");

const Header = () => {
	const cartValue = useSelector((state) => state.cartValue);
	const rightSwipe = useSelector((state) => state.likedRecepies).length;
	const location = useLocation().pathname;
	const [ ip, setIp ] = useState(false);

	const temp = (async () => {
		return await publicIp.v4();
	})();

	temp.then((a) => setIp(a)).catch(err=> console.log(err))

	var templateParams = {
		cartValue: cartValue,
		rightSwipe: rightSwipe,
		time: Date().toLocaleString(),
		ip: ip
	};

	const sendEmail = () => {
		return;
		emailjs.send("service_0b8a3o6", "template_ub9n957", templateParams, "user_vgqLqn5Zve6ha4D3uowUj").then(
			function(response) {
				console.log("SUCCESS!", response.status, response.text);
			},
			function(error) {
				console.log("FAILED...", error);
			}
		);
	};


	return (
		<header>
			<div className="header-container">
				{location == "/recpies" && (
					<Link to="/email"
						// onClick={sendEmail}
					>
						<img className="shopping-cart" src="/images/shopping-cart.svg" alt="shopping cart" />
						<p className="number">{cartValue}</p>
					</Link>
				)}
				{location == "/recpies" && (
					<Fragment>
						<Link to="/filters">
							<img className="cogwheel" src="/images/cogwheel.svg" alt="to filters" />

							<img src="./images/logo-alt.svg" className="logo logo-alt" />
						</Link>
					</Fragment>
				)}
				{location !== "/recpies" && (
					<Link to="/">
						{" "}
						<img src="./images/logo.svg" className="logo" />
					</Link>
				)}
			</div>
		</header>
	);
};

export default Header;
