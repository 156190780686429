import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
	return (
		<footer id="footer" className="section">
			<Link to="/about">
				<button>About</button>
			</Link>
			<Link to="/impressum">
				<button>Impressum</button>
			</Link>
			<Link to="/datenschutz">
				<button>Datenschutz</button>
			</Link>
		</footer>
	);
};

export default Footer;
