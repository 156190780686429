const ads = [
	{
		id: "ad1",
		titleImage: "./Ad1/0.webp",
		name: "Meisenheimer Hof",
		kochzeit: 8,
		supermarktpreis: 5,
		gerichtbeschreibung:
			"Lorem ipsum dolor sit amet, consectetur adipiscing elit. At gravida faucibus consequat nec, dictum quis enim lacus. Dignissim maecenas velit faucibus diam vitae elementum. Sed risus dui aliquam varius dignissim pharetra, hendrerit. Ullamcorper amet enim ipsum faucibus nibh.",
		steps: [
			{
				stepName: "Blumenkohl schneiden",
				stepDescription:
					"Den Backofen auf 220°C Umluft (240°C Ober-/Unterhitze) vorheizen. Den Blumenkohl in 2–3cm große Röschen schneiden.",
				stepImage: "./Ad1/1.webp"
			},
			{
				stepName: "Blumenkohl rösten",
				stepDescription:
					"Die Blumenkohlröschen mit 1EL Olivenöl, der 1/2 des Kreuzkümmels oder mehr nach Geschmack sowie 1 Prise Salz und Pfeffer vermengen und auf einem mit Backpapier belegten Backblech 15–20Min. im Ofen goldbraun rösten. Nach der Hälfte der Zeit einmal wenden.",
				stepImage: "./Ad1/2.webp"
			},
			{
				stepName: "Zwiebeln einweichen",
				stepDescription:
					"Die Zwiebel schälen, halbieren und in feine Streifen schneiden. Mit den Rosinen in eine kleine Schüssel geben und in ausreichend lauwarmem Wasser einweichen.",
				stepImage: "./Ad1/3.webp"
			},
			{
				stepName: "Fleisch vorbereiten",
				stepDescription:
					"Die Pistazien fein hacken. Den Senf mit 1–2EL Wasser verrühren. Das Fleisch trocken tupfen und längs in 2 dünne Schnitzel schneiden. Erst mit Salz und Pfeffer würzen, dann mit dem Senf bestreichen und mit den Pistazien panieren. Das Fleisch während der letzten 6–7Min. Garzeit mit zum Blumenkohl in den Ofen geben.",
				stepImage: "./Ad1/4.webp"
			},
			{
				stepName: "Salat mischen",
				stepDescription:
					"Die eingeweichten Zwiebeln und Rosinen in ein Sieb abgießen. Die Zitronenschale abreiben, dann die Zitrone halbieren und auspressen. Aus 2EL Olivenöl, 2EL Zitronensaft, 1TL Zitronenabrieb sowie Salz und Pfeffer ein Dressing anrühren und mit dem Blumenkohl, den Zwiebeln, den Rosinen und dem Rucola in einer großen Schüssel vermengen.",
				stepImage: "./Ad1/5.webp"
			},
			{
				stepName: "Anrichten und servieren ",
				stepDescription: "Das Pistazien-Hähnchen mit dem Salat anrichten und servieren.",
				stepImage: "./Ad1/6.webp"
			}
		]
	},
	{
		id: "ad2",
		titleImage: "./Ad2/0.webp",
		name: "Töpfe von Fissler",
		kochzeit: 8,
		supermarktpreis: 5,
		gerichtbeschreibung:
			"Lorem ipsum dolor sit amet, consectetur adipiscing elit. At gravida faucibus consequat nec, dictum quis enim lacus. Dignissim maecenas velit faucibus diam vitae elementum. Sed risus dui aliquam varius dignissim pharetra, hendrerit. Ullamcorper amet enim ipsum faucibus nibh.",
		steps: [
			{
				stepName: "Blumenkohl schneiden",
				stepDescription:
					"Den Backofen auf 220°C Umluft (240°C Ober-/Unterhitze) vorheizen. Den Blumenkohl in 2–3cm große Röschen schneiden.",
				stepImage: "./Ad2/1.webp"
			},
			{
				stepName: "Blumenkohl rösten",
				stepDescription:
					"Die Blumenkohlröschen mit 1EL Olivenöl, der 1/2 des Kreuzkümmels oder mehr nach Geschmack sowie 1 Prise Salz und Pfeffer vermengen und auf einem mit Backpapier belegten Backblech 15–20Min. im Ofen goldbraun rösten. Nach der Hälfte der Zeit einmal wenden.",
				stepImage: "./Ad2/2.webp"
			},
			{
				stepName: "Zwiebeln einweichen",
				stepDescription:
					"Die Zwiebel schälen, halbieren und in feine Streifen schneiden. Mit den Rosinen in eine kleine Schüssel geben und in ausreichend lauwarmem Wasser einweichen.",
				stepImage: "./Ad2/3.webp"
			},
			{
				stepName: "Fleisch vorbereiten",
				stepDescription:
					"Die Pistazien fein hacken. Den Senf mit 1–2EL Wasser verrühren. Das Fleisch trocken tupfen und längs in 2 dünne Schnitzel schneiden. Erst mit Salz und Pfeffer würzen, dann mit dem Senf bestreichen und mit den Pistazien panieren. Das Fleisch während der letzten 6–7Min. Garzeit mit zum Blumenkohl in den Ofen geben.",
				stepImage: "./Ad2/4.webp"
			},
			{
				stepName: "Salat mischen",
				stepDescription:
					"Die eingeweichten Zwiebeln und Rosinen in ein Sieb abgießen. Die Zitronenschale abreiben, dann die Zitrone halbieren und auspressen. Aus 2EL Olivenöl, 2EL Zitronensaft, 1TL Zitronenabrieb sowie Salz und Pfeffer ein Dressing anrühren und mit dem Blumenkohl, den Zwiebeln, den Rosinen und dem Rucola in einer großen Schüssel vermengen.",
				stepImage: "./Ad2/5.webp"
			},
			{
				stepName: "Anrichten und servieren ",
				stepDescription: "Das Pistazien-Hähnchen mit dem Salat anrichten und servieren.",
				stepImage: "./Ad2/6.webp"
			}
		]
	}
];

export default ads;
