import React from "react";

const RecepieEquipment = ({ recepie }) => {
	return (
		<ul>
			<p className="list-heading">Utensilien:</p>
			{recepie.utensilien.map((utensil, key) => <li key={key}>{utensil}</li>)}
		</ul>
	);
};

export default RecepieEquipment;
