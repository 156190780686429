import React from "react";

const ImpressumPage = () => {
	return (
		<div>
			<div className="datapage" id="impressum">
				<h1>Impressum</h1>
				<h2>Angaben gem&auml;&szlig; &sect; 5 TMG</h2>
				<h2>Allgemeine Hinweise</h2>
				<p>
					Symbiopreneur GmbH<br />
					Beethovenallee 24<br />
					61130 Nidderau
					<br />
					<br />
					Handelsregister: HRB 97672<br />
					Registergericht: Amtsgericht Hanau
					<br />
					<br />
					<strong>Vertreten durch:</strong>
					<br />
					Martin Voigt
				</p>
				<h2>Kontakt</h2>
				<p>
					<u>
						Telefon: +49 (0) 123 44 55 66<br />
						Telefax: +49 (0) 123 44 55 99<br />
						E-Mail: martin.voigt@symbiopreneur.com
					</u>
				</p>
				<h2>Umsatzsteuer-ID</h2>
				<p>
					Umsatzsteuer-Identifikationsnummer gem&auml;&szlig; &sect; 27 a Umsatzsteuergesetz:<br />
					DE999999999
				</p>
				<h2>EU-Streitschlichtung</h2>
				<p>
					Die Europ&auml;ische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit:
					<br />
					<a href="https://ec.europa.eu/consumers/odr" target="_blank" rel="noopener noreferrer">
						https://ec.europa.eu/consumers/odr
					</a>.<br /> Unsere E-Mail-Adresse finden Sie oben im Impressum.
				</p>
				<h2>Verbraucher&shy;streit&shy;beilegung/ Universal&shy;schlichtungs&shy;stelle</h2>
				<p>
					Wir nehmen an einem Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teil.
					Zust&auml;ndig ist die Universalschlichtungsstelle des Zentrums f&uuml;r Schlichtung e.V.,
					Stra&szlig;burger Stra&szlig;e 8, 77694 Kehl am Rhein (<a
						href="https://www.verbraucher-schlichter.de"
						rel="noopener noreferrer"
						target="_blank">
						https://www.verbraucher-schlichter.de
					</a>).
				</p>
				<h3>Haftung f&uuml;r Inhalte</h3>
				<p>
					Als Diensteanbieter sind wir gem&auml;&szlig; &sect; 7 Abs.1 TMG f&uuml;r eigene Inhalte auf diesen
					Seiten nach den allgemeinen Gesetzen verantwortlich. Nach &sect;&sect; 8 bis 10 TMG sind wir als
					Diensteanbieter jedoch nicht verpflichtet, &uuml;bermittelte oder gespeicherte fremde Informationen
					zu &uuml;berwachen oder nach Umst&auml;nden zu forschen, die auf eine rechtswidrige T&auml;tigkeit
					hinweisen.
					<br />
					Verpflichtungen zur Entfernung oder Sperrung der Nutzung von Informationen nach den allgemeinen
					Gesetzen bleiben hiervon unber&uuml;hrt. Eine diesbez&uuml;gliche Haftung ist jedoch erst ab dem
					Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung m&ouml;glich. Bei Bekanntwerden von
					entsprechenden Rechtsverletzungen werden wir diese Inhalte umgehend entfernen.
				</p>
				<h3>Haftung f&uuml;r Links</h3>
				<p>
					Unser Angebot enth&auml;lt Links zu externen Websites Dritter, auf deren Inhalte wir keinen Einfluss
					haben. Deshalb k&ouml;nnen wir f&uuml;r diese fremden Inhalte auch keine Gew&auml;hr
					&uuml;bernehmen. F&uuml;r die Inhalte der verlinkten Seiten ist stets der jeweilige Anbieter oder
					Betreiber der Seiten verantwortlich. Die verlinkten Seiten wurden zum Zeitpunkt der Verlinkung auf
					m&ouml;gliche Rechtsverst&ouml;&szlig;e &uuml;berpr&uuml;ft. Rechtswidrige Inhalte waren zum
					Zeitpunkt der Verlinkung nicht erkennbar.
					<br />
					Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne konkrete Anhaltspunkte
					einer Rechtsverletzung nicht zumutbar. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige
					Links umgehend entfernen.
				</p>
				<h3>Urheberrecht</h3>
				<p>
					Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen dem
					deutschen Urheberrecht. Die Vervielf&auml;ltigung, Bearbeitung, Verbreitung und jede Art der
					Verwertung au&szlig;erhalb der Grenzen des Urheberrechtes bed&uuml;rfen der schriftlichen Zustimmung
					des jeweiligen Autors bzw. Erstellers. Downloads und Kopien dieser Seite sind nur f&uuml;r den
					privaten, nicht kommerziellen Gebrauch gestattet.
					<br />
					Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt wurden, werden die Urheberrechte
					Dritter beachtet. Insbesondere werden Inhalte Dritter als solche gekennzeichnet. Sollten Sie
					trotzdem auf eine Urheberrechtsverletzung aufmerksam werden, bitten wir um einen entsprechenden
					Hinweis. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Inhalte umgehend entfernen.
					<br />
					<br />
					Quelle: <a href="https://www.e-recht24.de">eRecht24</a>
				</p>
			</div>
		</div>
	);
};

export default ImpressumPage;
