import React from "react";
import { Link } from "react-router-dom";
import ReactImageFallback from "react-image-fallback";

import "../styles/homepage.css";
const HomePage = () => {
	return (
		<div>
			<section id="section1" className="section">
				<ReactImageFallback
					fallbackImage="/images/photo-1.jpg"
					initialImage="images/spinner.svg"
					className="bannerimg"
					src="/images/photo-1.webp"
					alt="Banner"
				/>
				<p className="headingText">Dein wöchentlicher Kochbrief plus frische lokale Zutaten</p>
				<p className="paragraphText">
					<span>Schneller geliefert oder selber eingekauft</span>
					<span>
						<strong>Jeden Tag das perfekte Rezept, einfach ausgesucht.</strong>
					</span>
					<span>Regional und Saisonal angepasst, für ein gesünderes Ich und ein nachhaltiges Leben.</span>
				</p>
			</section>

			<section id="section2" className="section">
				<div className="video-container">
					<ReactImageFallback
						fallbackImage="/images/video.png"
						initialImage="images/spinner.svg"
						className="bannerimg"
						src="/images/video.webp"
						alt="Banner"
					/>
					<div className="play-button-container">
						<img className="play-button" src="/images/play-button.svg" alt="Play Button" />
					</div>
				</div>

				<br />
				<p className="headingText">
					Einfach Rezepte nach deinem Bauchgefühl auswählen denn Liebe geht durch den Magen.
				</p>
			</section>

			<section id="section3" className="section">
				<ReactImageFallback
					fallbackImage="./images/slider-1.png"
					initialImage="images/spinner.svg"
					src="./images/slider-1.webp"
					id="slider1"
				/>
				<ReactImageFallback
					fallbackImage="./images/slider-2.png"
					initialImage="images/spinner.svg"
					src="./images/slider-2.webp"
					id="slider2"
				/>
				<ReactImageFallback
					fallbackImage="./images/slider-3.png"
					initialImage="images/spinner.svg"
					src="./images/slider-3.webp"
					id="slider3"
				/>
			</section>

			<section id="section4" className="section">
				<p className="headingText">
					Wir haben dich verstanden und bieten dir die größte Abwechslung an Rezepten an
				</p>
				<p className="paragraphText">
					<span>Wähle aus auf was du diese Woche Lust hast,</span>
					<span>
						<strong>egal ob vegetarisch, vegan, low-fat, low-gluten, low-carb oder einfach so.</strong>
					</span>
				</p>
			</section>

			<section id="section5" className="section">
				<ReactImageFallback
					fallbackImage="/images/photo-3.jpg"
					initialImage="images/spinner.svg"
					className="bannerimg"
					src="/images/photo-3.webp"
					alt="Food Photo"
				/>
				<p className="headingText">Wir schicken dir die leckeren Fancies in deinen Briefkasten</p>
				<p className="paragraphText">
					<span>Genau passend portioniert, so nachhaltig wie möglich in Versand und Verpackung.</span>
				</p>
			</section>

			<section id="section6" className="section">
				<ReactImageFallback
					fallbackImage="/images/photo-4.jpg"
					initialImage="images/spinner.svg"
					className="bannerimg"
					src="/images/photo-4.webp"
					alt="Food Photo"
				/>
				<p className="headingText">Frisches Gemüse und das Fleisch deiner Wahl</p>
				<p className="paragraphText">
					<span>Lass dich lokal beliefern oder kaufe selber ein, wenn du magst auch Bio.</span>
					<span>
						Unsere Produkte sind immer Bio*. Dank einem einfachem Einkaufszettel ohne teuren SchnickSchnack
						ist das auch schnell und einfach erledigt.
					</span>
				</p>
			</section>

			<section id="section7" className="section">
				<ReactImageFallback
					fallbackImage="/images/photo-5.jpg"
					initialImage="images/spinner.svg"
					className="bannerimg"
					src="/images/photo-5.webp"
					alt="Food Photo"
				/>
				<p className="headingText">So einfach wie eine Post bekommen</p>
				<p className="paragraphText">
					<span>Du musst zum Empfang nicht daheim sein.</span>
					<span>
						<strong>
							Wir schicken dir unsere tollen Gewürze und weitere einfallsreiche Zutaten in einem gut
							verpackten Maxibrief in deinen Briefkasten
						</strong>
					</span>
				</p>
			</section>

			<section id="section8" className="section">
				<ReactImageFallback
					fallbackImage="/images/photo-email.jpg"
					initialImage="images/spinner.svg"
					className="bannerimg"
					src="/images/photo-6.webp"
					alt="Food Photo"
				/>
				<p className="headingText">Du hast mal andere Pläne?</p>
				<p className="paragraphText">
					<span>Kein Problem, setzt einfach aus und verwöhn dich die nächsten Woche umso mehr.</span>
				</p>
			</section>
			<div className="redButton-container">
				<Link to="/filters">
					<button className="redButton">Jetzt starten</button>
				</Link>
			</div>
		</div>
	);
};

export default HomePage;
