import React from "react";

const RecepieIngredients = ({ recepie }) => {
	return (
		<div>
			<ul>
				<p className="list-heading">Zutaten, die wir dir zuschicken:</p>
				{recepie.fancies.map((ingredient, i) => <li key={i}>{ingredient} </li>)}
			</ul>
			<ul>
				<p className="list-heading">Einkaufsliste</p>
				{recepie.einkaufsliste.map((ingredient, i) => <li key={i}>{ingredient} </li>)}
			</ul>
			<ul>
				<p className="list-heading">Zutaten, die du zuhause haben solltest:</p>
				{recepie.zutatenZuhause.map((ingredient, i) => <li key={i}>{ingredient} </li>)}
			</ul>
		</div>
	);
};

export default RecepieIngredients;
