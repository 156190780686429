import React, { useState, useEffect, Fragment } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, Link } from "react-router-dom";
import emailjs from "emailjs-com";

import { setCartValue, addToDisliked, addToLiked, removeFromFilteredList, initalizeList } from "../store/slice";
import "../styles/recepiepage.css";
import RecepieDescription from "./RecepieDescription";

const RecepiePage = () => {
	const publicIp = require("public-ip");
	const dispatch = useDispatch();
	let history = useHistory();
	const recepieList = useSelector((state) => state.filteredRecepies);
	const cartValue = useSelector((state) => state.cartValue);
	const rightSwipe = useSelector((state) => state.likedRecepies).length;
	const [ ip, setIp ] = useState(false);
	const temp = (async () => {
		return await publicIp.v4();
	})();
	temp.then((a) => setIp(a)).catch(err=> console.log(err))

	var templateParams = {
		cartValue: cartValue,
		rightSwipe: rightSwipe,
		time: Date().toLocaleString(),
		ip: ip
	};

	const sendEmail = () => {
		emailjs.send("service_0b8a3o6", "template_ub9n957", templateParams, "user_vgqLqn5Zve6ha4D3uowUj").then(
			function(response) {
				console.log("SUCCESS!", response.status, response.text);
			},
			function(error) {
				console.log("FAILED...", error);
			}
		);
	};

	const swiped = (dir, id) => {
		dispatch(removeFromFilteredList());
		if (id == "ad1" || id == "ad2") return;
		if (dir === "right") {
			dispatch(addToLiked(id));
			dispatch(setCartValue());
		}
		else if (dir === "left") {
			dispatch(addToDisliked(id))
		};
	};

	useEffect(
		() => {
			if (recepieList.length <= 1) {
				//   sendEmail();
				history.push("/email");
			}
		},
		[ recepieList, swiped ]
	);

	useEffect(() => {
		dispatch(initalizeList())
	}, [])


	return (
		<RecepieDescription
			key={recepieList[0]}
			recepie={recepieList[0]}
			liked={() => swiped("right", recepieList[0])}
			disliked={() => swiped("left", recepieList[0])}
		/>
	);
};

export default RecepiePage;
