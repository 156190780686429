import React from "react";

const AboutPage = () => {
	return (
		<div>
			<div className="datapage" id="about">
				<h1>Enter data here</h1>
			</div>
		</div>
	);
};

export default AboutPage;
