import React, { useState, Fragment, useEffect } from "react";
import ReactImageFallback from "react-image-fallback";

import RecepieIngredients from "../components/RecepieIngredients";
import RecepieNutritionalValue from "../components/RecepieNutritionValue";
import RecepieEquipment from "../components/RecepieEquipment";
import RecepieAllergens from "../components/RecepieAllergens";

const RecepieDescription = (props) => {
	const [ optionOpen, setOptionOpen ] = useState(0);
	const recepie = props.recepie;
	const dislike = document.getElementById("dislike");
	const like = document.getElementById("like");

	const tapped = (dir) => {
		if (dir === 0) {
			dislike.classList.add("big");
			setTimeout(() => {
				dislike.classList.remove("big");
				props.disliked();
			}, 250);
		} else if (dir === 1) {
			like.classList.add("big");
			setTimeout(() => {
				like.classList.remove("big");
				props.liked();
			}, 250);
		}
	};

	return (
		<div className="recepie-card" key={recepie} id={recepie}>
			<section id="recepie-section1" className="section">
				<div className="bannerimg-container">
					<ReactImageFallback
						class="titleImg"
						fallbackImage={recepie.titleImage.substring(0, recepie.titleImage.length - 4).concat("jpg")}
						initialImage="images/spinner.svg"
						src={recepie.titleImage}
					/>
					{recepie.fancies && <p id="level-tag">Level: {recepie.level}</p>}
					<p id="punkte-tag">{recepie.punkte} Punkte</p>
					{recepie.fancies && <p id="koch-tag">Kochzeit: {recepie.kochzeit} min</p>}
					<p id="super-tag">Supermarktpreis: {recepie.supermarktpreis}€</p>
				</div>
				<div className="title-box">
					<div
						 key={recepie} 
						className="icon"
						onClick={() => {
							tapped(0);
						}}>
						<img id="dislike" src="/images/x.svg" />
					</div>{" "}
					<p className="headingText">{recepie.name}</p>
					<div
						className="icon"
						onClick={() => {
							tapped(1);
						}}>
						<img id="like" src="/images/heart.svg" />
					</div>
				</div>
			</section>
			{!recepie.fancies && (
				<p style={{ margin: "30px auto" }} className="paragraphText">
					{recepie.gerichtbeschreibung}
				</p>
			)}
			{recepie.fancies && (
				<Fragment>
					<section id="recepie-section2" className="section">
						<p className="paragraphText">{recepie.gerichtbeschreibung}</p>
						<p className="headingText">Hardfacts</p>
						<p className="paragraphText">
							Kalorien: {recepie.kalorien} Kalorien pro Portion Schärfegrad: {recepie.schärfegrad}
						</p>
					</section>

					<div className="height100">
						<section id="recepie-section3" className="section">
							<img className="sidebar" src={`./images/sidebar-${optionOpen}.svg`} />
							<img className="topbar" src={`./images/topbar-${optionOpen}.svg`} />
							<img className={`anchor-${optionOpen}`} src="./images/anchor.svg" />
							<table id="selector-table">
								<tr>
									<td onClick={() => setOptionOpen(0)}>Zutaten</td>
									<td onClick={() => setOptionOpen(1)}>Nährwert</td>
									<td onClick={() => setOptionOpen(2)}>Equipment</td>
									<td onClick={() => setOptionOpen(3)}>Allergene</td>
								</tr>
							</table>
						</section>
						<div className="container-center">
							{optionOpen === 0 && <RecepieIngredients recepie={recepie} />}
							{optionOpen === 1 && <RecepieNutritionalValue recepie={recepie} />}
							{optionOpen === 2 && <RecepieEquipment recepie={recepie} />}
							{optionOpen === 3 && <RecepieAllergens recepie={recepie} />}
						</div>
					</div>
				</Fragment>
			)}
			<section id="recepie-section4" className="section">
				{recepie.fancies && <h1 className="headingText">Step-by-Step Anleitung:</h1>}
				{recepie.steps.map((step, i) => {
					const fallbackSrc = step.stepImage.substring(0, step.stepImage.length - 4).concat("jpg");
					const src = step.stepImage.concat;
					return (
						<div key={i}>
							<ReactImageFallback
								fallbackImage={fallbackSrc}
								initialImage="images/spinner.svg"
								className="bannerimg-recepie"
								src={step.stepImage}
							/>
							<span>
								{i + 1}
								<>. </>
								{step.stepName}
							</span>
							<p className="paragraphText">{step.stepDescription}</p>
						</div>
					);
				})}
			</section>
		</div>
	);
};

export default RecepieDescription;
