import React from "react";

const CheckboxOption = ({ text, selected, onClick }) => {
	return (
		<div onClick={onClick}>
			<div className="options-button">
				{!selected ? <div className="options-checkbox"/> : <div className="options-checkbox-selected" style={{backgroundImage: "url(/images/tick.svg)"}}  />}

				{!selected ? <p className="options-text">{text}</p> : <p className="options-text-selected">{text}</p>}
			</div>
		</div>
	);
};

export default CheckboxOption;
