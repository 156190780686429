import React, { Fragment } from "react";
import ReactImageFallback from "react-image-fallback";
import Mailchimp from "react-mailchimp-form";

import "../styles/emailpage.css";

const EmailPage = () => {
	return (
		<Fragment>
			<section id="emailsection1" className="section">
				<ReactImageFallback
					fallbackImage="/images/photo-email.jpg"
					initialImage="images/spinner.svg"
					className="bannerimg"
					src="/images/photo-email.webp"
					alt="Banner"
				/>
				<p className="headingText">Danke für dein Interesse an Flavour Flip!</p>
				<p className="paragraphText">
					Flavour Flip ist noch nicht auf dem Markt.
					<span>
						<strong>
							Trage deiner <i>E-Mail Adresse</i> und sichere dir tolle Willkommensangebote:
						</strong>
					</span>
				</p>
			</section>
			<section id="emailsection2" className="section">
				<p>E-Mail Adresse:</p>
			<Mailchimp
				action="https://flavourflip.us7.list-manage.com/subscribe/post?u=7a9bff4a9a375ec21d013c624&amp;id=812a99e467"
				fields={[
					{
						name: "EMAIL",
						placeholder: "",
						type: "email",
						required: true
					}
					  
				]}className='email'
			/>

		
				
				{/* <input type="text" className="email-input" /> */}
				{/* <button className="emailButton">Rabatte sichern!</button> */}
			</section>
		</Fragment>
	);
};

export default EmailPage;
