import React from "react";

const RecepieNutritionalValue = ({ recepie }) => {
	return (
		<ul>
			<p className="list-heading">Nährwertangaben pro Portion</p>
			<table id="info-table">
				<tr>
					<td>Energie in kcal</td>
					<td>{recepie.kcl}</td>
				</tr>
				<tr>
					<td>Fett in g</td>
					<td>{recepie.fett}</td>
				</tr>
				<tr>
					<td>Kohlenhydrate in g</td>
					<td>{recepie.kohlenhydrate}</td>
				</tr>
				<tr>
					<td>Eiweiß in g</td>
					<td>{recepie.eiweiß}</td>
				</tr>
			</table>
		</ul>
	);
};

export default RecepieNutritionalValue;
