import React from "react";

const RecepieAllergens = ({ recepie }) => {
	return (
		<ul>
			<p className="list-heading">Allergene</p>
			{recepie.allergene.map((allergen, i) => <li key={i}>{allergen}</li>)}
		</ul>
	);
};

export default RecepieAllergens;
