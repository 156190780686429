import { createSlice } from "@reduxjs/toolkit";
import database from "../database/database.js";
import ads from "../database/ads.js";

const slice = createSlice({
	name: "slice",
	initialState: {
		geflügel: true,
		rind: true,
		schwein: true,
		fisch: true,
		meeresfrüchte: true,
		milchEi: true,
		vegan: true,
		vegetarisch: true,
		lowFat: true,
		lowCarb: true,
		italienisch: true,
		spanisch: true,
		indisch: true,
		japanisch: true,
		chinesisch: true,
		arabisch: true,
		afrikanisch: true,
		laktose: false,
		gluten: false,
		nüsse: false,
		senf: false,
		filteredRecepies: database,
		likedRecepies: [],
		dislikedRecepies: [],
		cartValue: 10,
		ads: ads
	},
	reducers: {
		setGeflügel: (state) => {
			state.geflügel = !state.geflügel;
		},
		setRind: (state) => {
			state.rind = !state.rind;
		},
		setSchwein: (state) => {
			state.schwein = !state.schwein;
		},
		setFisch: (state) => {
			state.fisch = !state.fisch;
		},
		setMeeresfrüchte: (state) => {
			state.meeresfrüchte = !state.meeresfrüchte;
		},
		setMilchEi: (state) => {
			state.milchEi = !state.milchEi;
		},
		setVegan: (state) => {
			state.vegan = !state.vegan;
		},
		setVegetarisch: (state) => {
			state.vegetarisch = !state.vegetarisch;
		},
		setLowfat: (state) => {
			state.lowFat = !state.lowFat;
		},
		setLowCarb: (state) => {
			state.lowCarb = !state.lowCarb;
		},
		setItalienisch: (state) => {
			state.italienisch = !state.italienisch;
		},
		setSpanisch: (state) => {
			state.spanisch = !state.spanisch;
		},
		setIndisch: (state) => {
			state.indisch = !state.indisch;
		},
		setJapanisch: (state) => {
			state.japanisch = !state.japanisch;
		},
		setChinesisch: (state) => {
			state.chinesisch = !state.chinesisch;
		},
		setArabisch: (state) => {
			state.arabisch = !state.arabisch;
		},
		setAfrikanisch: (state) => {
			state.afrikanisch = !state.afrikanisch;
		},
		setLaktose: (state) => {
			state.laktose = !state.laktose;
		},
		setGluten: (state) => {
			state.gluten = !state.gluten;
		},
		setNüsse: (state) => {
			state.nüsse = !state.nüsse;
		},
		setSenf: (state) => {
			state.senf = !state.senf;
		},
		addToLiked: (state, action) => {
			state.likedRecepies.push(action.payload);
		},
		addToDisliked: (state, action) => {
			state.dislikedRecepies.push(action.payload);
		},
		removeFromFilteredList: (state) => {
			state.filteredRecepies.shift();
		},
		setCartValue: (state) => {
			state.cartValue = state.cartValue - 3;
		},
		initalizeList: (state) => {
			var newFilteredRecepies = [];
			state.filteredRecepies.map((recepie, i) => {
				if (state.laktose && recepie.laktose) return;
				if (state.gluten && recepie.gluten) return;
				if (state.nüsse && recepie.nüsse) return;
				if (state.senf && recepie.senf) return;
				if (state.geflügel && recepie.geflügel) {
					newFilteredRecepies.push(recepie);
					return;
				}
				if (state.rind && recepie.rind) {
					newFilteredRecepies.push(recepie);
					return;
				}
				if (state.schwein && recepie.schwein) {
					newFilteredRecepies.push(recepie);
					return;
				}
				if (state.fisch && recepie.fisch) {
					newFilteredRecepies.push(recepie);
					return;
				}
				if (state.meeresfrüchte && recepie.meeresfrüchte) {
					newFilteredRecepies.push(recepie);
					return;
				}
				if (state.milchEi && (recepie.milch || recepie.ei)) {
					newFilteredRecepies.push(recepie);
					return;
				}
				if (state.vegetarisch && recepie.vegetarisch) {
					newFilteredRecepies.push(recepie);
					return;
				}
				if (state.vegan && recepie.vegan) {
					newFilteredRecepies.push(recepie);
					return;
				}
				if (state.lowfat && recepie.lowFat) {
					newFilteredRecepies.push(recepie);
					return;
				}
				if (state.lowCarb && recepie.lowCarb) {
					newFilteredRecepies.push(recepie);
					return;
				}
			});
			// newFilteredRecepies.splice(3, 0, state.ads[0])
			// newFilteredRecepies.splice(7, 0, state.ads[1])
			state.filteredRecepies = newFilteredRecepies;
		}
	}
});

export const {
	setGeflügel,
	setRind,
	setSchwein,
	setFisch,
	setMeeresfrüchte,
	setMilchEi,
	setVegan,
	setVegetarisch,
	setLowfat,
	setLowCarb,
	setItalienisch,
	setSpanisch,
	setIndisch,
	setJapanisch,
	setChinesisch,
	setArabisch,
	setAfrikanisch,
	setLaktose,
	setGluten,
	setNüsse,
	setSenf,
	initalizeList,
	addToDisliked,
	addToLiked,
	removeFromFilteredList,
	setCartValue
} = slice.actions;
export default slice.reducer;
