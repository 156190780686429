import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import { Provider } from "react-redux";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import Header from "./components/Header";
import Footer from "./components/Footer";
import HomePage from "./screens/HomePage";
import EmailPage from "./screens/EmailPage";
import FiltersPage from "./screens/FiltersPage";
import RecepiePage from "./screens/RecepiePage";
import RecepieDescription from './screens/RecepieDescription';
import ImpressumPage from "./screens/ImpressumPage";
import DatenschutzPage from "./screens/DatenschutzPage";
import AboutPage from "./screens/AboutPage";
import ScrollToTop from "./utils/ScrollToTop";
import slice from "./store/slice";
import "./styles/main.css";

function App() {
	const store = configureStore({
		reducer: slice,
		middleware: [ ...getDefaultMiddleware({ thunk: false }) ]
	});


	return (
		<Provider store={store}>
			<Router>
				<Header />
				<ScrollToTop />
				<Switch>
					<Route path="/impressum">
						<ImpressumPage />
					</Route>
					<Route path="/datenschutz">
						<DatenschutzPage />
					</Route>
					<Route path="/about">
						<AboutPage />
					</Route>
					<Route path="/recpies">
						<RecepiePage />
					</Route>
					<Route path="/recpie-description">
						<RecepieDescription />
					</Route>
					<Route path="/filters">
						<FiltersPage />
					</Route>
					<Route path="/email">
						<EmailPage />
					</Route>
					<Route exact path="/">
						<HomePage />
					</Route>
				</Switch>
				<Footer />
			</Router>
		</Provider>
	);
}

export default App;
